import { SimpleButton } from "@components/Buttons/SimpleButton"
import GenericModal from "@components/GenericModal"
import { generateAlertMessage } from "@components/PlanPrices"
import Tooltip from "@components/Tooltip"
import { profileService } from "@services/Profile"
import useStore from "@store"
import { useMutation, useQuery } from "@tanstack/react-query"
import { XCircle } from "phosphor-react"
import React, { useCallback, useEffect, useState } from "react"
import { useDropzone } from "react-dropzone"
import { toast } from "sonner"

// import { Container } from './styles';

interface IGallery {
  isOpen: boolean
  setIsOpen: (value: boolean | null) => void
  handleSelectImage: (image_url: string) => void
}

const Gallery: React.FC<IGallery> = ({
  handleSelectImage,
  isOpen,
  setIsOpen,
}) => {
  const { paiedFeatures } = useStore()

  const [images, setImages] = useState<any[]>([])
  const [imagesToDelete, setImagesToDelete] = useState<any[]>([])

  const { data: gallery, refetch: refetchGallery } = useQuery({
    queryKey: [profileService.getMyImages.key],
    queryFn: profileService.getMyImages.fn,
  })

  const hasSomeImageNotUploaded =
    images.some((image) => !image.url) || imagesToDelete.length > 0

  const restOfNumberImagesToUpload =
    paiedFeatures?.upload_images - images.length

  const restImageMessage = generateAlertMessage(
    `Você pode adicionar mais ${restOfNumberImagesToUpload} imagens`,
    "text-lg",
    "mb-0"
  )

  const endOfNumberImagesToUpload = generateAlertMessage(
    "Você atingiu o limite de imagens salvas, exclua alguma para adicionar mais",
    "text-base",
    "mb-0"
  )

  useEffect(() => {
    if (gallery) {
      setImages(
        gallery.map((image: any) => ({
          id: image.name,
          url: image.url,
        }))
      )
    }
  }, [gallery])

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      if (acceptedFiles.length > restOfNumberImagesToUpload) {
        return toast.error(
          "Você está tentando adicionar mais imagens do que o permitido"
        )
      }

      setImages((prev) => [
        ...prev,
        ...acceptedFiles.map((file) => ({
          id: Math.random().toString(36).substr(2, 9),
          fileDetails: file,
        })),
      ])
    },
    [images, setImages]
  )

  const handleDelete = (id: string) => {
    setImages((prev) => prev.filter((image) => image.id !== id))
  }

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop,
    validator: (file) => {
      if (file.size > 150 * 1024) {
        return {
          code: "file-too-large",
          message: `O arquivo ${file.name} é muito grande, o tamanho máximo é 150KB`,
        }
      }
      if (images.length >= 10) {
        return {
          code: "max-files",
          message: "Máximo de 10 imagens",
        }
      }

      const fileAlreadyExistsOnGallery = gallery?.some((image: any) => {
        const [_, ...rest] = image.name.split("_")

        return rest.join("_") === file.name
      })

      if (
        images.some((image) => image.fileDetails?.name === file.name) ||
        fileAlreadyExistsOnGallery
      ) {
        return {
          code: "file-already-exists",
          message: `O arquivo ${file.name} já foi adicionado`,
        }
      }
      return null
    },
    onError: (error) => {
      toast.error("Erro ao adicionar imagem: " + error.message)
    },
    onDropRejected: (files) => {
      toast.error(
        "Erro ao adicionar imagem: " + files[0].errors[0].message || ""
      )
    },
    maxFiles: 10,
    noClick: true,
  })

  const { mutate: uploadImages, isPending: uploadImagesIsPending } =
    useMutation({
      mutationFn: profileService.uploadImages.fn,
      mutationKey: [profileService.uploadImages.key],
      onError: (error) => {
        toast.error("Erro ao salvar imagens: " + error.message)
      },
      onSuccess: () => {
        refetchGallery()
        toast.success("Imagens salvas com sucesso")
      },
      onSettled: () => {
        refetchGallery()
        setImagesToDelete([])
      },
    })

  const { mutate: deleteImage, isPending: deleteImageIsPending } = useMutation({
    mutationFn: profileService.deleteImage.fn,
    mutationKey: [profileService.deleteImage.key],
    onError: (error) => {
      toast.error("Erro ao deletar imagem: " + error.message)
    },
    onSettled: () => {
      refetchGallery()
      toast.success("Imagem deletada com sucesso")
    },
  })

  const handleUploadImages = async () => {
    const imagesToUpload = images.filter((image) => !image.url)
    if (imagesToUpload.length === 0) return

    const data = new FormData()
    imagesToUpload.forEach((image) => {
      data.append("images", image.fileDetails)
    })

    try {
      uploadImages(data)
    } catch (error) {
      toast.error("Erro ao salvar imagens: " + error.message)
    }
  }

  const handleDeleteImages = async () => {
    if (imagesToDelete.length === 0) return

    try {
      await Promise.all(imagesToDelete.map((image) => deleteImage(image))).then(
        () => {
          setImagesToDelete([])
        }
      )
    } catch (error) {
      toast.error("Erro ao deletar imagens: " + error.message)
    }
  }

  const handleSaveImages = async () => {
    try {
      await handleUploadImages()
      await handleDeleteImages()
    } catch (error) {
      toast.error("Erro ao salvar imagens: " + error.message)
    }
  }

  return (
    <GenericModal
      isOpen={isOpen !== null}
      onClose={() => {
        if (
          hasSomeImageNotUploaded &&
          !uploadImagesIsPending &&
          !deleteImageIsPending
        ) {
          return toast.error("Salve as imagens antes de fechar")
        } else {
          setIsOpen(false)
        }
      }}
      title={`Galeria de Imagens`}
      alert="Selecione uma imagem para a variante"
      className="w-11/12 lg:max-w-5xl"
    >
      <div {...getRootProps({ className: "dropzone" })}>
        <input {...getInputProps()} />
      </div>
      <div className="w-full flex flex-col lg:flex-row justify-between items-center gap-4 mb-4">
        {restOfNumberImagesToUpload > 0
          ? restImageMessage
          : endOfNumberImagesToUpload}
        <SimpleButton
          label={
            uploadImagesIsPending || deleteImageIsPending
              ? "Atualizando galeria..."
              : "Atualizar galeria"
          }
          onClick={handleSaveImages}
          className="w-full lg:w-1/4"
          isLoading={uploadImagesIsPending || deleteImageIsPending}
          disabled={!hasSomeImageNotUploaded}
        />
      </div>
      <div className="w-full grid grid-cols-1 lg:grid-cols-3 gap-4 justify-items-center">
        {restOfNumberImagesToUpload > 0 ? (
          <button
            className="w-72 h-72 border-2 border-dashed rounded-lg border-gray-300 flex items-center justify-center"
            onClick={open}
          >
            <span className="text-gray-500">Adicionar Imagem</span>
          </button>
        ) : null}
        {images.map((image) => (
          <Tooltip
            id={image.id}
            disableTooltip={image.url}
            text="Salve para poder selecionar a imagem"
          >
            <div
              key={image.id}
              className="relative w-72 h-72 border rounded-lg overflow-hidden group"
            >
              <img
                src={image?.url || URL.createObjectURL(image.fileDetails)}
                alt="uploaded"
                className="w-full h-full object-scale-down"
              />

              {image.url && (
                <div
                  className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center opacity-0 group-hover:opacity-100"
                  onClick={() => handleSelectImage(image.url)}
                >
                  <span className="bg-white text-black px-4 py-2 text-sm rounded-lg cursor-pointer">
                    Selecionar imagem
                  </span>
                </div>
              )}

              <XCircle
                size={40}
                weight="fill"
                className="absolute top-0 right-0 text-rose-700 p-1 rounded-full cursor-pointer"
                onClick={() => {
                  if (image.url) {
                    setImagesToDelete((prev) => [...prev, image.id])
                  }

                  handleDelete(image.id)
                }}
              />
            </div>
          </Tooltip>
        ))}
      </div>
    </GenericModal>
  )
}

export default Gallery
