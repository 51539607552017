import { IListClientsParams, IListClients } from "@interfaces/Services"
import { clientApi } from "./clientApi"
import { toast } from "sonner"
import { generateErrorMessage } from "@utils/generateErrorMessage"

const clientsService = {
  deleteClient: {
    fn: async (clientId: string) => {
      try {
        await clientApi.delete(`/clients/${clientId}`)
      } catch (error: any) {
        return generateErrorMessage("Erro ao deletar: ", error)
      }
    },
    key: "deleteClient",
  },
  updateClient: {
    fn: async (data: any) => {
      try {
        await clientApi.put(`/clients/${data.id}`, data)
      } catch (error: any) {
        return generateErrorMessage("Erro ao atualizar: ", error)
      }
    },
    key: "updateClient",
  },
  clientDetails: {
    fn: async (clientId: string) => {
      try {
        const { data } = await clientApi.get(`/clients/${clientId}`)

        return data
      } catch (error: any) {
        return generateErrorMessage("Erro ao buscar: ", error)
      }
    },
    key: "clientDetails",
  },
  createClient: {
    fn: async (data: any) => {
      try {
        await clientApi.post(`/clients`, data)
      } catch (error: any) {
        return generateErrorMessage("Erro ao criar: ", error)
      }
    },
    key: "createClient",
  },
  listClients: {
    fn: async ({ page, search, is_active }: IListClientsParams) => {
      try {
        const { data } = await clientApi.get<IListClients>(`/clients`, {
          params: {
            page,
            search,
            is_active,
          },
        })

        return data
      } catch (error: any) {
        return generateErrorMessage("Erro ao buscar: ", error)
      }
    },
    key: "listClients",
  },
}
export { clientsService }

export type { IListClients }
