import Card from "@components/Card"
import React, { useEffect, useState } from "react"
import Title from "@components/Title"
import ToggleInput from "@components/Inputs/ToggleInput"
import { IPaymentMethods } from "@interfaces/Components"
import useStore from "@store"
import { useMutation, useQuery } from "@tanstack/react-query"
import { profileService } from "@services/Profile"
import { IUser, PaymentMethod } from "@interfaces/Services"
import { SimpleButton } from "@components/Buttons/SimpleButton"
import { toast } from "sonner"
import { segmentsService } from "@services/Segments"
import InputWithLabel from "@components/Inputs/InputWithLabel"
import { formatNumberToMoney, formatStringMoneyToNumber } from "@utils/money"

const PaymentMethods: React.FC<IPaymentMethods> = ({}) => {
  const { userDetail, setUserDetail } = useStore()
  const [disableSubmit, setDisableSubmit] = useState(true)
  const userPaymentMethods = userDetail?.settings?.payment_methods

  const { data: segmentsPaymentMethods } = useQuery({
    queryKey: [segmentsService.getSegmentsPaymentMethods.key],
    queryFn: () =>
      segmentsService.getSegmentsPaymentMethods.fn(userDetail?.segment_id),
  })

  const { mutate, isPending } = useMutation({
    mutationFn: profileService.updateSettings.fn,
    mutationKey: [profileService.updateSettings.key],
    onSuccess() {
      toast.success("Métodos de pagamento atualizados com sucesso")
    },
    onError(error) {
      toast.error(error.message)
    },
  })

  const formatedMethods = segmentsPaymentMethods?.map((method) => ({
    name: method.name,
    title: method.title,
    description: "",
    min_value: formatNumberToMoney(
      userPaymentMethods?.find((m) => m.name === method.name)?.min_value
    ),
    enabled: userPaymentMethods?.some((m) => m.name === method.name),
  }))

  const [methods, setMethods] = useState<PaymentMethod[]>([])

  useEffect(() => {
    if (methods?.length == 0 && formatedMethods?.length > 0) {
      setMethods(formatedMethods)
    }
  }, [formatedMethods])

  const handleChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (disableSubmit) setDisableSubmit(false)

    const updatedMethods = methods?.map((m) => {
      if (m.name === e.target.name) {
        return {
          ...m,
          enabled: e.target.checked,
        }
      }
      return m
    })
    setMethods(updatedMethods)
  }

  const handleChangeMinValue = (value: string, name: string) => {
    if (disableSubmit) setDisableSubmit(false)

    const updatedMethods = methods?.map((m) => {
      if (m.name === name) {
        return {
          ...m,
          min_value: value,
        }
      }
      return m
    })
    setMethods(updatedMethods)
  }

  const handleSavePaymentMethods = () => {
    const updatedMethods = methods
      .filter((m) => m.enabled)
      .map((m) => ({
        name: m.name,
        title: m.title,
        min_value: formatStringMoneyToNumber(m.min_value),
      }))

    mutate({ ...userDetail.settings, payment_methods: updatedMethods })
    setUserDetail({
      ...userDetail,
      settings: {
        ...userDetail.settings,
        payment_methods: updatedMethods,
      },
    } as IUser)
    setDisableSubmit(true)
  }

  if (methods.length === 0) return null

  return (
    <Card className="w-full lg:max-w-xl max-h-[315px] flex justify-between">
      <Title
        title="Métodos de pagamento aceitos"
        info="Métodos de pagamento que você aceita receber em sua loja"
      />

      <div className="flex flex-col justify-start gap-4 py-1 h-full overflow-x-hidden overflow-y-auto mb-4">
        {methods?.map((method) => {
          return (
            <div
              key={method.name}
              className="flex flex-col items-start pr-4 gap-2 md:flex-row justify-between md:items-center"
            >
              <ToggleInput
                key={method.name}
                name={method.name}
                title={method.title}
                description={method.description}
                checked={method.enabled}
                onChange={handleChangeValue}
              />
              {method.enabled && (
                <InputWithLabel
                  label="Valor mínimo para pagamento"
                  size="xsmall"
                  value={method?.min_value?.toString()}
                  masks="money"
                  onType={(value) => handleChangeMinValue(value, method.name)}
                />
              )}
            </div>
          )
        })}
      </div>
      <div className="flex justify-end">
        <SimpleButton
          label="Salvar"
          onClick={handleSavePaymentMethods}
          isLoading={isPending}
          disabled={disableSubmit}
        />
      </div>
    </Card>
  )
}

export default PaymentMethods
