import clsx from 'clsx';
import React from 'react';
import { IStatusTag } from '@interfaces/Components';

const StatusTag: React.FC<IStatusTag> = ({ text, type }) => {
  return (
    <div
      className={clsx(
        "w-24 px-4 rounded-full flex justify-center items-center text-white",
        {
          "bg-green-500": type === "success",
          "bg-orange-400": type === "warning",
          "bg-red-500": type === "danger",
          "bg-blue-500": type === "info",
        }
      )}
    >
      {text}
    </div>
  )
}

export default StatusTag
