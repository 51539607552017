import { localStorageAuthKey } from "@constants/Auth"
import { ITokenData } from "@services/SignIn/loginUser"
import { useFeatureFlag } from "configcat-react"
import { decryptToken } from "./decryptToken"

interface IUseFlags {
  isActive: boolean
  isLoading: boolean
}

export const useFlag = (flag: string): IUseFlags => {
  const authKey = localStorage.getItem(localStorageAuthKey)

  const convertedAuthKey = authKey ? (JSON.parse(authKey) as ITokenData) : null

  const decrypToken = convertedAuthKey && decryptToken(convertedAuthKey)

  if (!decrypToken || typeof decrypToken === "boolean") {
    return { isActive: false, isLoading: false }
  }

  if (decrypToken?.payloadObj?.user_metadata?.role === "admin") {
    return { isActive: true, isLoading: false }
  }

  const { value, loading } = useFeatureFlag(flag, false, {
    email: decrypToken?.payloadObj?.email || "",
    identifier: decrypToken?.payloadObj?.sub || "",
    country: "",
    custom: {
      role: decrypToken?.payloadObj?.user_metadata?.role || "",
    },
  })

  return { isActive: value, isLoading: loading }
}
