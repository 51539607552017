import React from "react"
import { toast } from "sonner"

const QuantitySelector = ({ quantityCurrentValue, setValue, errors }) => {
  const minQuantity = 1
  const maxQuantity = 999

  const handleIncrementQuantity = () => {
    if (quantityCurrentValue >= maxQuantity) {
      toast.warning("Quantidade máxima é 999")
      return
    }
    setValue("quantity", Number(quantityCurrentValue) + 1)
  }

  const handleDecrementQuantity = () => {
    if (quantityCurrentValue <= minQuantity) {
      toast.warning("Quantidade mínima é 1")
      return
    }
    setValue("quantity", Number(quantityCurrentValue) - 1)
  }

  return (
    <div className="w-40">
      <p className="text-sm text-gray-800 mb-1">Selecione a quantidade</p>
      <div className="h-fit flex items-center justify-between rounded-md overflow-hidden">
        <button
          className="px-3 py-2 bg-primary-500 text-white hover:bg-primary-400 focus:outline-none border border-primary-500 hover:border-primary-400"
          onClick={handleDecrementQuantity}
        >
          -
        </button>
        <input
          type="number"
          value={quantityCurrentValue}
          className="w-full h-[41px] text-center focus:outline-none border border-primary-400"
          onChange={(e) => {
            const formattedValue = Number(e.target.value)

            if (formattedValue < minQuantity) {
              setValue("quantity", minQuantity)
              return
            }

            if (formattedValue > maxQuantity) {
              setValue("quantity", maxQuantity)
              return
            }

            setValue("quantity", formattedValue)
          }}
        />
        <button
          className="px-3 py-2 bg-primary-500 text-white hover:bg-primary-400 focus:outline-none border border-primary-500 hover:border-primary-400"
          onClick={handleIncrementQuantity}
        >
          +
        </button>
      </div>
      {errors.quantity && (
        <span className="text-red-500">{errors.quantity.message}</span>
      )}
    </div>
  )
}

export default QuantitySelector
