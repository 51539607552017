import { clientApi } from "./clientApi"
import { generateErrorMessage } from "@utils/generateErrorMessage"

const subscriptionsService = {
  getPlans: {
    key: "getPlans",
    fn: async (): Promise<any[]> => {
      try {
        const { data } = await clientApi.get("/subscriptions/plans")

        return data
      } catch (error: any) {
        throw new Error(generateErrorMessage("Erro ao obter planos: ", error))
      }
    },
  },
  createCheckoutSession: {
    key: "createCheckoutSession",
    fn: async (data: {
      planId: string
      getwayId: string
    }): Promise<{ checkout_url: string }> => {
      try {
        const response = await clientApi.post(
          "/subscriptions/create-checkout-session",
          {
            price_id: data.planId,
            gateway_customer_id: data.getwayId,
          }
        )

        return response.data
      } catch (error: any) {
        throw new Error(generateErrorMessage("Erro ao criar sessão de checkout: ", error))
      }
    },
  },
  updateSubscription: {
    key: "updateSubscription",
    fn: async (data: {
      subscriptionId: string
      newPlanId: string
    }): Promise<any> => {
      try {
        const response = await clientApi.post(
          `/subscriptions/update-subscription`,
          {
            subscription_id: data.subscriptionId,
            price_id: data.newPlanId,
          }
        )

        return response.data
      } catch (error: any) {
        throw new Error(generateErrorMessage("Erro ao atualizar assinatura: ", error))
      }
    },
  },
  cancelSubscription: {
    key: "cancelSubscription",
    fn: async (subscriptionId: string): Promise<any> => {
      try {
        const response = await clientApi.delete(
          `/subscriptions/cancel-subscription/${subscriptionId}`
        )

        return response.data
      } catch (error: any) {
        throw new Error(generateErrorMessage("Erro ao cancelar assinatura: ", error))
      }
    },
  },
  getSubscriptions: {
    key: "getSubscriptions",
    fn: async (): Promise<any[]> => {
      try {
        const response = await clientApi.get("/subscriptions")

        return response.data
      } catch (error: any) {
        throw new Error(generateErrorMessage("Erro ao obter assinaturas: ", error))
      }
    },
  },
}

export { subscriptionsService }
