import FirstAccess from "@pages/Private/FirstAccess"
import Loading from "@components/Loading"
import Private from "./Routes/Private"
import PrivateHOC from "./Components/PrivateHOC"
import Public from "./Routes/Public"
import React from "react"
import useStore from "@store"
import { autoRefreshSession, decodeHash } from "./Utils/autoRefreshSession"
import { localStorageAuthKey } from "./Constants/Auth"
import { publicRoutes } from "@constants/Routes"
import { toast } from "sonner"
import { useLocation } from "react-router-dom"
import { segmentsService } from "@services/Segments"
import { userServices } from "@services/User"
import { profileService } from "@services/Profile"
import FullLogo from "@assets/FullLogo"

const publicRoutesAccess = [publicRoutes.products]

const App: React.FC = () => {
  const location = useLocation()
  const path = location.pathname

  const { isLogged, setIsLogged, userDetail, setUserDetail, setPaiedFeatures } =
    useStore()

  const { hash } = useLocation()

  const routeIsSplashScreen = window.location.pathname === "/splash-screen"

  const decodedHash = hash && decodeHash(hash)

  if (decodedHash) {
    localStorage.setItem(localStorageAuthKey, JSON.stringify(decodedHash))
  }

  const dontHasLogin = isLogged === undefined

  const isLoadingPlatform = dontHasLogin

  const searchUserDetails = async () => {
    try {
      const userDetailsData = await userServices.getUserDetails.fn()
      const paiedFeatures = await profileService.findPaiedFeatures.fn()

      const segmentDetail =
        userDetailsData?.segment_id &&
        (await segmentsService.getSegment.fn(
          userDetailsData.segment_id as string
        ))

      setIsLogged(true)
      setPaiedFeatures(paiedFeatures)

      setUserDetail({
        ...userDetailsData,
        segment_key: segmentDetail ? segmentDetail.key : undefined,
      })

      return userDetailsData
    } catch (error: any) {
      toast.error("Erro ao buscar detalhes do usuário")
    }
  }

  if (
    isLoadingPlatform &&
    !routeIsSplashScreen &&
    !decodedHash &&
    !userDetail?.id
  ) {
    autoRefreshSession({
      onSucess: () => {
        searchUserDetails()
      },
      onFail: () => {
        setIsLogged(false)
      },
    })
    return (
      <div className="h-dvh flex items-center justify-center">
        <FullLogo size="2xlarge" className="animate-pulse" />
      </div>
    )
  }

  const isUserFirstAccess = !userDetail?.segment_id || !userDetail?.full_name

  if (publicRoutesAccess.includes(path)) {
    return <Public />
  }

  if (!isLogged) {
    return <Public />
  }

  if (isLogged && isUserFirstAccess) {
    return (
      <div className="w-screen h-dvh flex justify-center items-center">
        <FirstAccess />
      </div>
    )
  }

  if (isLogged && !isUserFirstAccess) {
    return (
      <div className="w-screen h-dvh flex justify-center items-center">
        <PrivateHOC>
          <Private />
        </PrivateHOC>
      </div>
    )
  }
}

export default App
