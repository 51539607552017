/* eslint-disable @typescript-eslint/no-explicit-any */
import { localStorageAuthKey } from "@constants/Auth"
import { clientApi } from "./clientApi"
import { publicRoutes } from "@constants/Routes"
import { IUser, IUpdateMySelfData } from "@interfaces/Services"
import { errorFormatter } from "@utils/errorFormatter"
import { generateErrorMessage } from "@utils/generateErrorMessage"

const userServices = {
  getUserDetails: {
    fn: async () => {
      try {
        const { data } = await clientApi.get<IUser>("/profiles/me")

        return data
      } catch (error: any) {
        return generateErrorMessage(
          "Erro ao buscar detalhes do usuário: ",
          error
        )
      }
    },
    key: "getUserDetails",
  },
  updateMySelf: {
    fn: async (data: IUpdateMySelfData) => {
      const isFirstAccess = data.isFirstAccess

      delete data.isFirstAccess

      try {
        const response = await clientApi.put("/profiles/me", data, {
          params: {
            first_access: isFirstAccess,
          },
        })

        return response
      } catch (error: any) {
        errorFormatter(error)
      }
    },
    key: "updateMySelf",
  },
  logout: {
    fn: async () => {
      try {
        localStorage.removeItem(localStorageAuthKey)
        window.location.href = publicRoutes.signIn

        return true
      } catch (error: any) {
        return generateErrorMessage("Erro ao deslogar: ", error)
      }
    },
    key: "logout",
  },
}
export { userServices }
