import BG from "@assets/bg2.png"
import FullLogo from "@assets/FullLogo"
import InputWithLabel from "@components/Inputs/InputWithLabel"
import React, { useEffect, useRef, useState } from "react"
import Separator from "@components/Separator"
import { createAccount } from "@services/SignIn/createAccount"
import { Envelope } from "phosphor-react"
import { publicRoutes } from "@constants/Routes"
import { SimpleButton } from "@components/Buttons/SimpleButton"
import { useMutation } from "@tanstack/react-query"
import { useNavigate } from "react-router-dom"

const SignUp: React.FC = ({}) => {
  const navigate = useNavigate()
  const [newUserData, setNewUserData] = useState({
    email: "",
    password: "",
    confirmPassword: "",
  })

  const [needToVerify, setNeedToVerify] = useState(false)

  const handleChangeUserData = (field: string, value: string) => {
    setNewUserData({
      ...newUserData,
      [field]: value,
    })
  }

  const { mutate: createAccountMutation, isPending } = useMutation({
    mutationFn: createAccount.fn,
    mutationKey: [createAccount.key],
    onSuccess: () => {
      setNeedToVerify(true)
    },
    onError: () => {
      navigate(publicRoutes.signIn)
    },
  })

  const googleButtonRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const iframe = googleButtonRef.current?.querySelector("iframe")

    if (iframe) {
      iframe.style.width = "100%"
      iframe.style.height = "50px"
      iframe.style.top = "-3px"
      iframe.style.left = "10px"
    }
  }, [googleButtonRef])

  const passwordMatch = newUserData.password === newUserData.confirmPassword

  const hasFormError = {
    email: new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g).test(
      newUserData.email
    ),
    password: newUserData.password.length > 5,
    confirmPassword: !passwordMatch,
  }

  const hasEmailError = newUserData.email !== "" && !hasFormError.email
  const hasPasswordError = newUserData.password !== "" && !hasFormError.password

  const isFieldsEmpty =
    newUserData.email === "" ||
    newUserData.password === "" ||
    newUserData.confirmPassword === ""

  return (
    <div className="bg-[#fafafa] w-screen h-dvh flex items-center justify-center">
      <div className="w-full lg:w-4/12 h-full p-4 bg-white flex flex-col items-center justify-start rounded-lg shadow-lg">
        <div className="w-full flex items-center justify-start gap-3 mb-4">
          <FullLogo size="small" />
        </div>
        {needToVerify ? (
          <div className="my-auto">
            <div className="h-full flex flex-col">
              <div className="container max-w-md mx-auto flex-1 flex flex-col items-center justify-center px-2">
                <div className="bg-white px-6 py-8 rounded-xl text-black w-full flex flex-col justify-center items-center">
                  <h1 className="flex flex-col justify-around items-center mb-8 text-3xl text-center">
                    <Envelope size={46} className="mb-4" />
                    Verifique seu email
                  </h1>
                  <p className="mb-8 text-center text-lg">
                    Enviamos um email para <strong>{newUserData.email}</strong>{" "}
                    com um link de verificação.
                  </p>
                  <p className="mb-8 text-center text-lg">
                    <strong>Verifique sua caixa de entrada</strong> e clique no
                    link de verificação para ativar sua conta.
                  </p>
                  <SimpleButton
                    label="Voltar"
                    onClick={() => {
                      navigate(publicRoutes.signIn)
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="w-full max-w-sm h-full flex flex-col items-center justify-center gap-4">
            <div className="w-full flex flex-col items-start justify-center">
              <p className="text-2xl font-semibold text-gray-700">
                Cadastre-se
              </p>
              <p className="text-lg font-medium text-gray-700">
                Gerenie seus pedidos de forma fácil e rápida
              </p>
            </div>
            <Separator middleText="ou" size="medium" />
            <div className="w-full flex flex-col items-center justify-center gap-1">
              <InputWithLabel
                label="E-mail"
                type="email"
                placeholder="Digite seu e-mail"
                value={newUserData.email}
                onType={(value) => handleChangeUserData("email", value)}
                size="full"
                error={hasEmailError ? "E-mail inválido" : ""}
              />
              <InputWithLabel
                label="Senha"
                type="password"
                placeholder="Digite sua senha"
                value={newUserData.password}
                onType={(value) => handleChangeUserData("password", value)}
                size="full"
                error={hasPasswordError ? "Senha precisa ter 6 caracteres" : ""}
              />
              <InputWithLabel
                label="Confirme sua senha"
                type="password"
                placeholder="Digite sua senha"
                value={newUserData.confirmPassword}
                onType={(value) =>
                  handleChangeUserData("confirmPassword", value)
                }
                size="full"
                error={
                  hasFormError.confirmPassword ? "Senhas não coincidem" : ""
                }
              />
              <div className="w-full mb-4">
                <SimpleButton
                  label="Cadastrar"
                  size="full"
                  onClick={() => {
                    createAccountMutation(newUserData)
                  }}
                  isLoading={isPending}
                  disabled={
                    isFieldsEmpty ||
                    hasEmailError ||
                    hasPasswordError ||
                    hasFormError.confirmPassword
                  }
                />
              </div>

              <div className="text-base text-gray-800 cursor-pointer text-center">
                <p>Já tem uma conta?</p>
                <p
                  className="text-primary-500 font-black cursor-pointer hover:underline hover:text-primary-200"
                  onClick={() => {
                    navigate(publicRoutes.signIn)
                  }}
                >
                  Faça login agora!
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="hidden w-11/12 h-full bg-gray-200 lg:flex flex-col items-center justify-center rounded-lg shadow-lg p-4 relative overflow-hidden">
        <img
          src={BG}
          alt="Landing"
          className=" absolute top-12 left-12 rounded-3xl"
        />
        {/* overlay bg-gradient */}
        <div className="w-full h-full absolute top-12 left-12 rounded-3xl z-10 bg-gradient-to-br from-[#ffffff00] to-[#ffffff]" />
      </div>
    </div>
  )
}

export default SignUp
