import BG from "@assets/bg-3.png"
import FullLogo from "@assets/FullLogo"
import InputWithLabel from "@components/Inputs/InputWithLabel"
import React, { useState } from "react"
import Select from "@components/Inputs/Select"
import useStore from "@store"
import { SimpleButton } from "@components/Buttons/SimpleButton"
import { toast } from "sonner"
import { useMutation, useQuery } from "@tanstack/react-query"
import { segmentsService } from "@services/Segments"
import { signOut } from "@utils/signOut"
import { userServices } from "@services/User"

const FirstAccess: React.FC = ({}) => {
  const { userDetail, setUserDetail } = useStore()
  const [userData, setUserData] = useState({
    name: "",
    lastName: "",
    phone: "",
    segmentId: "",
  })

  const requiredFieldsAreFilled = userData.name && userData.segmentId

  const hasErrorOnPhone = userData.phone && userData.phone.length < 15

  const hasError = hasErrorOnPhone || !requiredFieldsAreFilled

  const { data: segmentsData } = useQuery({
    queryKey: [segmentsService.getSegments.key],
    queryFn: segmentsService.getSegments.fn,
  })

  const { mutate, isPending } = useMutation({
    mutationFn: userServices.updateMySelf.fn,
    onSuccess() {
      const findSegment = segmentsData?.data?.find(
        (segment) => segment.id === userData.segmentId
      )

      setUserDetail({
        ...userDetail,
        full_name: `${userData.name} ${userData.lastName}`,
        segment_id: findSegment?.id,
        segment_key: findSegment?.key,
        slug: userDetail?.slug || "",
        settings: userDetail?.settings || {
          payment_methods: [],
          only_clients_can_buy: false,
        },
      })

      toast.success("Cadastro finalizado com sucesso")
    },
  })

  const formattedSegments =
    segmentsData?.data?.map((segment) => ({
      label: segment.name,
      value: segment.id,
    })) || []

  return (
    <div className="w-full h-dvh flex flex-row items-center justify-between gap-4">
      <div className="w-full lg:w-4/12 h-full p-4 bg-white flex flex-col items-center justify-start rounded-lg">
        <div className="w-full flex items-center justify-start gap-1 mb-8 lg:mb-4">
          <FullLogo size="small" />
        </div>
        <div className="w-full max-w-sm h-full flex flex-col items-start justify-center gap-4">
          <p className="text-gray-800 text-xl font-bold">
            Seja bem-vindo ao nosso sistema
          </p>
          <p className="text-gray-800 text-lg mb-4">
            Para continuar, precisamos que você complete seu cadastro.
          </p>
          <div className="w-full flex flex-col justify-center">
            <div className="w-full grid grid-cols-1 gap-x-4">
              <InputWithLabel
                label="Nome"
                placeholder="Digite seu nome"
                type="text"
                size="full"
                onType={(value) => {
                  setUserData({ ...userData, name: value })
                }}
                isDisabled={isPending}
              />
              <InputWithLabel
                label="Sobrenome (Opcional)"
                placeholder="Digite seu sobrenome"
                type="text"
                size="full"
                onType={(value) => {
                  setUserData({ ...userData, lastName: value })
                }}
                isDisabled={isPending}
              />
            </div>
            <div className="w-full grid grid-cols-1 gap-x-4">
              <Select
                label="Segmento"
                placeholder="Selecione o segmento"
                options={formattedSegments}
                selectedValue={userData.segmentId}
                onSelectOption={({ value }) => {
                  setUserData({ ...userData, segmentId: value })
                }}
                className="w-full mb-2"
                isDisabled={isPending}
              />
              <InputWithLabel
                label="Telefone (Opcional)"
                placeholder="Digite seu telefone"
                type="text"
                size="full"
                masks="phone"
                error={hasErrorOnPhone ? "Telefone inválido" : ""}
                onType={(value) => {
                  setUserData({ ...userData, phone: value })
                }}
                isDisabled={isPending}
              />
            </div>
          </div>
          <div className="w-full flex justify-between">
            <SimpleButton
              label="Sair"
              mode="outline"
              onClick={signOut}
              isLoading={isPending}
              disabled={isPending}
            />
            <SimpleButton
              label="Continuar"
              onClick={() => {
                const joinNameAndLastName = `${userData.name} ${userData.lastName} ${Math.random().toString(36).substring(7)}`
                const createSlug = joinNameAndLastName
                  .toLowerCase()
                  .replace(/\s+/g, "-")

                mutate({
                  full_name: `${userData.name} ${userData.lastName}`,
                  segment_id: userData.segmentId,
                  phone_number: userData.phone,
                  slug: createSlug,
                  isFirstAccess: true,
                })
              }}
              isLoading={isPending}
              disabled={hasError}
            />
          </div>
        </div>
      </div>
      <div className="hidden w-11/12 h-full bg-gray-200 lg:flex flex-col items-center justify-center rounded-lg shadow-lg p-4 relative overflow-hidden">
        <img
          src={BG}
          alt="Landing"
          className=" absolute top-12 left-12 rounded-3xl"
        />
        {/* overlay bg-gradient */}
        <div className="w-full h-full absolute top-12 left-12 rounded-3xl z-10 bg-gradient-to-br from-[#ffffff00] to-[#f0f0f0]" />
      </div>
    </div>
  )
}

export default FirstAccess
