import Card from "@components/Card"
import ConfirmActionModal from "@components/Modals/ConfirmAction"
import DatePicker from "@components/DatePicker"
import InputWithLabel from "@components/Inputs/InputWithLabel"
import Pagination from "@components/Pagination"
import React, { useEffect, useState } from "react"
import Select from "@components/Inputs/Select"
import Table from "@components/Table/Table"
import TableFilters from "@components/Table/TableFilters"
import UnderlineTitle from "@components/UnderlineTitle"
import { formatOrdersData } from "@utils/formatters"
import { mainIconPage } from "@utils/index"
import { ordersHeadersList } from "@interfaces/Constants/Pages"
import { ordersService } from "@services/Orders"
import { OrderStatus } from "@constants/index"
import { toast } from "sonner"
import { useMutation, useQuery } from "@tanstack/react-query"
import { useNavigate } from "react-router-dom"
import dayjs from "dayjs"

const formatDateToFilter = (date?: string, isEndOfDay = false) => {
  if (!date) return undefined

  const dateFormatted = dayjs(date).format("YYYY-MM-DD")

  if (isEndOfDay) {
    return `${dateFormatted}T23:59:59`
  }

  return `${dateFormatted}T00:00:00`
}

const Orders: React.FC = ({}) => {
  const pageIcon = mainIconPage()
  const navigate = useNavigate()

  const [pageFilters, setPageFilters] = useState({
    actualPage: 1,
    itemsPerPage: 15,
  })

  const [filters, setFilters] = useState<{
    search?: string
    status?: string
    startDate?: string
    endDate?: string
  }>()

  const [lastQuantityOfTotalItems, setLastQuantityOfTotalItems] = useState(0)
  const [deleteOrderId, setDeleteOrderId] = useState("")

  let nodeTimeout: NodeJS.Timeout

  const {
    data: listOrdersData,
    isFetching: isListOrdersPending,
    refetch: refetchListOrders,
  } = useQuery({
    queryKey: [ordersService.listOrders.key, filters, pageFilters],
    queryFn: () =>
      ordersService.listOrders.fn({
        page: pageFilters.actualPage,
        start_date: formatDateToFilter(filters?.startDate, false),
        end_date: formatDateToFilter(filters?.endDate, true),
        search: filters?.search,
        status: filters?.status,
      }),
  })

  const { mutate: deleteOrder, isPending: isDeletePending } = useMutation({
    mutationFn: ordersService.deleteOrder.fn,
    onSuccess: () => {
      toast.success("Pedido deletado com sucesso!")
      setPageFilters({ ...pageFilters, actualPage: 1 })
      setDeleteOrderId("")
      refetchListOrders()
    },
    onError: (error) => {
      toast.error("Erro ao deletar produto: " + error.message)
    },
  })

  const { mutate: updateOrder, isPending: isUpdatePending } = useMutation({
    mutationFn: ordersService.updateOrder.fn,
    onSuccess: () => {
      toast.success("Pedido atualizado com sucesso!")
      refetchListOrders()
    },
    onError: (error) => {
      toast.error("Erro ao atualizar pedido: " + error.message)
    },
  })

  useEffect(() => {
    if (
      listOrdersData?.total &&
      listOrdersData?.total !== lastQuantityOfTotalItems
    ) {
      setLastQuantityOfTotalItems(listOrdersData?.total)
    }
  }, [listOrdersData])

  const handleChangePage = (page: number) => {
    setPageFilters({ ...pageFilters, actualPage: page })
  }

  const handleChangeFilter = (filtersToChange: any) => {
    setFilters({ ...filters, ...filtersToChange })
  }

  const handleChangeSearch = (text: string) => {
    clearTimeout(nodeTimeout)
    nodeTimeout = setTimeout(() => {
      handleChangeFilter({
        search: text,
      })
    }, 500)
  }

  const handleViewDetails = (productId: string) => {
    navigate(productId)
  }

  const allFiltersAreEmpty = filters === undefined

  const dontHasOrders =
    allFiltersAreEmpty &&
    (!listOrdersData?.data || listOrdersData?.data.length === 0)

  const hasOrderToDelete = deleteOrderId !== ""

  const howManyFiltersAreActive = filters
    ? Object.keys(filters).filter(
        (key) =>
          key !== "endDate" && filters[key as keyof typeof filters] !== ""
      ).length
    : undefined

  const statusOptions = [
    ...Object.values(OrderStatus).map((status) => ({
      value: status.key,
      label: status.value,
    })),
    {
      value: "",
      label: "Todos",
    },
  ]

  const handleApproveOrder = (orderId: string) => {
    updateOrder({
      id: orderId,
      status: OrderStatus.approved.key,
    })
  }

  const handleDisapproveOrder = (orderId: string) => {
    updateOrder({
      id: orderId,
      status: OrderStatus.canceled.key,
    })
  }

  return (
    <>
      {hasOrderToDelete && (
        <ConfirmActionModal
          isOpen={hasOrderToDelete}
          title="Deletar pedido"
          message="Tem certeza que deseja deletar esse pedido?"
          onConfirm={() => {
            deleteOrder(deleteOrderId)
          }}
          onCancel={() => {
            setDeleteOrderId("")
          }}
          icon={pageIcon}
          isPending={isDeletePending}
        />
      )}
      <div className="flex justify-between items-center w-full">
        <UnderlineTitle />
      </div>
      <Card>
        <div className="flex justify-end w-full h-fit mb-8 gap-4">
          {!dontHasOrders ? (
            <TableFilters
              filtersQuantity={howManyFiltersAreActive}
              clearFilters={() => {
                setFilters({})
              }}
            >
              <div className="lg:max-w-[500px] flex flex-col mb-8 lg:mb-0">
                <InputWithLabel
                  label="Pesquisar"
                  size="full"
                  rightIcon="MagnifyingGlass"
                  placeholder="Código, Nome, CPNJ ou Email"
                  onType={handleChangeSearch}
                  isDisabled={dontHasOrders}
                  value={filters?.search}
                />
                <DatePicker
                  valueDate={{
                    startDate: filters?.startDate
                      ? new Date(filters.startDate)
                      : null,
                    endDate: filters?.endDate
                      ? new Date(filters.endDate)
                      : null,
                  }}
                  setValueDate={({ startDate, endDate }) => {
                    handleChangeFilter({
                      startDate: startDate
                        ? startDate.toISOString()
                        : undefined,
                      endDate: endDate ? endDate.toISOString() : undefined,
                    })
                  }}
                />
                <Select
                  label="Status"
                  className="w-full"
                  placeholder="Status"
                  options={statusOptions}
                  selectedValue={filters?.status}
                  onSelectOption={({ value: status }) =>
                    handleChangeFilter({ status })
                  }
                  isDisabled={dontHasOrders}
                />
              </div>
            </TableFilters>
          ) : null}
        </div>
        <Table
          headers={ordersHeadersList}
          data={formatOrdersData(
            listOrdersData?.data,
            setDeleteOrderId,
            handleViewDetails,
            handleApproveOrder,
            handleDisapproveOrder
          )}
          isPending={isListOrdersPending || isUpdatePending}
          customEmptyMessage={
            dontHasOrders ? (
              <p className="w-96">
                Infelizmente nenhum pedido foi encontrado, mas não se preocupe,
                em breve você terá muitos pedidos para gerenciar!
              </p>
            ) : (
              ""
            )
          }
          emptyIcon={dontHasOrders ? pageIcon : "SmileySad"}
          iconLoading={pageIcon}
        />
        {!dontHasOrders &&
        (listOrdersData?.total || lastQuantityOfTotalItems) ? (
          <div className="w-full flex justify-end items-center mt-auto">
            <Pagination
              currentPage={pageFilters.actualPage}
              itemsPerPage={pageFilters.itemsPerPage}
              totalItems={
                listOrdersData?.total || lastQuantityOfTotalItems || 0
              }
              onPageChange={handleChangePage}
            />
          </div>
        ) : null}
      </Card>
    </>
  )
}

export default Orders
