import { toast } from "sonner"

export const generateErrorMessage = (
  typeMessage: string,
  error: any,
  toastError = false
) => {
  const formattedError =
    typeMessage + error.response?.data?.detail ||
    error.message ||
    "Erro desconhecido"

  if (toastError) {
    toast.error(formattedError)
  }

  throw new Error(formattedError)
}
