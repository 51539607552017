import * as PhosphorIcons from "phosphor-react"
import clsx from "clsx"
import React from "react"
import Tooltip from "./Tooltip"
import { ISideBarOption } from "@interfaces/Components"
import { useFlag } from "@utils/featureFlags"
import { useNavigate } from "react-router-dom"

const SideBarOption: React.FC<ISideBarOption> = ({
  icon,
  title,
  isSelected,
  route,
  isCollapsed,
  flagPermission,
  onClick,
  steps,
  isSubOption,
  childRoute,
  navigateBlank,
}) => {
  const navigate = useNavigate()
  const Icon = PhosphorIcons[icon] as React.FC<any>

  const routeWords = route?.split("/")[1]
  const firstWordOfPath = window.location.pathname.split("/")[1]

  const actualRoute =
    window.location.pathname === route ||
    window.location.pathname === childRoute

  const optionIsSelected = isSelected || actualRoute || routeWords === firstWordOfPath

  const isSubOptionSelected = steps?.some(
    (step) => window.location.pathname === step.route
  )

  const isEnabled = flagPermission ? useFlag(flagPermission).isActive : true

  if (!isEnabled) return null

  return (
    <div className="w-full relative">
      <Tooltip
        id={title}
        text={title}
        place="right"
        disableTooltip={!isCollapsed}
      >
        <button
          className={clsx(
            "relative border-0 shadow-none py-2 w-full flex justify-start items-center text-gray-500 hover:opacity-100 text-sm",
            optionIsSelected &&
              isSubOption &&
              "border-l-2 border-secondary-500 text-secondary-500",
            optionIsSelected &&
              !isSubOption &&
              "bg-primary-500 text-white cursor-default font-semibold",
            !optionIsSelected &&
              !isSubOption &&
              "hover:bg-primary-400 hover:text-white ",
            isSubOption && "hover:text-secondary-500",
            optionIsSelected && !isSubOption && "hover:bg-primary-500",

            isSubOptionSelected && "bg-primary-500 text-white",
            isSubOption && !isCollapsed ? "pl-3 pr-0" : "px-4",
            !isSubOption && "!rounded-md"
          )}
          onClick={() => {
            if (onClick) {
              onClick()
              return
            }
            if (route || childRoute) {
              if (navigateBlank) {
                window.open(route || childRoute, "_blank")
                return
              }
              navigate(route || childRoute || "")
            }

            if (actualRoute) return
          }}
        >
          {Icon && <Icon size={26} className="transition-all duration-300" />}
          <span
            className={clsx(
              "text-left transition-all duration-300 ease-in-out text-nowrap overflow-hidden whitespace-nowrap",
              isCollapsed ? "w-0" : "w-32 duration-300 ml-2"
            )}
          >
            {title}
          </span>
        </button>
      </Tooltip>
      {steps && (optionIsSelected || isSubOptionSelected) && (
        <div
          className={clsx(
            "w-fit flex h-auto transition-all duration-300 ease-in-out",
            isCollapsed ? "h-0 mt-2" : "h-auto pt-2 pl-6"
          )}
        >
          {/* <div
            className={clsx(
              "transition-all duration-300 w-px h-auto bg-gray-300",
              isCollapsed ? "mr-0 opacity-0" : "mr-2 opacity-100"
            )}
          /> */}
          <div className="flex flex-col gap-2">
            {steps.map((step, index) => (
              <SideBarOption
                key={index}
                isCollapsed={isCollapsed}
                {...step}
                route={step.route}
                isSubOption
              />
            ))}
          </div>
        </div>
      )}
    </div>
  )
}

export default SideBarOption
