import PaymentMethods from "@components/Profile/PaymentMethods"
import ProfileInfo from "@components/Profile/ProfileInfos"
import Security from "@components/Profile/Security"
import UnderlineTitle from "@components/UnderlineTitle"
import React from "react"

// import { Container } from './styles';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IProfile {}

// eslint-disable-next-line no-empty-pattern
const Profile: React.FC<IProfile> = ({}) => {
  return (
    <div className="w-full">
      <UnderlineTitle />

      <div className="w-full grid grid-cols-1 gap-4 lg:grid-cols-3" >
        <div className="w-full grid grid-cols-1 gap-4">
          <ProfileInfo />
          <Security />
        </div>
        <div className="w-full grid grid-cols-1 gap-4">
          <PaymentMethods />
        </div>
      </div>
    </div>
  )
}

export default Profile
