import * as PhosphorIcons from "phosphor-react"
import clsx from "clsx"
import React from "react"
import { IUnderlineTitle } from "@interfaces/Components"
import { sidebarMenu } from "@constants/Routes"
import { useNavigate } from "react-router-dom"

const UnderlineTitle: React.FC<IUnderlineTitle> = ({ title, breadcrumb }) => {
  const navigate = useNavigate()
  const actualRoute = window.location.pathname

  let titleBasedOnRoute = sidebarMenu()?.find(
    (menu) => actualRoute === menu.route
  )

  const titleBasedOnChildRoute = sidebarMenu()
    ?.find((menu) => actualRoute === menu.childRoute)
    ?.steps?.find((step) => actualRoute === step.route)

  titleBasedOnRoute = titleBasedOnChildRoute || titleBasedOnRoute

  const Icon = PhosphorIcons[
    titleBasedOnRoute?.icon as keyof typeof PhosphorIcons
  ] as React.FC<any>

  // const isClickable = titleBasedOnRoute?.route !== actualRoute

  return (
    <div className="w-fit flex items-start gap-2 mb-6">
      <div className="w-fit flex flex-col items-start">
        <div className="flex items-center w-full gap-2 mb-1">
          {Icon && (
            <Icon size={32} weight="fill" className="text-secondary-500" />
          )}
          <p
            className={clsx(
              "text-2xl font-bold text-primary-500 text-left"
              // isClickable ? "cursor-pointer" : ""
            )}
            // onClick={() => {
            //   if (isClickable) navigate(titleBasedOnRoute?.route as string)
            // }}
          >
            {title || titleBasedOnRoute?.title}
          </p>
        </div>
        <div className="w-1/3 h-[3px] bg-primary-500 rounded-full" />
      </div>
      {breadcrumb && (
        <div className="flex items-center mt-[3px] gap-2">
          {breadcrumb.map((item, index) => (
            <div key={index} className="flex items-center gap-2">
              <PhosphorIcons.CaretRight
                size={22}
                weight="bold"
                className="text-primary-500"
              />
              <p
                className={clsx(
                  "text-primary-500 text-lg",
                  item.route ? "cursor-pointer" : ""
                )}
                onClick={() => {
                  if (item.route) navigate(item.route as string)
                }}
              >
                {item.title}
              </p>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default UnderlineTitle
