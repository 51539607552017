import React, { useEffect, useState } from "react"
import ReactDOM from "react-dom"
import clsx from "clsx"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import { formatNumberToMoney } from "@utils/money"
import { IProduct, IVariant } from "@interfaces/Services"
import { SimpleButton } from "@components/Buttons/SimpleButton"
import GenericModal from "@components/GenericModal"
import Select from "@components/Inputs/Select"
import { ICartItem } from "@interfaces/Components/Ecommerce"
import { toast } from "sonner"
import { PlusCircle } from "phosphor-react"
import { Tooltip } from "react-tooltip"
import QuantitySelector from "./QuantitySelector"

const schema = yup.object().shape({
  selectedVariant: yup.string().required("Variação é obrigatória"),
  quantity: yup
    .number()
    .required("Quantidade é obrigatória")
    .positive("Quantidade deve ser maior que zero")
    .integer("Quantidade deve ser um número inteiro"),
})

const ProductDetailsModal = ({
  isOpen,
  onClose,
  product,
  defaultSelectedVariant,
  onAddCart,
}) => {
  const {
    register,
    handleSubmit,
    setValue,
    control,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      quantity: 1,
      selectedVariant: defaultSelectedVariant?.id || "",
    },
    resolver: yupResolver(schema),
  })

  const variantsOrderByPrice =
    product.variants?.sort((a, b) => a.price - b.price) || []

  const generateNameVariant = (variant) => {
    if (variant.type_key === "units") {
      return product.name
    } else {
      return `${product.name} - ${variant.type_value} ${variant.type_name}`
    }
  }

  const onSubmit = (data) => {
    const selectedVariant = variantsOrderByPrice.find(
      (variant) => variant.id === data.selectedVariant
    )

    if (selectedVariant && onAddCart) {
      onAddCart(
        {
          ...selectedVariant,
          name: generateNameVariant(selectedVariant),
          image_url: selectedVariant.image_url,
          lastPrice: selectedVariant.sale_price || selectedVariant.price,
          quantity: data.quantity,
        },
        data.quantity
      )
    }
    onClose()
    setValue("quantity", 1)
  }

  const handleClose = () => {
    onClose()
    setValue("quantity", 1)
  }

  const quantityCurrentValue = watch("quantity")
  const selectedVariant = variantsOrderByPrice.find(
    (variant) => variant?.id === watch("selectedVariant")
  )

  const variantsOptions = variantsOrderByPrice.map((variant) => ({
    value: variant.id,
    label: `${variant.type_value} ${variant.type_name} ${
      variant.sale_price
        ? `- ${Math.floor(
            ((variant?.price - variant?.sale_price) / variant?.price) * 100
          )}% OFF`
        : ""
    }`,
  }))

  const salesFromOptions =
    selectedVariant?.sales.map((sale) => ({
      from: sale.from,
      price: sale.price,
    })) || []

  const [saleSelected, setSaleSelected] = useState(salesFromOptions[0] || {})

  useEffect(() => {
    setSaleSelected(salesFromOptions[0] || {})
  }, [selectedVariant])

  const handleSaleChange = (e) => {
    const selectedSale = salesFromOptions.find(
      (sale) => sale.from === e.target.value
    )
    setSaleSelected(selectedSale || {})
  }

  const handleAddSaleToCart = () => {
    setValue(
      "quantity",
      Number(quantityCurrentValue) + Number(saleSelected.from)
    )
  }

  const unitTypeIsUniqueUnity = product.variants[0].type_key === "units"

  return ReactDOM.createPortal(
    <GenericModal
      isOpen={isOpen}
      onClose={handleClose}
      title={product.name}
      className="w-11/12 md:w-fit"
    >
      <div className="flex flex-col items-start gap-4">
        <div className="w-full flex items-center justify-center">
          {selectedVariant?.image_url && (
            <div className="relative w-fit md:w-72 h-fit overflow-hidden group">
              <img
                src={selectedVariant.image_url}
                alt="uploaded"
                className="w-full h-full object-scale-down"
              />
            </div>
          )}
        </div>
        <div className="w-full rounded text-primary-400 bg-opacity-100 text-sm flex-col">
          <div className="w-full flex flex-col items-center justify-between">
            <span
              className={clsx(
                selectedVariant?.sale_price
                  ? "text-xl line-through text-gray-400"
                  : "text-2xl font-semibold mb-2 text-primary-500"
              )}
            >
              {formatNumberToMoney(selectedVariant?.price)}
            </span>
            {selectedVariant?.sale_price ? (
              <span className="text-2xl font-semibold text-secondary-400">
                {formatNumberToMoney(selectedVariant.sale_price)}
              </span>
            ) : null}
            {salesFromOptions.length > 0 && (
              <div className="text-gray-700 font-normal text-base flex items-center gap-2">
                <span>A partir de </span>
                <select
                  className="font-medium px-1 outline-none border-none"
                  value={saleSelected.from}
                  onChange={handleSaleChange}
                >
                  {salesFromOptions.map((sale) => (
                    <option key={sale.from} value={sale.from}>
                      {sale.from}
                    </option>
                  ))}
                </select>
                <span>por</span>
                <span className="text-primary-500 font-semibold text-lg">
                  {formatNumberToMoney(saleSelected.price)}
                </span>
                <Tooltip id={`sale-${saleSelected.from}`} />
                <PlusCircle
                  size={24}
                  weight="fill"
                  className="text-primary-500 cursor-pointer"
                  data-tooltip-id={`sale-${saleSelected.from}`}
                  data-tooltip-content={`Adicione ${saleSelected.from} ao carrinho`}
                  onClick={handleAddSaleToCart}
                />
              </div>
            )}
          </div>
        </div>
        {!unitTypeIsUniqueUnity ? (
          <div className="w-full flex flex-col md:flex-row gap-4">
            <Select
              label="Selecione a variação"
              options={variantsOptions}
              control={control}
              register={register("selectedVariant")}
              className="w-64"
              position="top"
            />
            <QuantitySelector
              quantityCurrentValue={quantityCurrentValue}
              setValue={setValue}
              errors={errors}
            />
          </div>
        ) : null}
        <div
          className={clsx("", {
            "w-full flex flex-col-reverse md:flex-row items-center justify-center gap-4":
              !unitTypeIsUniqueUnity,
            "w-full flex items-end justify-center gap-4": unitTypeIsUniqueUnity,
          })}
        >
          {unitTypeIsUniqueUnity ? (
            <QuantitySelector
              quantityCurrentValue={quantityCurrentValue}
              setValue={setValue}
              errors={errors}
            />
          ) : null}
          <SimpleButton
            label="Adicionar ao carrinho"
            size={unitTypeIsUniqueUnity ? "small" : "full"}
            onClick={handleSubmit(onSubmit)}
          />
        </div>
      </div>
    </GenericModal>,
    document.body
  )
}

export default ProductDetailsModal
