import { IDatePicker } from "@interfaces/Components"
import React from "react"
import Datepicker, { DateType } from "react-tailwindcss-datepicker"

const formatDateToSave = (date: DateType): DateType => {
  if (date) {
    return new Date(date.setHours(0, 0, 0, 0))
  }

  return null
}

const DatePicker: React.FC<IDatePicker> = ({
  valueDate,
  setValueDate,
}: IDatePicker) => {
  return (
    <div className="lg:w-[280px] mb-4">
      <p
        className="mb-2 text-sm text-gray-500 dark:text-white"
        data-testid="datepicker-label"
      >
        Data
      </p>
      <Datepicker
        value={valueDate}
        onChange={(newValue) => {
          if (newValue) {
            setValueDate({
              startDate: formatDateToSave(newValue.startDate),
              endDate: formatDateToSave(newValue.endDate),
            })
          }
        }}
        placeholder="Selecione a data"
        i18n="pt"
        separator=" - "
        showShortcuts
        popoverDirection="down"
        configs={{
          shortcuts: {
            yesterday: "Ontem",
            today: "Hoje",
            currentMonth: "Este mês",
            pastMonth: "Mês passado",
            past: (period) => `Últimos ${period} dias`,
          },
        }}
        maxDate={new Date()}
        classNames={{
          input: () =>
            "relative py-2.5 pl-4 pr-14 w-full border-[1px] border-gray-400 rounded-lg tracking-wide font-light text-sm placeholder-gray-400 bg-white outline-none",
        }}
      />
    </div>
  )
}

export default DatePicker
