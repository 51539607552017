import { PaymentMethod } from "@interfaces/Services"
import { clientApi } from "./clientApi"
import { generateErrorMessage } from "@utils/generateErrorMessage"

const profileService = {
  updateSettings: {
    key: "updateSettings",
    fn: async (data) => {
      try {
        const response = await clientApi.patch("/profiles/me/settings", data)

        return response.data
      } catch (error: any) {
        return generateErrorMessage("Erro ao atualizar configurações: ", error)
      }
    },
  },
  getMyImages: {
    key: "getMyImages",
    fn: async () => {
      try {
        const response = await clientApi.get("/profiles/my_images")

        return response.data
      } catch (error: any) {
        return generateErrorMessage("Erro ao buscar imagens: ", error)
      }
    },
  },
  uploadImages: {
    key: "uploadImages",
    fn: async (data: FormData) => {
      try {
        const response = await clientApi.post("/profiles/my_images", data)

        return response.data
      } catch (error: any) {
        return generateErrorMessage("Erro ao salvar imagens: ", error)
      }
    },
  },
  deleteImage: {
    key: "deleteImage",
    fn: async (image_name: string) => {
      try {
        const response = await clientApi.delete("/profiles/my_images", {
          params: {
            image_name,
          },
        })

        return response.data
      } catch (error: any) {
        return generateErrorMessage("Erro ao deletar imagem: ", error)
      }
    },
  },
  findPaiedFeatures: {
    key: "findPaiedFeatures",
    fn: async () => {
      try {
        const response = await clientApi.get("/profiles/paied_features")

        return response.data
      } catch (error: any) {
        return generateErrorMessage(
          "Erro ao buscar funcionalidades pagas: ",
          error
        )
      }
    },
  },
}

export { profileService }
