export const reactSelectStyles: any = {
  control: (provided: any) => ({
    ...provided,
    height: "42px !important",
    borderRadius: "8px",
    fontSize: "15px",
    border: "1px solid var(--primary-500) !important",
    ":hover": {
      border: "1px solid var(--primary-500) !important",
    },
    ":focus": {
      border: "1px solid var(--primary-500) !important",
    },
    ":active": {
      border: "1px solid var(--primary-500) !important",
    },
    boxShadow: "none !important",
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    fontWeight: state.isSelected ? "bold" : "normal",
    backgroundColor: state.isSelected ? "var(--primary-500)" : "white",
    ":hover": {
      backgroundColor: state.isSelected
        ? "var(--primary-500)"
        : "var(--primary-400)",
      color: "white",
    },
    ":active": {
      backgroundColor: state.isSelected ? "var(--primary-300)" : "white",
    },
  }),
  menu: (provided: any) => ({
    ...provided,
  }),
}
