import { SimpleButton } from "@components/Buttons/SimpleButton"
import GenericModal from "@components/GenericModal"
import * as PhosphorIcons from "phosphor-react"
import React from "react"

interface IConfirmActionModal {
  isOpen: boolean
  title: string
  message: string
  onConfirm: () => void
  onCancel: () => void
  icon: keyof typeof PhosphorIcons
  isPending?: boolean
}

const ConfirmActionModal: React.FC<IConfirmActionModal> = ({
  isOpen,
  title,
  message,
  icon,
  isPending,
  onConfirm,
  onCancel,
}) => {
  return (
    <GenericModal
      isOpen={isOpen}
      onClose={onCancel}
      title={title}
      label={message}
      icon={icon}
    >
      <div className="flex gap-2 justify-between">
        <SimpleButton
          label="Cancelar"
          size="full"
          onClick={onCancel}
          isLoading={isPending}
        />
        <SimpleButton
          label="Confirmar"
          size="full"
          variant="primary"
          mode="outline"
          onClick={onConfirm}
          isLoading={isPending}
        />
      </div>
    </GenericModal>
  )
}

export default ConfirmActionModal
