import UnderlineTitle from "@components/UnderlineTitle"
import React from "react"

const Admin: React.FC = ({}) => {
  return (
    <>
      <div className="flex justify-between items-center w-full">
        <UnderlineTitle />
      </div>
    </>
  )
}

export default Admin
