import InputWithLabel from "@components/Inputs/InputWithLabel"
import Logo from "@assets/Logo"
import React, { useEffect, useState } from "react"
import { publicRoutes } from "@constants/Routes"
import { resetPassword } from "@services/SignIn/resetPassword"
import { SimpleButton } from "@components/Buttons/SimpleButton"
import { toast } from "sonner"
import { useMutation } from "@tanstack/react-query"
import { useNavigate, useSearchParams } from "react-router-dom"

const ResetPassword: React.FC = ({}) => {
  const navigate = useNavigate()

  const queryParam = useSearchParams()[0]
  const tokenHash = queryParam.get("token_hash") || ""

  const [resetPasswordData, setResetPasswordData] = useState({
    newPassword: "",
    confirmPassword: "",
  })

  const handleChangeUserData = (field: string, value: string) => {
    setResetPasswordData({
      ...resetPasswordData,
      [field]: value,
    })
  }

  const onSuccess = () => {
    navigate(publicRoutes.signIn)
  }

  const { mutate: resetPasswordMutation, isPending } = useMutation({
    mutationFn: resetPassword.fn,
    mutationKey: [resetPassword.key],
    onSuccess,
    onError: () => {
      navigate(publicRoutes.signIn)
      toast.error(
        "Erro ao redefinir senha: Faça uma nova solicitação de redefinição"
      )
    },
  })

  useEffect(() => {
    if (!tokenHash) {
      navigate(publicRoutes.signIn)
    }
  }, [tokenHash])

  const hasError =
    resetPasswordData.newPassword === "" ||
    resetPasswordData.confirmPassword === "" ||
    resetPasswordData.newPassword !== resetPasswordData.confirmPassword

  return (
    <div className="bg-secondary-100 w-screen h-screen flex flex-col items-center justify-center">
      <div className="w-11/12 lg:max-w-xl bg-white rounded-3xl flex justify-start items-center flex-col relative py-8">
        <div className="mb-8">
          <Logo size="xsmall" />
        </div>
        <div
          className="absolute top-8 left-8 cursor-pointer"
          onClick={() => navigate(publicRoutes.signIn)}
        >
          <svg
            width="20"
            height="16"
            viewBox="0 0 20 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M18.25 8H1.75"
              stroke="#6E6D6D"
              strokeWidth="2"
              strokeLinecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M8.5 1.25L1.75 8L8.5 14.75"
              stroke="#6E6D6D"
              strokeWidth="2"
              strokeLinecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <div className="text-xl lg:text-2xl text-primary-500 mb-4">
          Redefinição de senha
        </div>
        <div className="max-w-80 text-base text-center text-neutral-200 mb-6">
          Em poucos passos você terá acesso a sua conta novamente.
        </div>
        <div className="w-10/12 text-base text-center text-primary-500 mb-8">
          Digite sua nova senha e confirme para continuar.
        </div>
        <div className="w-10/12 lg:w-7/12 flex flex-col items-center justify-center mb-6">
          <InputWithLabel
            placeholder="Nova senha"
            label="Insira sua nova senha"
            size="full"
            type="password"
            onType={(value) => handleChangeUserData("newPassword", value)}
          />
          <InputWithLabel
            placeholder="Confirme sua senha"
            label="Confirme sua senha"
            size="full"
            type="password"
            onType={(value) => handleChangeUserData("confirmPassword", value)}
          />
        </div>
        <SimpleButton
          label="Confirmar"
          onClick={() =>
            resetPasswordMutation({
              token_hash: tokenHash,
              password: resetPasswordData.newPassword,
            })
          }
          disabled={hasError}
          className="w-10/12 mt-8"
          isLoading={isPending}
        />
      </div>
    </div>
  )
}

export default ResetPassword
