import * as PhosphorIcons from "phosphor-react"
import React from "react"
import { ILoading } from "@interfaces/Components"

const Loading: React.FC<ILoading> = ({ iconLoading, size = 64 }) => {
  const Icon = iconLoading
    ? (PhosphorIcons[iconLoading] as React.FC<any>)
    : null

  if (!Icon) return null

  return <Icon size={size} className="text-primary-500 infiniteDraw" />
}

export default Loading
