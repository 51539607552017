import makeAnimated from "react-select/animated"
import React from "react"
import ReactSelect from "react-select"
import { ISelect } from "@interfaces/Components/Input"
import { reactSelectStyles } from "@constants/Components/ReactSelect"
import { Controller } from "react-hook-form"
import { Info } from "phosphor-react"
import Tooltip from "@components/Tooltip"

const animatedComponents = makeAnimated()

const Select: React.FC<ISelect> = ({
  label,
  options,
  placeholder,
  className,
  isDisabled,
  selectedValue,
  register,
  control,
  isRequired,
  onSelectOption,
  position,
  info,
}) => {
  const handleChange = (selected: any) => {
    onSelectOption?.(selected)
  }

  const renderSelect = (field: any) => {
    return (
      <ReactSelect
        className={`min-w-44 ${className}`}
        styles={reactSelectStyles}
        placeholder={placeholder || "Selecione uma opção"}
        closeMenuOnSelect={true}
        menuPlacement={position}
        components={animatedComponents}
        options={options}
        value={
          options.length === 0
            ? null
            : selectedValue === "" || (selectedValue === undefined && !field)
              ? null
              : options.find(
                  (option) =>
                    option.value === (field ? field.value : selectedValue)
                ) || null
        }
        onChange={(selected: any) => {
          if (field) {
            field.onChange(selected?.value)
          }
          handleChange(selected)
        }}
        defaultValue={options.length > 0 ? options[0] : null}
        isDisabled={isDisabled}
      />
    )
  }

  return (
    <div
      className={`flex flex-col justify-start items-start min-w-44 ${className}`}
    >
      {label && (
        <div className="flex justify-start items-center mb-1">
          {isRequired && <span className="text-red-500 text-sm mr-1">*</span>}

          <label className="w-full h-5 text-wrap text-sm text-left text-gray-800 font-medium">
            {label}
          </label>
          {info && (
            <Tooltip id={register ? register.name : ""} text={info}>
              <Info size={16} className="ml-1" />
            </Tooltip>
          )}
        </div>
      )}
      {control && register ? (
        <Controller
          name={register.name}
          control={control}
          render={({ field }) => renderSelect(field)}
        />
      ) : (
        renderSelect(null)
      )}
    </div>
  )
}

export default Select
