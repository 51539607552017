import clsx from 'clsx';
import React from 'react';
import { ISeparator } from '@interfaces/Components/Separator';
import { SeparatorSizes } from '@constants/Components/Separator';

const Separator: React.FC<ISeparator> = ({ size = "small", middleText }) => {
  return (
    <div className="flex justify-center items-center">
      <div
        className={clsx("h-[1px] bg-gray-400 rounded-full", {
          [SeparatorSizes[size]]: true,
        })}
      />
      {middleText && (
        <div className="mx-3 text-gray-400 font-medium">{middleText}</div>
      )}
      <div
        className={clsx("h-[1px] bg-gray-400 rounded-full", {
          [SeparatorSizes[size]]: true,
        })}
      />
    </div>
  )
}

export default Separator
