import React from "react"
import SideBar from "./SideBar"

interface IPrivateHOC {
  children: React.ReactNode
}

// eslint-disable-next-line no-empty-pattern
const PrivateHOC: React.FC<IPrivateHOC> = ({ children }) => {
  return (
    <div className="w-full h-dvh relative flex justify-start items-start">
      <SideBar />
      <div
        className="w-full h-dvh flex flex-col justify-start items-start p-4 lg:p-8 overflow-x-auto"
        style={{ backgroundColor: "#f2f5f8" }}
      >
        {children}
      </div>
    </div>
  )
}

export default PrivateHOC
