export const formatStringMoneyToNumber = (value?: number | string) => {
  if (!value) return 0

  return Number(
    String(value)
      .replace(/\D/g, "")
      .replace(/(\d)(\d{2})$/, "$1.$2")
  )
}

export const formatNumberToMoney = (value: number) => {
  if (!value) return "R$ 0,00"
  return value.toLocaleString("pt-BR", {
    style: "currency",
    currency: "BRL",
  })
}
