import { IUser } from "@interfaces/Services"
import { segmentsService } from "@services/Segments"
import { userServices } from "@services/User"
import { toast } from "sonner"

interface IUpdateSessionProps {
  onSaveLogin?: (value: boolean) => void
  onSaveUserDetail: (data: IUser) => void
}

export const updateSession = async ({
  onSaveLogin,
  onSaveUserDetail,
}: IUpdateSessionProps): Promise<IUser | undefined> => {
  try {
    const data = await userServices.getUserDetails.fn()
    const segmentDetail =
      data?.segment_id &&
      (await segmentsService.getSegment.fn(data.segment_id as string))

    const dataWithSegment = {
      ...data,
      segment_key: segmentDetail ? segmentDetail.key : undefined,
    }

    onSaveLogin?.(true)
    onSaveUserDetail(dataWithSegment)

    return dataWithSegment
  } catch (error: any) {
    toast.error("Erro ao buscar detalhes do usuário")
  }
}
