import { Trash, PlusCircle } from "phosphor-react"

export const SimpleButtonIconsOptions = {
  trash: <Trash weight="bold" size={24} />,
  add: <PlusCircle weight="bold" size={24} />,
} as const

export const SimpleButtonSizes = {
  small: "w-full md:w-60",
  medium: "w-72",
  large: "w-96",
  full: "w-full",
  fitContent: "w-fit",
} as const
