import Benefits from "./Benefits"
import Price from "./Price"
import React, { useEffect, useState } from "react"
import { Plan } from "@interfaces/Components"
import { plansIds, plansKeys } from "@constants/Plans"
import { SimpleButton } from "../Buttons/SimpleButton"
import useStore from "@store"

interface IBasicPlan {
  selectedPlan: string
  isOpen: boolean
  onSelectedPlan: (plan: Plan) => void
  onCancel: (setIsLoading: any) => void
  isFirst7Days: boolean
  onlyView?: boolean
}

const BasicPlan: React.FC<IBasicPlan> = ({
  selectedPlan,
  isOpen,
  onlyView,
  isFirst7Days,
  onSelectedPlan,
  onCancel,
}) => {
  const [confirmChange, setConfirmChange] = useState(false)
  const [confirmCancel, setConfirmCancel] = useState(false)
  const [isLoading, setisLoading] = useState(false)

  const { userDetail } = useStore()

  useEffect(() => {
    if (!isOpen) {
      setTimeout(() => {
        setConfirmChange(false)
        setConfirmCancel(false)
      }, 300)
    }
  }, [isOpen])

  const handleSelectedPlan = () => {
    setisLoading(true)
    onSelectedPlan({
      id: plansIds.basic,
      key: plansKeys.basic,
    })
  }

  const handleCancel = () => {
    if (confirmCancel) {
      setisLoading(true)
      onCancel(setisLoading)
    } else {
      setConfirmCancel(true)
    }
  }

  const renderConfirmationMessage = () => {
    if (confirmCancel) {
      return (
        <p className="text-base font-medium text-secondary-500">
          Deseja realmente cancelar o plano Básico?{" "}
          {isFirst7Days
            ? "Você receberá um reembolso integral e perderá todos os benefícios."
            : "Você poderá continuar utilizando os benefícios até o final do ciclo de faturamento."}
        </p>
      )
    }

    if (confirmChange) {
      return (
        <p className="text-base font-medium text-secondary-500">
          Deseja realmente perder todos os benefícios do seu plano Premium?
        </p>
      )
    }

    return null
  }

  const canNotAssign =
    selectedPlan === plansKeys.basic ||
    (selectedPlan === plansKeys.premium && isFirst7Days)

  return (
    <div className="flex flex-col p-6 mx-auto xs:min-w-96 md:max-w-96 lg:w-full text-center text-gray-900 bg-white rounded-lg border border-gray-300 shadow-lg relative">
      <div className="flex flex-col gap-2 justify-center items-center">
        {selectedPlan === plansKeys.basic && (
          <div className="w-fit px-4 py-1 bg-primary-500 text-white rounded-full text-sm absolute -top-4">
            Plano atual
          </div>
        )}
        <h3 className="text-2xl font-semibold">Básico</h3>
        <p className="font-light text-gray-500 sm:text-lg">
          Pra quem quer crescer
        </p>
      </div>
      <Price price={69.9} salePrice={49.9} />
      <Benefits
        benefits={[
          "Pedidos ilimitados",
          "Produtos ilimitados",
          "Notificações ilimitadas",
          "Relatórios ilimitados",
          "Clientes ilimitados",
        ]}
      />
      {!onlyView && (
        <div className="mt-auto w-full flex justify-center flex-col gap-2">
          {renderConfirmationMessage()}
          <div className="flex flex-col gap-2">
            {!canNotAssign && (
              <SimpleButton
                label={
                  isLoading
                    ? "Aguarde..."
                    : selectedPlan === plansKeys.premium && isFirst7Days
                      ? "Voltar ao Básico"
                      : "Assinar Básico"
                }
                size="full"
                onClick={handleSelectedPlan}
                disabled={plansKeys.basic === selectedPlan}
                isLoading={isLoading}
                className={confirmChange ? "bg-gray-500 hover:bg-gray-600" : ""}
              />
            )}
            {confirmChange && (
              <SimpleButton
                label="Desistir da mudança"
                size="full"
                className="bg-red-500 hover:bg-red-600"
                onClick={() => setConfirmChange(false)}
              />
            )}
          </div>
          {plansKeys.basic === selectedPlan && (
            <>
              <SimpleButton
                label={
                  confirmCancel ? "Confirmar cancelamento" : "Cancelar plano"
                }
                size="full"
                className="!bg-gray-500 hover:!bg-gray-800"
                onClick={handleCancel}
                disabled={confirmChange || userDetail?.cancel_at_period_end}
                isLoading={isLoading}
              />
              {confirmCancel && (
                <SimpleButton
                  label="Desistir do cancelamento"
                  size="full"
                  className="bg-red-500 hover:bg-red-600"
                  onClick={() => setConfirmCancel(false)}
                  isLoading={isLoading}
                />
              )}
            </>
          )}
        </div>
      )}
    </div>
  )
}

export default BasicPlan
