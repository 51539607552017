import axios from "axios"

const isDev = process.env.NODE_ENV === "development"
// https://0b9gw7d2-3000.brs.devtunnels.ms/
const baseURL = isDev
  ? "http://localhost:3000"
  : "https://cliquepeca-backend.onrender.com/"

const clientApi = axios.create({
  baseURL,
})

export { clientApi }
