import React from "react"
import * as PhosphorIcons from "phosphor-react"
import { ITableActionItem } from "@interfaces/Components"

const TableActionItem: React.FC<ITableActionItem> = ({
  handleClick,
  icon,
  text,
  disabled,
}) => {
  const Icon = PhosphorIcons[icon] as React.FC<any>

  return (
    <button
      className="w-full flex justify-start items-center text-primary-600 px-2 p-1 rounded-md  cursor-pointer disabled:cursor-not-allowed disabled:bg-slate-200 disabled:text-slate-400 outline-none focus:outline-none"
      disabled={disabled}
      type="button"
      onClick={handleClick}
    >
      <Icon size={18} className="mr-2" />
      {text}
    </button>
  )
}

export default TableActionItem
