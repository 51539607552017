import React from "react"
import Price from "./Price"
import Benefits from "./Benefits"
import { plansKeys } from "@constants/Plans"

interface IFreePlan {
  selectedPlan: string
}

const FreePlan: React.FC<IFreePlan> = ({ selectedPlan }) => {
  return (
    <div className="flex flex-col p-6 mx-auto xs:min-w-96 md:max-w-96 lg:w-full text-center text-gray-900 bg-white rounded-lg border border-gray-300 shadow-lg relative">
      <div className="flex flex-col gap-2 justify-center items-center">
        {selectedPlan === plansKeys.free && (
          <div className="w-fit px-4 py-1 bg-primary-500 text-white rounded-full text-sm absolute -top-4">
            Plano atual
          </div>
        )}
        <h3 className="text-2xl font-semibold">Gratuito</h3>
        <p className="font-light text-gray-500 sm:text-lg">
          Pra quem está começando
        </p>
      </div>
      <Price price={0} salePrice={0} />
      <Benefits
        benefits={[
          "50 pedidos por mês",
          "20 produtos cadastrados",
          "5 notificações por mês/email",
          "1 relatório por mês",
          "20 clientes cadastrados",
        ]}
      />
    </div>
  )
}

export default FreePlan
