/* eslint-disable @typescript-eslint/no-explicit-any */
import { toast } from "sonner"
import { clientApi } from "../clientApi"
import { generateErrorMessage } from "@utils/generateErrorMessage"

interface IPostData {
  email: string
}

const forgotPassword = {
  fn: async (dataPost: IPostData): Promise<void> => {
    try {
      await clientApi.post("/auth/forgot_password", {
        email: dataPost.email,
      })

      toast.success(
        "Se tudo estiver correto, você receberá um email com as instruções para redefinir sua senha."
      )
    } catch (error: any) {
      generateErrorMessage("Erro ao enviar email: ", error)
    }
  },
  key: "forgotPassword",
}

export { forgotPassword }
