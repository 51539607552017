import Informations from "@components/NewProduct/Informations"
import Loading from "@components/Loading"
import React, { useEffect, useState } from "react"
import Sales from "@components/NewProduct/Sales"
import Status from "@components/NewProduct/Status"
import UnderlineTitle from "@components/UnderlineTitle"
import useStore from "@store"
import Variants from "@components/NewProduct/Variants"
import { fieldsProduct, fieldsVariant } from "@constants/Products"
import { formatNumberToMoney, formatStringMoneyToNumber } from "@utils/money"
import { FormProvider, useFieldArray, useForm } from "react-hook-form"
import { newProductSchema } from "@schemas/Products"
import { privateRoutes } from "@constants/Routes"
import { productsService } from "@services/Products"
import { queryClient } from "@services/queryClient"
import { segmentsService } from "@services/Segments"
import { SimpleButton } from "@components/Buttons/SimpleButton"
import { toast } from "sonner"
import { useMutation, useQuery } from "@tanstack/react-query"
import { useNavigate, useParams } from "react-router-dom"
import { yupResolver } from "@hookform/resolvers/yup"

interface ImageState {
  id: string
  fileDetails: File
  url?: string
}

const NewProduct: React.FC = () => {
  const navigate = useNavigate()
  const { userDetail } = useStore()
  const { productId } = useParams()

  const isCreationOfNewProduct = productId === undefined

  const [isLoading, setIsLoading] = useState(!isCreationOfNewProduct)

  const { data: productDetails } = useQuery({
    queryKey: [productsService.getProductDetails.key, productId],
    queryFn: () => productsService.getProductDetails.fn(productId as string),
    enabled: !isCreationOfNewProduct,
    retry: 1,
  })

  const { data: customFields } = useQuery({
    queryKey: [segmentsService.getSegmentsCustomFields.key],
    queryFn: () =>
      segmentsService.getSegmentsCustomFields.fn(userDetail?.segment_id || ""),
  })

  const schema = newProductSchema(customFields)

  const methods = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  })

  const {
    handleSubmit,
    control,
    formState: { isValid },
    trigger,
    setValue,
  } = methods

  const { append: appendVariants } = useFieldArray({
    name: "variants",
    control,
  })

  const allProductFields = fieldsProduct.concat(
    customFields?.product?.map((field) => ({
      label: field.label,
      key: field.key,
      info: field.description || "",
      placeholder: field.label,
      size: "full",
      isRequired: field.is_required,
      masks: field.mask as any,
      input_type: field.input_type,
      options: field.options,
      is_required: field.is_required,
    })) || []
  )

  const allVariantFields = fieldsVariant.concat(
    customFields?.variant?.map((field) => ({
      label: field.label,
      key: field.key,
      info: field.description || "",
      placeholder: field.label,
      size: "full",
      isRequired: field.is_required,
      masks: field.mask as any,
      input_type: field.input_type,
      options: field.options,
      is_required: field.is_required,
    })) || []
  )

  const { mutateAsync: createProduct, isPending: isCreatePending } =
    useMutation({
      mutationFn: productsService.createProduct.fn,
      onSuccess: () => {
        navigate(privateRoutes.products)
        toast.success("Produto criado com sucesso!")
      },
      onError: (error) => {
        toast.error("Erro ao criar produto: " + error.message)
      },
    })

  const { mutateAsync: updateProduct, isPending: isUpdatePending } =
    useMutation({
      mutationFn: productsService.updateProduct.fn,
      onSuccess: () => {
        navigate(privateRoutes.products)
        toast.success("Produto atualizado com sucesso!")
      },
      onError: (error) => {
        toast.error("Erro ao atualizar produto: " + error.message)
      },
    })

  const loadProductDetails = async (
    setValue: any,
    trigger: any,
    setIsLoading: (value: boolean) => void
  ) => {
    try {
      if (productDetails) {
        setValue("name", productDetails.name)
        setValue("isRelease", productDetails.is_release)

        productDetails.variants?.forEach((variant) => {
          appendVariants({
            ...variant,
            ...variant.custom_fields?.reduce((acc: any, field: any) => {
              acc[field.key] = field.value
              return acc
            }, {}),
            sale_price: formatNumberToMoney(variant.sale_price),
            price: formatNumberToMoney(variant.price),
          })
        })

        productDetails.custom_fields?.forEach((field: any) => {
          setValue(field.key, field.value)
        })

        trigger()
        setIsLoading(false)
      }
    } catch (error) {
      console.error("Erro ao carregar detalhes do produto:", error.message)
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (!isCreationOfNewProduct && productDetails?.id) {
      loadProductDetails(setValue, trigger, setIsLoading)
    }
  }, [isCreationOfNewProduct, productDetails?.id])

  const isEditing = !isCreationOfNewProduct
  const hasActionInProgress = isCreatePending || isUpdatePending
  const creationWasEnabled = isValid && !isCreatePending

  const formatFormDataToSubmit = (data: any) => {
    const formattedVariants = data.variants.map((variant: any) => {
      const variantsCustomFields =
        customFields?.variant?.map((field) => ({
          key: field.key,
          value: variant[field.key],
        })) || []

      const keysToDelete =
        customFields?.variant?.map((field) => field.key) || []

      keysToDelete.forEach((key) => {
        delete variant[key]
      })

      return {
        ...variant,
        price: formatStringMoneyToNumber(variant.price),
        sale_price: formatStringMoneyToNumber(variant.sale_price),
        sales: variant.sales.map((sale: any) => ({
          from: sale.from,
          price: formatStringMoneyToNumber(sale.price),
        })),
        custom_fields: variantsCustomFields,
      }
    })

    const variantsToDeleteIds = productDetails?.variants?.reduce(
      (acc: string[], variant) => {
        if (
          variant.id &&
          !data.variants.some((newVariant: any) => newVariant.id === variant.id)
        ) {
          acc.push(variant.id)
        }
        return acc
      },
      []
    )

    return {
      name: data.name,
      is_release: data.isRelease,
      custom_fields:
        customFields?.product?.map((field) => ({
          key: field.key,
          value: data[field.key],
        })) || [],
      variants: formattedVariants,
      variantsToDeleteIds,
    }
  }

  const handleFormSubmit = async (data: any) => {
    const formattedData = formatFormDataToSubmit(data)

    queryClient.resetQueries({
      queryKey: [productsService.listProducts.key],
    })

    if (isEditing) {
      await updateProduct({
        id: productId,
        ...formattedData,
      })
    } else {
      await createProduct(formattedData)
    }

    queryClient.resetQueries({
      queryKey: [productsService.getProductDetails.key, productId],
    })
  }

  return (
    <FormProvider {...methods}>
      <div className="w-full flex flex-col items-start justify-between lg:flex-row lg:items-center mb-8">
        <UnderlineTitle title="Novo produto" />
        <div className="w-full lg:w-fit flex justify-between lg:justify-end mt-4 gap-3">
          <SimpleButton
            label="Voltar"
            onClick={() => navigate(privateRoutes.products)}
            mode="outline"
            disabled={hasActionInProgress}
          />
          <SimpleButton
            label={isEditing ? "Salvar alterações" : "Criar produto"}
            disabled={!creationWasEnabled}
            isLoading={hasActionInProgress}
            onClick={handleSubmit(handleFormSubmit)}
          />
        </div>
      </div>
      {isLoading ? (
        <div className="flex justify-center items-center w-full h-full">
          <Loading iconLoading="Package" />
        </div>
      ) : (
        <div className="w-full grid grid-cols-1 lg:grid-cols-newProduct gap-4">
          <Informations
            fields={allProductFields}
            hasActionInProgress={hasActionInProgress}
          />
          <Variants
            fields={allVariantFields}
            hasActionInProgress={hasActionInProgress}
          />
          <Status hasActionInProgress={hasActionInProgress} />
        </div>
      )}
    </FormProvider>
  )
}

export default NewProduct
