import { MagnifyingGlass } from "phosphor-react"

interface SearchBarProps {
  onSearch: (search: string) => void
}

const SearchBar: React.FC<SearchBarProps> = ({ onSearch }) => {
  let timeout: NodeJS.Timeout

  const handleSearch = (search: string) => {
    clearTimeout(timeout)

    timeout = setTimeout(() => {
      onSearch(search)
    }, 500)
  }

  return (
    <label className="w-full border border-gray-400 flex items-center gap-2 rounded-lg p-2">
      <input
        type="text"
        className="w-full outline-none"
        placeholder="Pesquise pelo nome do item"
        onChange={(e) => {
          handleSearch(e.target.value)
        }}
      />
      <MagnifyingGlass size={20} />
    </label>
  )
}

export default SearchBar
