export const plansKeys = {
  free: "free",
  basic: "basic",
  premium: "premium",
  master: "master",
} as const

export const plansIds = {
  free: "price_1QD67MD3GkpV0dA0Vkj7UdIt",
  basic: "price_1QD67rD3GkpV0dA09RTR1xKi",
  premium: "price_1QD68BD3GkpV0dA0zcvcb3L4",
} as const
