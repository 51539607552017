import { Field } from "@interfaces/Pages/Products"
import { formatStringMoneyToNumber } from "@utils/money"
import * as yup from "yup"

export const fieldsProduct: Field[] = [
  {
    label: "Nome do produto",
    key: "name",
    info: "",
    placeholder: "Nome do produto",
    size: "full",
    input_type: "text",
    is_required: true,
    max_length: 40,
  },
]

export const fieldsVariant: Field[] = [
  {
    label: "Tipo",
    key: "type_name",
    info: "",
    placeholder: "Tipo",
    size: "full",
    default_value: "",
    input_type: "text",
    is_required: true,
    disabled: true,
  },
  {
    label: "Valor",
    key: "type_value",
    info: "",
    placeholder: "200",
    size: "full",
    default_value: "",
    input_type: "text",
    is_required: true,
  },
  {
    label: "Preço",
    key: "price",
    info: "",
    placeholder: "Preço",
    size: "full",
    default_value: "",
    masks: "money",
    input_type: "text",
    is_required: true,
  },
  {
    label: "Disponível",
    key: "is_available",
    info: "Se o produto está disponível para venda",
    placeholder: "Disponível",
    size: "full",
    default_value: false,
    input_type: "checkbox",
    is_required: false,
  },
  {
    label: "Preço Promocional",
    key: "sale_price",
    info: "Ao adicionar um preço promocional, o produto será exibido com o preço promocional.",
    placeholder: "Preço Promocional",
    size: "full",
    default_value: "",
    masks: "money",
    input_type: "text",
    is_required: false,
    customSchema: yup
      .string()
      .test(
        "is-lower-than-price",
        "O preço promocional deve ser menor que o preço padrão",
        function (value) {
          const price = formatStringMoneyToNumber(this.parent.price)
          const salePrice = formatStringMoneyToNumber(value)

          return salePrice < price
        }
      ),
  },

  {
    label: "Imagem",
    key: "image_url",
    info: "Imagem do produto",
    placeholder: "Imagem",
    size: "full",
    default_value: "",
    input_type: "text",
    is_required: false,
  },
  {
    label: "Promoções",
    key: "sales",
    info: "",
    placeholder: "",
    size: "full",
    default_value: [],
    input_type: "array",
    is_required: false,
  },
]

export const headersBySegment: {
  [key: string]: {
    key: string
    title: string
    size: number
    is_custom?: boolean
    is_sortable?: boolean
  }[]
} = {
  drugs: [
    {
      key: "name",
      title: "Nome",
      size: 100,
      is_custom: true,
      is_sortable: true,
    },
    {
      key: "variants",
      title: "Quantidade de variantes",
      size: 100,
      is_custom: true,
      is_sortable: true,
    },
    {
      key: "is_available",
      title: "Alguma variante disponível",
      size: 100,
      is_custom: true,
      is_sortable: true,
    },
    { key: "link", title: "Info. do fabricante", size: 100, is_custom: true },
    {
      key: "actions",
      title: "Ações",
      size: 100,
      is_custom: true,
    },
  ],
}

export const tableVisibleColumnsBySegment = {
  drugs: headersBySegment.drugs.map((header) => header.key),
}
