import Card from "@components/Card"
import clsx from "clsx"
import GenericModal from "@components/GenericModal"
import InputWithLabel from "@components/Inputs/InputWithLabel"
import Popup from "reactjs-popup"
import React, { useState } from "react"
import { IVariants } from "@interfaces/Pages/Products"
import { Plus, X } from "phosphor-react"
import { PopUpContentStyle } from "@constants/Components"
import { SimpleButton } from "@components/Buttons/SimpleButton"
import { useFieldArray, useFormContext } from "react-hook-form"
import ToggleInput from "@components/Inputs/ToggleInput"
import { segmentsService } from "@services/Segments"
import { useQuery } from "@tanstack/react-query"
import useStore from "@store"
import Gallery from "./Gallery"

const Variants: React.FC<IVariants> = ({ fields, hasActionInProgress }) => {
  const { userDetail } = useStore()

  const requiredFields = fields.filter((field) => field.is_required)

  const optionalKeysToIgnore = ["is_available", "image_url", "sales"]
  const optionalFields = fields.filter(
    (field) => !field.is_required && !optionalKeysToIgnore.includes(field.key)
  )

  const { data: variantTypes } = useQuery({
    queryKey: [segmentsService.getSegmentsVariantTypes.key],
    queryFn: () =>
      segmentsService.getSegmentsVariantTypes.fn(userDetail?.segment_id || ""),
  })

  const { register, control, watch } = useFormContext()

  const {
    fields: fieldsVariant,
    append: appendVariant,
    remove: removeVariant,
    update: updateVariant,
  } = useFieldArray({
    name: "variants",
    control,
  })

  const variantFields = fieldsVariant as any

  const [openModalIndex, setOpenModalIndex] = useState<number | null>(null)
  const [openModalGallery, setOpenModalGallery] = useState<number | null>(null)

  const defineColSpan = (fieldsQuantity: number, fieldIndex: number) => {
    return fieldsQuantity % 2 === 0 || fieldIndex !== fieldsQuantity - 1
      ? "col-span-1"
      : "col-span-2"
  }

  const filteredVariantFields =
    watch("type") === "n/a"
      ? variantTypes?.filter((type) => type?.type !== "number")
      : variantTypes

  return (
    <Card className="w-full max-h-64 overflow-y-auto flex gap-1 flex-col col-s">
      <div className="w-full flex justify-center items-end mb-4">
        <div className="w-full flex flex-col">
          <p className="text-lg font-semibold text-neutral-700">Variantes</p>
          <p className="text-base text-neutral-500">
            Adicione as variações de seu produto
          </p>
        </div>
        <Popup
          trigger={
            <Plus
              size={32}
              weight="fill"
              className={clsx("text-primary-500", {
                "opacity-30 cursor-not-allowed":
                  variantFields?.length >= 3 || hasActionInProgress,
              })}
            />
          }
          position="left center"
          on={["hover"]}
          mouseEnterDelay={200}
          contentStyle={{
            ...PopUpContentStyle,
            display:
              variantFields?.length >= 3 || hasActionInProgress
                ? "none"
                : "flex",
          }}
        >
          {filteredVariantFields?.map((variantType) => (
            <SimpleButton
              key={variantType.key}
              label={variantType.description}
              mode="outline"
              onClick={() => {
                appendVariant({
                  ...fields.reduce((acc, field) => {
                    acc[field.key] = field.default_value
                    return acc
                  }, {}),
                  type_key: variantType.key,
                  type_value: variantType.key === "units" ? "1" : "",
                  type_name: variantType.description,
                  type_suffix: variantType.suffix,
                  image_url: "https://placehold.co/500?text=Imagem",
                })
              }}
              className="w-full"
            />
          ))}
        </Popup>
      </div>
      <div
        className={clsx("w-full min-h-[120px] flex flex-col max-h-96 p-1", {
          "min-w-[500px]": variantFields.length > 0,
        })}
      >
        {variantFields.length > 0 ? (
          variantFields.map((variant: any, index) => {
            const allRequiredFieldsFilled = requiredFields.every((field) =>
              watch(`variants.${index}.${field.key}`)
            )

            return (
              <div className="w-full flex items-center gap-4" key={variant.id}>
                <div className="w-full h-fit grid gap-4 grid-cols-3">
                  {requiredFields.map((field) => {
                    console.log(
                      "🚀 ~ {requiredFields.map ~ watch(`variants.${index}`):",
                      watch(`variants.${index}`)
                    )

                    if (field.key === "type_name") {
                      return null
                    }

                    return (
                      <InputWithLabel
                        key={field.key}
                        label={
                          field.key === "type_value"
                            ? watch(`variants.${index}`).type_name
                            : field.label
                        }
                        placeholder={field.label}
                        masks={field.masks}
                        size="fit"
                        value={variant[field.key]}
                        register={register(`variants.${index}.${field.key}`)}
                        isDisabled={hasActionInProgress || field.disabled}
                      />
                    )
                  })}
                </div>
                <SimpleButton
                  label="Detalhes"
                  onClick={() => setOpenModalIndex(index)}
                  disabled={hasActionInProgress || !allRequiredFieldsFilled}
                  className="mt-2"
                />
                <X
                  size={32}
                  weight="bold"
                  className={clsx(
                    "text-red-300 mt-2",
                    hasActionInProgress && "opacity-30 cursor-not-allowed",
                    !hasActionInProgress && "hover:text-red-500 cursor-pointer"
                  )}
                  onClick={() => {
                    if (hasActionInProgress) return
                    removeVariant(index)
                  }}
                />
              </div>
            )
          })
        ) : (
          <div className="flex h-full justify-center items-center text-neutral-600">
            <p>
              Nenhuma variante adicionada. Clique no botão de adicionar para
              começar
            </p>
          </div>
        )}
      </div>
      {openModalIndex !== null && (
        <GenericModal
          isOpen={openModalIndex !== null}
          onClose={() => setOpenModalIndex(null)}
          title="Detalhes da Variante"
          className="w-11/12 lg:max-w-4xl"
        >
          <div className="w-full grid grid-cols-1 lg:grid-cols-newProduct gap-8 items-start justify-items-center">
            <img
              className="h-72 rounded-lg cursor-pointer"
              src={
                variantFields[openModalIndex].image_url ||
                "https://placehold.co/500?text=Imagem"
              }
              onClick={() => setOpenModalGallery(openModalIndex)}
            />
            <div className="flex flex-col justify-start items-start gap-4 mb-4">
              <div
                className={clsx(
                  "w-full grid grid-cols-1 gap-x-4 gap-y-1",
                  optionalFields.length > 1 ? "lg:grid-cols-2" : ""
                )}
              >
                {optionalFields.map((field, fieldIndex) => (
                  <InputWithLabel
                    key={field.key}
                    label={field.label}
                    placeholder={field.label}
                    masks={field.masks}
                    size={optionalFields.length === 1 ? "small" : "full"}
                    register={register(
                      `variants.${openModalIndex}.${field.key}`
                    )}
                    createEspaceToLabel={false}
                    isDisabled={hasActionInProgress || field.disabled}
                    className={defineColSpan(optionalFields.length, fieldIndex)}
                  />
                ))}
              </div>
              <ToggleInput
                title="Disponibilidade"
                description="Está disponível para venda?"
                name={`variants.${openModalIndex}.is_available`}
                register={register}
              />
              <div className="flex flex-col justify-between">
                <div className="w-full flex flex-col">
                  <div className="w-full flex justify-center items-end mb-4">
                    <div className="w-full flex flex-col">
                      <p className="text-lg font-semibold text-neutral-700">
                        Promoções
                      </p>
                      <p className="text-base text-neutral-500">
                        Adicione promoções para essa variante
                      </p>
                    </div>
                    <Plus
                      size={32}
                      weight="fill"
                      className={clsx("text-primary-500", {
                        "opacity-30 cursor-not-allowed":
                          variantFields[openModalIndex].sales?.length >= 3 ||
                          hasActionInProgress,
                        "cursor-pointer":
                          variantFields[openModalIndex].sales?.length < 3 &&
                          !hasActionInProgress,
                      })}
                      onClick={() => {
                        if (
                          variantFields[openModalIndex].sales?.length >= 3 ||
                          hasActionInProgress
                        )
                          return

                        updateVariant(openModalIndex, {
                          ...watch(`variants.${openModalIndex}`),
                          sales: [
                            ...(variantFields[openModalIndex].sales || []),
                            { from: "", price: "", id: Math.random() },
                          ],
                        })
                      }}
                    />
                  </div>
                  <div className="w-full overflow-y-auto overflow-x-hidden">
                    {variantFields[openModalIndex].sales?.length > 0 ? (
                      variantFields[openModalIndex].sales.map((sale, index) => (
                        <div
                          className="w-full flex items-center gap-4"
                          key={sale.id}
                        >
                          <div className="w-full flex flex-col md:flex-row md:gap-4 items-center">
                            <InputWithLabel
                              label="A partir de (quantidade)"
                              placeholder="A partir de (quantidade)"
                              size="full"
                              register={register(
                                `variants.${openModalIndex}.sales.${index}.from`
                              )}
                              masks="onlyNumbers"
                              isDisabled={hasActionInProgress}
                            />
                            <InputWithLabel
                              label="Valor promocional"
                              placeholder="Valor promocional"
                              size="full"
                              register={register(
                                `variants.${openModalIndex}.sales.${index}.price`
                              )}
                              masks="money"
                              isDisabled={hasActionInProgress}
                            />
                          </div>
                          <X
                            size={32}
                            weight="bold"
                            className={clsx(
                              "text-red-300",
                              hasActionInProgress &&
                                "opacity-30 cursor-not-allowed",
                              !hasActionInProgress &&
                                "hover:text-red-500 cursor-pointer"
                            )}
                            onClick={() => {
                              if (hasActionInProgress) return
                              updateVariant(openModalIndex, {
                                ...watch(`variants.${openModalIndex}`),
                                sales: variantFields[
                                  openModalIndex
                                ].sales.filter((_, i) => i !== index),
                              })
                            }}
                          />
                        </div>
                      ))
                    ) : (
                      <div className="flex h-full justify-center items-center text-neutral-600">
                        <p>
                          Nenhuma promoção adicionada. Clique no botão de
                          adicionar para incluir promoções.
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </GenericModal>
      )}
      {openModalGallery !== null && (
        <Gallery
          isOpen={openModalGallery !== null}
          setIsOpen={() => setOpenModalGallery(null)}
          handleSelectImage={(url) => {
            setOpenModalGallery(null)
            updateVariant(openModalIndex, {
              ...watch(`variants.${openModalIndex}`),
              image_url: url,
            })
          }}
        />
      )}
    </Card>
  )
}

export default Variants
