import clsx from "clsx"
import FullLogo from "@assets/FullLogo"
import Logo from "@assets/Logo"
import React, { useState } from "react"
import SideBarOption from "./SideBarOption"
import { CaretCircleLeft } from "phosphor-react"
import { ISideBar } from "@interfaces/Components"
import { privateRoutes, sidebarMenu } from "@constants/Routes"
import { useNavigate } from "react-router-dom"
import SideBarMobile from "./SideBarMobile"
import useStore from "@store"

const SideBar: React.FC<ISideBar> = ({}) => {
  const [isCollapsed, setIsCollapsed] = useState(false)
  const navigate = useNavigate()
  const { userDetail } = useStore()

  return (
    <>
      <SideBarMobile />

      <div
        className={clsx(
          "hidden lg:block h-full shadow-xl relative bg-white transition-all duration-700",
          isCollapsed ? "min-w-8" : "min-w-56"
        )}
      >
        <div
          className="w-full h-16 flex items-center justify-center mt-2 mb-6 cursor-pointer"
          onClick={() => navigate(privateRoutes.dashboard)}
        >
          <Logo size={isCollapsed ? "small" : "none"} />
          <FullLogo size={isCollapsed ? "none" : "small"} />
        </div>

        <CaretCircleLeft
          className={clsx(
            "absolute bottom-5 right-[-16px] text-primary-500 cursor-pointer transition-all duration-700",
            isCollapsed ? "rotate-180" : ""
          )}
          size={38}
          onClick={() => setIsCollapsed(!isCollapsed)}
          weight="fill"
        />
        <div
          className={clsx(
            "h-[calc(100vh-64px)] lg:h-[calc(100vh-150px)] p-2 flex flex-col justify-start items-start gap-2 "
          )}
        >
          {sidebarMenu(userDetail).map((option, index) => (
            <SideBarOption key={index} isCollapsed={isCollapsed} {...option} />
          ))}
        </div>
      </div>
    </>
  )
}

export default SideBar
