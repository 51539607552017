import React from "react"

const Sizes = {
  none: "w-0 h-0",
  xsmall: "w-8 h-8",
  small: "w-12 h-12",
  medium: "w-48 h-48",
  large: "w-60 h-60",
  xlarge: "w-72 h-72",
  "2xlarge": "w-96 h-96",
} as const

interface ILogo {
  size?: keyof typeof Sizes
}

const Logo: React.FC<ILogo> = ({ size = "small" }) => {
  return (
    <svg
      width="309"
      height="433"
      viewBox="0 0 309 433"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`transition-all duration-500 ${Sizes[size]}`}
    >
      <path
        d="M3 415.66V59.0054L17.8742 44.2026H290.568L305.893 59.9306V416.585L292.822 430H16.9728L3 415.66Z"
        fill="#42668C"
        stroke="#42668C"
        strokeWidth="6"
      />
      <path
        d="M281.265 69H27.5864C27.0341 69 26.5864 69.4477 26.5864 70V405.201C26.5864 405.986 27.4481 406.465 28.1142 406.051L51.48 391.532C51.8057 391.33 52.2186 391.331 52.5426 391.537L76.4565 406.676C76.771 406.875 77.17 406.883 77.4922 406.697L103.726 391.52C104.063 391.326 104.481 391.343 104.8 391.566L126.348 406.629C126.671 406.855 127.097 406.87 127.435 406.667L152.742 391.504C153.054 391.317 153.442 391.314 153.757 391.496L180.033 406.697C180.355 406.883 180.754 406.875 181.068 406.676L204.967 391.546C205.299 391.336 205.723 391.34 206.051 391.556L229.014 406.663C229.328 406.87 229.731 406.883 230.058 406.697L256.794 391.492C257.11 391.313 257.499 391.319 257.809 391.508L281.678 406.069C282.345 406.476 283.201 405.995 283.198 405.213L282.265 69.9972C282.263 69.446 281.816 69 281.265 69Z"
        fill="#FFFAED"
      />
      <path
        d="M98.2881 79.4604V29.6573C98.2881 29.105 98.7358 28.6573 99.2881 28.6573H127.478C128.031 28.6573 128.477 28.1871 128.493 27.6351C129.504 -6.15676 180.625 -4.25388 181.692 27.6383C181.71 28.1903 182.156 28.6573 182.708 28.6573H210.501C211.054 28.6573 211.501 29.105 211.501 29.6573V79.4604C211.501 80.0127 211.054 80.4604 210.501 80.4604H99.2881C98.7358 80.4604 98.2881 80.0127 98.2881 79.4604Z"
        fill="#42668C"
        stroke="#42668C"
        strokeWidth="6"
        strokeLinecap="round"
      />
      <path d="M102.75 121.399H241.119" stroke="#42668C" strokeWidth="5" />
      <path d="M102.75 167.658H241.119" stroke="#42668C" strokeWidth="5" />
      <path d="M102.75 213.915H241.119" stroke="#42668C" strokeWidth="5" />
      <path d="M102.75 260.175H241.119" stroke="#42668C" strokeWidth="5" />
      <path d="M102.75 306.434H241.119" stroke="#42668C" strokeWidth="5" />
      <path d="M102.75 352.694H241.119" stroke="#42668C" strokeWidth="5" />
      <ellipse
        cx="77.1559"
        cy="121.371"
        rx="7.99819"
        ry="8.20853"
        fill="#F77038"
      />
      <ellipse
        cx="77.1559"
        cy="167.338"
        rx="7.99819"
        ry="8.20853"
        fill="#F77038"
      />
      <ellipse
        cx="77.1559"
        cy="214.127"
        rx="7.99819"
        ry="8.20853"
        fill="#F77038"
      />
      <ellipse
        cx="77.1559"
        cy="260.094"
        rx="7.99819"
        ry="8.20853"
        fill="#F77038"
      />
      <ellipse
        cx="77.1559"
        cy="306.063"
        rx="7.99819"
        ry="8.20853"
        fill="#F77038"
      />
      <ellipse
        cx="77.1559"
        cy="352.851"
        rx="7.99819"
        ry="8.20853"
        fill="#F77038"
      />
    </svg>
  )
}

export default Logo
