export const ordersHeadersList = [
  {
    key: "code",
    title: "Código do pedido",
    size: 50,
    is_sortable: true,
  },
  {
    key: "name",
    title: "Nome do cliente",
    size: 100,
    is_sortable: true,
  },
  {
    key: "cnpj",
    title: "CNPJ do cliente",
    size: 80,
    is_sortable: true,
  },
  {
    key: "createdAt",
    title: "Data do pedido",
    size: 80,
    is_sortable: true,
  },
  {
    key: "total",
    title: "Total",
    size: 80,
    is_sortable: true,
  },
  {
    key: "status",
    title: "Status",
    size: 80,
    is_custom: true,
    is_sortable: true,
  },
  {
    key: "actions",
    title: "Ações",
    size: 30,
    is_custom: true,
  },
]

export const clientsHeadersList = [
  {
    key: "name",
    title: "Nome do cliente",
    size: 100,
    is_sortable: true,
  },
  {
    key: "email",
    title: "Email do cliente",
    size: 100,
    is_sortable: true,
  },
  {
    key: "cnpj",
    title: "CNPJ do cliente",
    size: 100,
    is_sortable: true,
  },
  {
    key: "status",
    title: "Pode comprar?",
    size: 100,
    is_custom: true,
    is_sortable: true,
  },
  {
    key: "actions",
    title: "Ações",
    size: 50,
    is_custom: true,
  },
]

export const orderDetailsHeadersList = [
  {
    key: "name",
    title: "Nome",
    size: 40,
    is_custom: true,
  },
  {
    key: "quantity",
    title: "Quantidade",
    size: 20,
  },
  {
    key: "price",
    title: "Valor unitário",
    size: 20,
  },
  {
    key: "total",
    title: "Valor total",
    size: 20,
  },
]
