import { fieldsProduct } from "@constants/Products"
import { Field } from "@interfaces/Pages/Products"
import { ICustomFields, ICustonFieldsObject } from "@interfaces/Services"
import * as yup from "yup"

export const createSchemaToCustomFields = (
  customFields: ICustonFieldsObject,
  keyField: "product" | "variant" = "product"
) => {
  return customFields?.[keyField]?.reduce((acc, field) => {
    let currentField = yup.string()

    if (field.is_required) {
      currentField = currentField.required("Campo obrigatório")
    }

    if (field.min_length) {
      currentField = currentField.min(
        field.min_length,
        `Mínimo de ${field.min_length} caracteres`
      )
    }

    if (field.max_length) {
      currentField = currentField.max(
        field.max_length,
        `Máximo de ${field.max_length} caracteres`
      )
    }

    if (field.pattern) {
      currentField = currentField.test(
        "pattern",
        field.validate_message || "Campo inválido",
        (value) => {
          if (!value) return true

          return field.pattern ? new RegExp(field.pattern).test(value) : true
        }
      )
    }

    acc[field.key] = currentField

    return acc
  }, {} as any)
}

export const createSchemaToBaseFields = (fields: Field[]) => {
  return fields.reduce((acc, field) => {
    let currentField = yup.string()

    if (field.is_required) {
      currentField = currentField.required("Campo obrigatório")
    }

    if (field.customSchema) {
      currentField = field.customSchema
    }

    acc[field.key] = currentField

    return acc
  }, {} as any)
}

// Form validation schema
export const newProductSchema = (
  customFields: ICustonFieldsObject
): yup.ObjectSchema<any> =>
  yup
    .object({
      sales: yup.array().of(
        yup.object({
          from: yup.string().required(),
          price: yup.string().required(),
        })
      ),
      // variants: yup
      //   .array()
      //   .of(
      //     yup
      //       .object({
      //         name: yup.string().required(),
      //         price: yup.string().required(),
      //         sale_price: yup.string().required(),
      //         custom_fields: yup
      //           .object()
      //           .shape(createSchemaToCustomFields(customFields, "variant")),
      //       })
      //       .required()
      //   )
      //   .required(),
      isRelease: yup.boolean(),
      isAvailable: yup.boolean(),
      ...createSchemaToBaseFields(fieldsProduct),
      ...createSchemaToCustomFields(customFields, "product"),
    })
    .required()
