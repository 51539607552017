import * as PhotonIcons from "phosphor-react"
import clsx from "clsx"
import React, { useEffect } from "react"
import { IGenericModal } from "@interfaces/Components"

const GenericModal: React.FC<IGenericModal> = ({
  title,
  label,
  icon,
  children,
  isOpen,
  alert,
  disabledClickOutside,
  className = "w-fit lg:max-w-2xl",
  onClose,
}) => {
  const Icon = icon ? (PhotonIcons[icon] as React.FC<any>) : () => null

  const refModal = React.useRef<HTMLDivElement>(null)

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (e.target === refModal.current) {
        onClose()
      }
    }

    const handleStopPropagation = () => {}

    if (disabledClickOutside) {
      refModal.current?.addEventListener("click", handleStopPropagation)
    } else {
      refModal.current?.addEventListener("click", handleClickOutside)
    }

    return () => {
      if (disabledClickOutside) {
        refModal.current?.removeEventListener("click", handleStopPropagation)
      } else {
        refModal.current?.removeEventListener("click", handleClickOutside)
      }
    }
  }, [refModal, onClose, disabledClickOutside])

  return (
    <div
      ref={refModal}
      className={clsx(
        "w-full h-full bg-black bg-opacity-50 absolute top-0 left-0 transition-all duration-100",
        isOpen ? "opacity-100 z-[9999999]" : "opacity-0 pointer-events-none z-0"
      )}
    >
      <div
        className={clsx(
          "p-4 max-h-[93%] overflow-y-auto bg-white rounded-lg shadow-lg absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2",
          className
        )}
      >
        <div className="flex justify-between items-center mb-4">
          {title && (
            <p className="text-xl font-semibold text-neutral-700">{title}</p>
          )}
          <PhotonIcons.X
            onClick={onClose}
            size={32}
            className="cursor-pointer text-neutral-500 hover:text-white hover:bg-primary-500 rounded-full p-1 transition-all duration-100"
          />
        </div>
        <div className="mb-4">
          <div className="flex items-center gap-2 mb-2">
            {icon && (
              <div className="w-fit p-2 border-[1px] border-neutral-400 rounded-lg">
                <Icon size={32} weight="fill" className="text-primary-500" />
              </div>
            )}
            {alert && (
              <p className="w-10/12 text-sm font-semibold text-primary-500">
                * {alert}
              </p>
            )}
          </div>
          {label && <p className="text-base text-neutral-500">{label}</p>}
        </div>
        {children}
      </div>
    </div>
  )
}

export default GenericModal
