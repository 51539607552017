import { SimpleButton } from "@components/Buttons/SimpleButton"
import Card from "@components/Card"
import ToggleInput from "@components/Inputs/ToggleInput"
import Title from "@components/Title"
import { IUser } from "@interfaces/Services"
import { profileService } from "@services/Profile"
import { queryClient } from "@services/queryClient"
import { userServices } from "@services/User"
import useStore from "@store"
import { useMutation, useQueries } from "@tanstack/react-query"
import React from "react"
import { toast } from "sonner"

// import { Container } from './styles';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ISecurity {}

// eslint-disable-next-line no-empty-pattern
const Security: React.FC<ISecurity> = ({}) => {
  const { userDetail, setUserDetail } = useStore()

  const [controlClients, setControlClients] = React.useState(
    userDetail?.settings?.only_clients_can_buy
  )

  const { mutate, isPending } = useMutation({
    mutationFn: profileService.updateSettings.fn,
    mutationKey: [profileService.updateSettings.key],
    onSuccess() {
      toast.success("Configurações atualizadas com sucesso"),
        setUserDetail({
          ...userDetail,
          settings: {
            ...userDetail.settings,
            only_clients_can_buy: controlClients,
          },
        } as IUser)
    },
    onError(error) {
      toast.error(error.message)
    },
  })

  const handleSave = () => {
    mutate({
      ...userDetail.settings,
      only_clients_can_buy: controlClients,
    })
  }

  const disableSubmit =
    controlClients === userDetail?.settings?.only_clients_can_buy

  if (userDetail?.settings?.only_clients_can_buy === undefined) return null

  return (
    <Card className="w-full lg:max-w-xl">
      <Title
        title="Segurança"
        info="Aqui você pode configurar a segurança da sua conta"
      />
      <ToggleInput
        name="control_clients"
        title="Controle de clientes"
        description="Permitir que apenas clientes cadastrados possam comprar"
        checked={controlClients}
        onChange={(e) => setControlClients(e.target.checked)}
      />
      <div className="flex justify-end mt-8">
        <SimpleButton
          label="Salvar"
          onClick={handleSave}
          isLoading={isPending}
          disabled={disableSubmit}
        />
      </div>
    </Card>
  )
}

export default Security
