import { DotsThree } from "phosphor-react"
import React, { useState } from "react"
import Popup from "reactjs-popup"
import TableActionItem from "./TableActionItem"
import { PopupPosition } from "reactjs-popup/dist/types"

interface ITableActions {
  id: string
  children?: React.ReactNode
  onCreate?: () => void
  onDelete?: (productId: string) => void
  onEdit?: (productId: string) => void
  position?: PopupPosition | PopupPosition[]
}

const TableActions: React.FC<ITableActions> = ({
  id,
  children,
  position = "bottom center",
  onDelete,
  onEdit,
  onCreate,
}) => {
  const [isOpen, setIsOpen] = useState(false)

  if (!isOpen) {
    return (
      <DotsThree
        size={28}
        weight="bold"
        className="cursor-pointer rotate-90 ml-3"
        onMouseEnter={() => setIsOpen(true)}
      />
    )
  }

  return (
    <Popup
      open={isOpen}
      trigger={() => (
        <DotsThree
          size={28}
          weight="bold"
          className="cursor-pointer rotate-90 ml-3"
        />
      )}
      position={position}
      on={["hover"]}
    >
      <div className="border-[1px] border-slate-200 shadow-xl bg-white rounded-lg p-2 flex flex-col gap-2 text-base font-regular">
        {onEdit && (
          <TableActionItem
            handleClick={() => {
              setIsOpen(false)
              onEdit?.(id)
            }}
            icon="Pen"
            text="Editar"
          />
        )}
        {onCreate && (
          <TableActionItem
            handleClick={() => {
              setIsOpen(false)
              onCreate?.()
            }}
            icon="Plus"
            text="Criar"
          />
        )}
        {children}
        {onDelete && (
          <TableActionItem
            handleClick={() => {
              setIsOpen(false)
              onDelete?.(id)
            }}
            icon="Trash"
            text="Excluir"
          />
        )}
      </div>
    </Popup>
  )
}

export default TableActions
