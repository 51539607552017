import Card from "@components/Card"
import ToggleInput from "@components/Inputs/ToggleInput"
import { IStatus } from "@interfaces/Pages/Products"
import React from "react"
import { useFormContext } from "react-hook-form"

const Status: React.FC<IStatus> = ({ hasActionInProgress }) => {
  const { register } = useFormContext()

  return (
    <Card className="w-full max-h-fit flex gap-8 flex-col justify-between">
      {/* <p className="text-lg font-semibold text-primary-500">Status</p> */}
      <div className="w-full flex flex-col">
        <p className="text-lg font-semibold text-neutral-700">Status</p>
        <p className="text-base text-neutral-500">
          Defina o status do seu produto
        </p>
      </div>
      <div className="flex flex-col gap-4">
        <ToggleInput
          register={register}
          name="isRelease"
          title="Lançamento"
          description="Este produto é um lançamento?"
          info="Ao marcar essa opção, o produto será exibido como um lançamento."
          disabled={hasActionInProgress}
        />
      </div>
    </Card>
  )
}

export default Status
