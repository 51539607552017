import { clientApi } from "../clientApi"
import { generateErrorMessage } from "@utils/generateErrorMessage"
import { toast } from "sonner"
/* eslint-disable @typescript-eslint/no-explicit-any */

const createAccount = {
  fn: async (newUserData: { email: string; password: string }) => {
    try {
      await clientApi.post("/auth/signup", newUserData)

      toast.success("Conta criada com sucesso!")
    } catch (error: any) {
      generateErrorMessage("Erro ao criar conta: ", error)
      throw new Error(error)
    }
  },
  key: "createAccount",
}

export { createAccount }
