import React from "react"
import { Funnel } from "phosphor-react"
import Popup from "reactjs-popup"
import { PopUpContentStyle } from "@constants/Components"
import { PopupPosition } from "reactjs-popup/dist/types"
import { SimpleButton } from "../Buttons/SimpleButton"
import GenericModal from "@components/GenericModal"
interface ITableFilters {
  children?: React.ReactNode
  position?: PopupPosition | PopupPosition[]
  filtersQuantity?: number
  clearFilters?: () => void
}

import { useMediaQuery } from "react-responsive"

const TableFilters: React.FC<ITableFilters> = ({
  children,
  position = "left top",
  filtersQuantity,
  clearFilters,
}) => {
  const isMobile = useMediaQuery({ maxWidth: 1024 })
  const [isOpen, setIsOpen] = React.useState(false)

  return (
    <div className="w-fit flex justify-end items-center">
      {isMobile ? (
        <>
          <GenericModal
            isOpen={isOpen}
            onClose={() => setIsOpen(false)}
            className="w-11/12 max-h-[90vh] flex flex-col justify-between"
          >
            {children}
            {clearFilters && (
              <SimpleButton
                label="Limpar filtros"
                onClick={clearFilters}
                size="full"
                disabled={!filtersQuantity}
              />
            )}
          </GenericModal>
          <div
            className="w-fit h-full flex justify-center items-center text-primary-500 border border-primary-500 p-1 px-4 rounded-md cursor-pointer relative"
            onClick={() => setIsOpen(true)}
          >
            {filtersQuantity > 0 ? (
              <div className="absolute top-0 -right-3 bg-primary-500 text-white rounded-full w-5 h-5 flex justify-center items-center">
                <p>{filtersQuantity}</p>
              </div>
            ) : null}
            <Funnel size={25} className="mr-2" weight="bold" />
            <p>Filtros</p>
          </div>
        </>
      ) : (
        <Popup
          trigger={() => (
            <div className="w-fit h-full flex justify-center items-center text-primary-500 border border-primary-500 p-1 px-4 rounded-md cursor-pointer relative gap-2">
              <Funnel size={25} className="" weight="bold" />
              <p>Filtros</p>
              {filtersQuantity ? (
                <div className="bg-primary-500 text-white rounded-full w-6 h-6 flex justify-center items-center">
                  <p>{filtersQuantity}</p>
                </div>
              ) : null}
            </div>
          )}
          position={position}
          on={["hover"]}
          contentStyle={PopUpContentStyle}
        >
          {children}
          {clearFilters && (
            <SimpleButton
              label="Limpar filtros"
              onClick={clearFilters}
              size="full"
              disabled={!filtersQuantity}
            />
          )}
        </Popup>
      )}
    </div>
  )
}

export default TableFilters
