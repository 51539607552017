export const PopUpContentStyle = {
  border: "1px solid #d3d3d3",
  boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.063)",
  backgroundColor: "white",
  borderRadius: "8px",
  padding: "10px",
  display: "flex",
  flexDirection: "column",
  gap: "10px",
  fontSize: "1rem",
  fontWeight: 600,
} as React.CSSProperties
