import { OrderDetail } from "@interfaces/Services"
import { clientApi } from "./clientApi"
import { generateErrorMessage } from "@utils/generateErrorMessage"

const ordersService = {
  downloadOrder: {
    key: "downloadOrder",
    fn: async (orderId: any) => {
      try {
        const { data } = await clientApi.get(`/orders/pdf/${orderId}`, {
          responseType: "blob",
        })
        return data
      } catch (error: any) {
        return generateErrorMessage("Erro ao baixar: ", error)
      }
    },
  },
  getOrder: {
    key: "getOrder",
    fn: async (orderId: any) => {
      try {
        const { data } = await clientApi.get<OrderDetail>(`/orders/${orderId}`)
        return data
      } catch (error: any) {
        return generateErrorMessage("Erro ao buscar: ", error)
      }
    },
  },
  listOrders: {
    key: "listOrders",
    fn: async ({ page, ...filters }: any) => {
      try {
        const { data } = await clientApi.get("/orders", {
          params: {
            page,
            ...filters,
          },
        })
        return data
      } catch (error: any) {
        return generateErrorMessage("Erro ao buscar: ", error)
      }
    },
  },
  deleteOrder: {
    key: "deleteOrder",
    fn: async (orderId: any) => {
      try {
        await clientApi.delete(`/orders/${orderId}`)
      } catch (error: any) {
        return generateErrorMessage("Erro ao deletar: ", error)
      }
    },
  },
  updateOrder: {
    key: "updateOrder",
    fn: async (data: any) => {
      try {
        await clientApi.put(`/orders/${data.id}`, data)
      } catch (error: any) {
        return generateErrorMessage("Erro ao atualizar: ", error)
      }
    },
  },
}
export { ordersService }
