import { clientApi } from "./clientApi"
import { toast } from "sonner"
import {
  IListProductsParams,
  ICreateProductParams,
  IListProducts,
  IEditProductParams,
  Product,
  IProduct,
} from "@interfaces/Services"
import { generateErrorMessage } from "@utils/generateErrorMessage"

const productsService = {
  getProductDetails: {
    fn: async (id: string) => {
      try {
        const { data } = await clientApi.get<IProduct>(`/products/${id}`)

        return data
      } catch (error: any) {
        return generateErrorMessage("Erro ao buscar: ", error)
      }
    },
    key: "getProductDetails",
  },
  listProducts: {
    fn: async (filters: IListProductsParams) => {
      try {
        const { data } = await clientApi.get(`/products`, {
          params: {
            name: filters.name,
            page: filters.page,
            type: filters.type,
            is_available: filters.isAvailable,
            is_release: filters.isRelease,
            custom_fields: Object.keys(filters.customFields).join(","),
            custom_values: Object.values(filters.customFields).join(","),
          },
        })

        return data
      } catch (error: any) {
        return generateErrorMessage("Erro ao buscar: ", error)
      }
    },
    key: "listProducts",
  },
  createProduct: {
    fn: async (data: ICreateProductParams) => {
      try {
        const { data: response } = await clientApi.post(`/products`, data)

        return response
      } catch (error: any) {
        return generateErrorMessage("Erro ao criar: ", error)
      }
    },
    key: "createProduct",
  },
  updateProduct: {
    fn: async (data: IEditProductParams) => {
      try {
        const { data: response } = await clientApi.put(
          `/products/${data.id}`,
          data
        )

        return response
      } catch (error: any) {
        return generateErrorMessage("Erro ao atualizar: ", error)
      }
    },
    key: "updateProduct",
  },
  deleteProduct: {
    fn: async (id: string) => {
      try {
        const { data } = await clientApi.delete(`/products/${id}`)

        return data
      } catch (error: any) {
        return generateErrorMessage("Erro ao deletar: ", error)
      }
    },
    key: "deleteProduct",
  },
}
export { productsService }

export type { ICreateProductParams, IListProducts }
