/* eslint-disable @typescript-eslint/no-explicit-any */
import { clientApi } from "../clientApi"
import { generateErrorMessage } from "@utils/generateErrorMessage"

interface IPostData {
  email: string
  password: string
}

export interface ITokenData {
  access_token: string
  expires_at: string
  refresh_token: string
}

const loginUser = {
  fn: async (dataPost: IPostData): Promise<ITokenData> => {
    try {
      const { data } = await clientApi.post("/auth/signin", {
        email: dataPost.email,
        password: dataPost.password,
      })

      return data as ITokenData
    } catch (error: any) {
      return generateErrorMessage("Erro ao realizar login: ", error)
    }
  },
  key: "loginUser",
}

export { loginUser }
