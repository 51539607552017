import * as PhosphorIcons from "phosphor-react"
import clsx from "clsx"
import Loading from "../Loading"
import React, { useState } from "react"
import { CaretDown, CaretUp } from "phosphor-react"
import { ITable } from "@interfaces/Components/Input"
import { SimpleButton } from "../Buttons/SimpleButton"
import { formatStringMoneyToNumber } from "@utils/money"

const sortTable = (field: string, order: string, data: any[]) => {
  if (!field) return data

  return data.sort((a, b) => {
    let aField = typeof a?.[field] === "object" ? a?.[field]?.value : a?.[field]
    let bField = typeof b?.[field] === "object" ? b?.[field]?.value : b?.[field]

    if (!aField || !bField) return 0

    const isMoney = aField?.includes("R$") || bField?.includes("R$")
    const isNumber = typeof aField === "number" || typeof bField === "number"

    if (isMoney || isNumber) {
      aField = formatStringMoneyToNumber(aField)
      bField = formatStringMoneyToNumber(bField)
    } else {
      aField = aField
        ?.normalize("NFD")
        ?.replace(/[\u0300-\u036f]/g, "")
        ?.toLowerCase()
      bField = bField
        ?.normalize("NFD")
        ?.replace(/[\u0300-\u036f]/g, "")
        ?.toLowerCase()
    }

    if (aField < bField) {
      return order === "asc" ? -1 : 1
    }
    if (aField > bField) {
      return order === "asc" ? 1 : -1
    }
    return 0
  })
}
const Table: React.FC<ITable> = ({
  headers = [],
  data = [],
  isPending,
  iconLoading,
  emptyIcon,
  seletedItems,
  customEmptyMessage,
  emptyAction,
  onSelectedItem,
}) => {
  const EmptyIconComponent: any = emptyIcon
    ? PhosphorIcons[emptyIcon]
    : PhosphorIcons.SmileySad
  const [order, setOrder] = useState({
    field: "",
    order: "",
  })

  const handleSetOrder = (field: string) => {
    if (order.field === field) {
      if (order.order === "desc") {
        setOrder({
          field: "",
          order: "",
        })
        return
      }

      setOrder({
        field,
        order: order.order === "asc" ? "desc" : "asc",
      })
      return
    }

    setOrder({
      field,
      order: "asc",
    })
  }

  const tableDataByOrder = sortTable(order.field, order.order, [...data])

  if (isPending)
    return (
      <div className="w-full h-full flex justify-center items-center">
        <Loading iconLoading={iconLoading} />
      </div>
    )

  if (data.length === 0) {
    return (
      <div className="w-full h-full flex flex-col justify-center items-center">
        <EmptyIconComponent
          size={64}
          weight="duotone"
          className="mb-4 text-gray-700"
        />
        <p
          className={clsx(
            "w-fit text-center text-xl font-normal text-gray-700",
            emptyAction && "mb-6"
          )}
        >
          {customEmptyMessage ||
            "Pedimos desculpas, mas não encontramos dados para exibir."}
        </p>
        {emptyAction && (
          <SimpleButton
            label={emptyAction.label}
            onClick={emptyAction.onClick}
          />
        )}
      </div>
    )
  }

  return (
    <div className="overflow-x-auto relative mb-4">
      <div className="w-full min-w-[1400px]">
        <div className="w-full flex justify-center items-center pb-4 mb-2 gap-4 border-b">
          <div className="w-8 mr-4 flex items-center">
            {/* {hasSelection && (
            <input
              aria-label="Single check"
              className="checkbox checkbox-sm rounded-sm"
              type="checkbox"
            />
          )} */}
          </div>
          {headers.map((header) => (
            <div
              key={header.key}
              className={clsx(
                "text-left font-semibold flex justify-start items-center gap-2 text-sm",
                header?.is_sortable && "cursor-pointer",
                order.field === header.key ? "text-gray-700" : "text-gray-700"
              )}
              style={{ width: header.size ? `${header.size}%` : "auto" }}
              onClick={() => {
                if (!header?.is_sortable) return
                handleSetOrder(header.key)
              }}
            >
              {header.title}
              {order.field === header.key && order.order === "asc" && (
                <CaretUp weight="fill" />
              )}
              {order.field === header.key && order.order === "desc" && (
                <CaretDown weight="fill" />
              )}
            </div>
          ))}
        </div>
        {/* Data */}
        <div className="w-full flex flex-col gap-4">
          {tableDataByOrder.map((row, index) => (
            <div
              key={index}
              className="w-full flex justify-center items-center py-2 border-b gap-4"
            >
              <div className="w-8 mr-4 flex items-center">
                {onSelectedItem && (
                  <input
                    aria-label="Single check"
                    className="checkbox checkbox-md rounded-full"
                    type="checkbox"
                    onChange={() => onSelectedItem?.(row?.id)}
                    checked={seletedItems?.includes?.(row?.id)}
                  />
                )}
              </div>
              {headers?.map?.((header) => (
                <div
                  key={header.key}
                  className="text-left text-gray-600 overflow-hidden text-sm"
                  style={{ width: header.size ? `${header.size}%` : "auto" }}
                >
                  {header?.is_custom
                    ? row?.[header?.key]?.component
                    : row?.[header?.key]}
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Table
