import { IUser } from "@interfaces/Services"
import { IPaiedFeatures } from "@interfaces/Store"
import { create } from "zustand"

interface IStore {
  userDetail?: IUser
  setUserDetail: (userDetail: IUser) => void
  isLogged?: boolean
  setIsLogged: (isLogged: boolean) => void
  paiedFeatures?: IPaiedFeatures
  setPaiedFeatures?: (paiedFeatures: IPaiedFeatures) => void
}

const useStore = create<IStore>()((saveState) => {
  return {
    setUserDetail: (userDetail) => saveState({ userDetail }),
    setIsLogged: (isLogged) => saveState({ isLogged }),
    setPaiedFeatures: (paiedFeatures) => saveState({ paiedFeatures }),
  }
})

export default useStore
