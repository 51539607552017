import Card from "@components/Card"
import ClientModal from "@components/Modals/ClientModal"
import InputWithLabel from "@components/Inputs/InputWithLabel"
import Pagination from "@components/Pagination"
import React, { useEffect, useState } from "react"
import Select from "@components/Inputs/Select"
import Table from "@components/Table/Table"
import TableFilters from "@components/Table/TableFilters"
import UnderlineTitle from "@components/UnderlineTitle"
import { clientsHeadersList } from "@interfaces/Constants/Pages"
import { clientsService } from "@services/Clients"
import { clientModalId } from "@constants/Components/Modals"
import { formatClientsData } from "@utils/formatters"
import { IOnFinishedClientData } from "@interfaces/Components/Modals"
import { SimpleButton } from "@components/Buttons/SimpleButton"
import { toast } from "sonner"
import { useMutation, useQuery } from "@tanstack/react-query"
import { useSearchParams } from "react-router-dom"
import ConfirmActionModal from "@components/Modals/ConfirmAction"
import { queryClient } from "@services/queryClient"

const Clients: React.FC = () => {
  const [params, setParams] = useSearchParams()

  const [pageFilters, setPageFilters] = useState({
    actualPage: 1,
    itemsPerPage: 15,
  })

  const [filters, setFilters] = useState({
    search: "",
    is_active: "",
  })

  const [lastQuantityOfTotalItems, setLastQuantityOfTotalItems] = useState(0)

  const [clientModalIsOpen, setClientModalIsOpen] = useState(false)
  const [deleteProductId, setDeleteProductId] = useState("")

  const clearCacheUseQuery = () => {
    queryClient.clear()
  }

  const handleChangePage = (page: number) => {
    setPageFilters({ ...pageFilters, actualPage: page })
  }

  const handleChangeFilter = (filtersToChange: any) => {
    setFilters({ ...filters, ...filtersToChange })
  }

  const handleChangeName = (text: string) => {
    handleChangeFilter({
      search: text,
    })
  }

  const onClosedModal = () => {
    setClientModalIsOpen(false)
    setParams({})
  }

  const {
    data: listClientsData,
    isFetching: isListClientsPending,
    refetch: refetchClientsList,
  } = useQuery({
    queryKey: [clientsService.listClients.key, filters, pageFilters],
    queryFn: () =>
      clientsService.listClients.fn({
        page: pageFilters.actualPage,
        ...filters,
      }),
  })

  const { mutate: deleteClient, isPending: isDeletePending } = useMutation({
    mutationFn: clientsService.deleteClient.fn,
    mutationKey: [clientsService.deleteClient.key],
    onSuccess: () => {
      toast.success("Cliente deletado com sucesso!")
      refetchClientsList()
    },
    onError: (error) => {
      toast.error("Erro ao deletar cliente: " + error.message)
    },
  })

  const { mutate: createClient, isPending } = useMutation({
    mutationFn: clientsService.createClient.fn,
    mutationKey: [clientsService.createClient.key],
    onSuccess: () => {
      toast.success("Cliente criado com sucesso")
      refetchClientsList()
      onClosedModal()
    },
    onError: () => {
      toast.error("Erro ao criar cliente")
    },
  })

  const { mutate: updateClient } = useMutation({
    mutationFn: clientsService.updateClient.fn,
    onSuccess: () => {
      clearCacheUseQuery()
      toast.success("Produto atualizado com sucesso!")
      setParams({})
      refetchClientsList()
    },
    onError: (error) => {
      toast.error("Erro ao atualizar produto: " + error.message)
    },
  })

  const onFinishedModal = (data: IOnFinishedClientData) => {
    if (selectedClientId) {
      updateClient({
        id: selectedClientId,
        name: data.name,
        email: data.email,
        cnpj: data.cnpj,
        phone: data.phone,
        is_active: data.isActive,
      })

      return
    }

    createClient({
      name: data.name,
      email: data.email,
      cnpj: data.cnpj,
      phone: data.phone,
      is_active: data.isActive,
    })
  }

  const howManyFiltersAreActive =
    Object.values(filters).filter((filter) => filter !== "").length || undefined

  useEffect(() => {
    if (
      listClientsData?.total &&
      listClientsData?.total !== lastQuantityOfTotalItems
    ) {
      setLastQuantityOfTotalItems(listClientsData?.total)
    }
  }, [listClientsData])

  const allFiltersAreEmpty = filters.search === "" && filters.is_active === ""

  const dontHasOrders =
    allFiltersAreEmpty &&
    (!listClientsData?.data || listClientsData?.data.length === 0)

  const customEmptyMessage = dontHasOrders ? (
    <div className="text-center flex flex-col gap-2">
      <p>Nenhum cliente encontrado.</p>
      <p>Você pode adicionar um novo cliente pressionando</p>
      <p>o botão no canto superior direito.</p>
    </div>
  ) : undefined

  const handleOpenEditProductModal = (id: string) => {
    setParams({ clientId: id })
  }

  const selectedClientId = params.get("clientId")
  const hasClientToDelete = deleteProductId !== ""

  return (
    <div className="w-full h-svh flex flex-col">
      {(clientModalIsOpen || selectedClientId) && (
        <ClientModal
          idModal={clientModalId}
          isOpen={clientModalIsOpen || selectedClientId !== ""}
          isPendingRequest={isPending}
          isEditing={!!selectedClientId}
          isLoadind={selectedClientId !== null}
          onClose={onClosedModal}
          onFinished={onFinishedModal}
        />
      )}
      {hasClientToDelete && (
        <ConfirmActionModal
          isOpen={hasClientToDelete}
          title="Deletar Cliente"
          message="Tem certeza que deseja deletar esse cliente?"
          onConfirm={() => {
            deleteClient(deleteProductId)
            setDeleteProductId("")
          }}
          onCancel={() => {
            setDeleteProductId("")
          }}
          icon="Users"
          isPending={isDeletePending}
        />
      )}
      <div className="flex justify-between items-center w-full">
        <UnderlineTitle />
      </div>
      <Card>
        <div className="flex w-full overflow-x-auto lg:overflow-hidden md:justify-end min-h-10 mb-6 gap-4 pb-2 lg:pb-0">
          {!dontHasOrders && (
            <>
              <TableFilters
                filtersQuantity={howManyFiltersAreActive}
                clearFilters={() => {
                  setFilters({
                    search: "",
                    is_active: "",
                  })
                }}
              >
                <div className="flex flex-col gap-4">
                  <Select
                    label="Status"
                    placeholder="Status"
                    className="w-full"
                    options={[
                      { value: "true", label: "Ativo" },
                      { value: "false", label: "Inativo" },
                    ]}
                    selectedValue={filters.is_active}
                    onSelectOption={({ value }) => {
                      handleChangeFilter({
                        is_active: value,
                      })
                    }}
                  />
                  <InputWithLabel
                    label="Pesquisar"
                    size="full"
                    rightIcon="MagnifyingGlass"
                    placeholder="Nome, Email ou CNPJ"
                    onType={handleChangeName}
                    value={filters.search}
                  />
                </div>
              </TableFilters>
              <SimpleButton
                label="Adicionar cliente"
                icon="Plus"
                onClick={() => {
                  setClientModalIsOpen(true)
                }}
                size="small"
              />
            </>
          )}
        </div>
        <Table
          headers={clientsHeadersList}
          data={formatClientsData(
            listClientsData?.data as any,
            setDeleteProductId,
            handleOpenEditProductModal
          )}
          isPending={isListClientsPending}
          iconLoading="Users"
          customEmptyMessage={customEmptyMessage}
        />
        {listClientsData?.total && listClientsData.total > 0 && (
          <div className="w-full flex justify-end items-center mt-auto">
            <Pagination
              currentPage={pageFilters.actualPage}
              itemsPerPage={pageFilters.itemsPerPage}
              totalItems={
                listClientsData?.total || lastQuantityOfTotalItems || 0
              }
              onPageChange={handleChangePage}
            />
          </div>
        )}
      </Card>
    </div>
  )
}

export default Clients
