import * as PhosphorIcons from "phosphor-react"
import clsx from "clsx"
import React, { useEffect, useState } from "react"
import { IInput } from "@interfaces/Components/Input"
import { InputSizes } from "@constants/Components/Input"
import { Masks } from "@constants/Masks"

const Input: React.FC<IInput> = ({
  size = "small",
  leftIcon,
  rightIcon = "X",
  placeholder,
  error,
  helperText,
  type = "text",
  masks,
  value,
  isDisabled,
  register,
  createEspaceToLabel = true,
  maxLength,
  suffix,
  onType,
  onEnter,
  handleClearFormFields,
}) => {
  const [textedValue, setTextedValue] = useState(value || "")

  useEffect(() => {
    setTextedValue(value || "")
  }, [value])

  const hasSomeValueTyped = textedValue.length > 0

  if (hasSomeValueTyped) {
    rightIcon = "X"
  }

  const LeftIcon = leftIcon ? (PhosphorIcons[leftIcon] as React.FC<any>) : null
  const RightIcon = PhosphorIcons[rightIcon] as React.FC<any>

  let nodeTimeout: NodeJS.Timeout
  const rightIconIsX = rightIcon === "X"

  return (
    <div className={InputSizes[size]}>
      <div className={clsx("relative", InputSizes[size])}>
        <div className="absolute top-[50%] left-2 transform -translate-y-1/2">
          {LeftIcon ? <LeftIcon size={24} className="text-gray-600" /> : null}
        </div>
        <input
          className={clsx(
            "bg-white text-gray-600 w-full flex justify-center items-center border border-gray-600 rounded-lg px-2 py-2 outline-none focus:border-transparent focus:ring-1 focus:ring-black transition-all duration-300 hover:border-black focus:border-black disabled:bg-neutral-200 disabled:text-neutral-500 placeholder:text-sm font-normal",
            {
              "pl-9": leftIcon,
              "pr-8": rightIcon || suffix,
              "border-red-500": error,
              "!px-0 !pl-2": size === "xsmall",
            }
          )}
          type={type}
          placeholder={placeholder}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              onEnter?.()
            }
          }}
          value={register ? register.value : textedValue}
          {...register}
          disabled={isDisabled}
          onChange={(e) => {
            if (masks) {
              e.target.value = Masks[masks](e.target.value)
            }

            setTextedValue(e.target.value)

            clearTimeout(nodeTimeout)
            nodeTimeout = setTimeout(() => {
              onType?.(e.target.value)
            }, 300)

            register?.onChange?.(e)
          }}
          maxLength={maxLength}
        />
        {suffix && (
          <div className="absolute top-[50%] right-2 transform -translate-y-1/2 text-gray-600">
            {suffix}
          </div>
        )}
        {register === undefined ||
          (register && handleClearFormFields && (
            <div
              className={clsx(
                "absolute top-[50%] right-2 transform -translate-y-1/2",
                hasSomeValueTyped ? "cursor-pointer" : ""
              )}
              onClick={() => {
                if (hasSomeValueTyped) {
                  handleClearFormFields?.()
                  setTextedValue("")
                  onType?.("")
                }
              }}
            >
              <RightIcon
                size={24}
                className={clsx("text-gray-600", {
                  "opacity-0": !hasSomeValueTyped && rightIconIsX,
                })}
              />
            </div>
          ))}
      </div>
      {createEspaceToLabel && !error && !helperText && <div className="h-4" />}
      {error || helperText ? (
        <p
          className={clsx("w-full h-4 text-wrap text-xs font-semibold mt-1", {
            "text-red-500": error,
            "text-neutral-300": !error,
          })}
        >
          {error || helperText}
        </p>
      ) : null}
    </div>
  )
}

export default Input
