import InputWithLabel from "@components/Inputs/InputWithLabel"
import Logo from "@assets/Logo"
import React, { useState } from "react"
import { forgotPassword } from "@services/SignIn/forgotPassword"
import { publicRoutes } from "@constants/Routes"
import { SimpleButton } from "@components/Buttons/SimpleButton"
import { useMutation } from "@tanstack/react-query"
import { useNavigate } from "react-router-dom"

const ForgotPassword: React.FC = ({}) => {
  const navigate = useNavigate()

  const [forgotPasswordData, setForgotPasswordData] = useState({
    email: "",
  })

  const handleChangeUserData = (field: string, value: string) => {
    setForgotPasswordData({
      ...forgotPasswordData,
      [field]: value,
    })
  }

  const onSuccess = () => {
    navigate(publicRoutes.signIn)
  }

  const { mutate: forgotPassordMutation, isPending } = useMutation({
    mutationFn: forgotPassword.fn,
    mutationKey: [forgotPassword.key],
    onSuccess,
    onError: () => {},
  })

  const hasError =
    forgotPasswordData.email === "" ||
    !new RegExp(/^[^\s@]+@[^\s@]+\.[^\s@]+$/).test(forgotPasswordData.email)

  return (
    <div className="bg-secondary-100 w-screen h-screen flex flex-col items-center justify-center">
      <div className="w-11/12 lg:max-w-xl bg-white rounded-3xl flex justify-start items-center flex-col relative py-8">
        <div className="mb-8">
          <Logo size="xsmall" />
        </div>
        <div
          className="absolute top-8 left-8 cursor-pointer"
          onClick={() => navigate(publicRoutes.signIn)}
        >
          <svg
            width="20"
            height="16"
            viewBox="0 0 20 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M18.25 8H1.75"
              stroke="#6E6D6D"
              strokeWidth="2"
              strokeLinecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M8.5 1.25L1.75 8L8.5 14.75"
              stroke="#6E6D6D"
              strokeWidth="2"
              strokeLinecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <div className="text-xl lg:text-2xl text-primary-500 mb-4">
          Esqueceu sua senha?
        </div>
        <div className="text-base lg:text-xl text-neutral-200 mb-6">
          Não se preocupe! Acontece :D
        </div>
        <div className="w-10/12 text-base text-center text-primary-500 mb-8">
          Digite seu e-mail no campo abaixo. E enviaremos um link para que você
          possa redefinir sua senha.
        </div>
        <div className="w-10/12 lg:w-7/12 flex flex-col items-center justify-center mb-6">
          <InputWithLabel
            placeholder="Email"
            label="Insira seu E-mail"
            size="full"
            onType={(value) => handleChangeUserData("email", value)}
          />
        </div>
        <SimpleButton
          label="Confirmar"
          onClick={() => forgotPassordMutation(forgotPasswordData)}
          disabled={hasError}
          className="w-10/12 mt-8"
          isLoading={isPending}
        />
      </div>
    </div>
  )
}

export default ForgotPassword
