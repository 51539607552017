import React from "react"

interface ICard {
  children: React.ReactNode
  className?: string
}

// eslint-disable-next-line no-empty-pattern
const Card: React.FC<ICard> = ({
  children,
  className = "w-full min-h-[calc(100%-4rem)]",
}) => {
  return (
    <div
      className={`flex flex-col justify-start bg-white px-4 py-4 rounded-xl ${className}`}
    >
      {children}
    </div>
  )
}

export default Card
