import { ICartItem } from "@interfaces/Components/Ecommerce"
import { formatNumberToMoney } from "@utils/money"
import clsx from "clsx"
import { CaretLeft, TrashSimple } from "phosphor-react"
import ReactDrawer from "react-modern-drawer"
import "react-modern-drawer/dist/index.css"
import CartItem from "./CartItem"
import FinishOrder from "./FinishOrder"
import { SimpleButton } from "@components/Buttons/SimpleButton"
import { IProduct, IVariant } from "@interfaces/Services"
import { IEcommerceSettings } from "@interfaces/Pages/Ecommerce"

export const calculatePriceWithSale = (
  price: number,
  quantity: number,
  sales: {
    from: number
    price: number
  }[]
) => {
  if (!sales || sales.length === 0) {
    return {
      lastPrice: 0,
      price,
    }
  }

  const applicableSales = sales.filter((sale) => quantity >= sale.from)
  if (applicableSales.length === 0) {
    return {
      lastPrice: 0,
      price,
    }
  }

  const bestSale = applicableSales.reduce((prev, current) =>
    current.from > prev.from ? current : prev
  )

  return {
    lastPrice: price,
    price: bestSale.price,
  }
}

const CartDrawer: React.FC<{
  drawerIsOpen: boolean
  storagedCart: {
    id: string
    quantity: number
    name: string
  }[]
  allVariants: IVariant[]
  register: any
  control: any
  errors: any
  isValid: boolean
  isPending: boolean
  isFinishingOrder: boolean
  setDrawerIsOpen: (value: boolean) => void
  handleUpdateQuantity: (id: string, quantity: number) => void
  setIsFinishingOrder: (value: boolean) => void
  handleRemoveCart: (id: string) => void
  handleSubmit: () => void
  handleClearCart: () => void
  ecommerceSettings: IEcommerceSettings
}> = ({
  errors,
  isValid,
  register,
  control,
  drawerIsOpen,
  storagedCart,
  allVariants,
  isFinishingOrder,
  isPending,
  handleSubmit,
  handleClearCart,
  setDrawerIsOpen,
  handleRemoveCart,
  handleUpdateQuantity,
  setIsFinishingOrder,
  ecommerceSettings,
}) => {
  const total = storagedCart.reduce((acc, item) => {
    const variant = allVariants?.find((variant) => variant.id === item.id)
    return (
      acc +
      calculatePriceWithSale(
        variant?.sale_price || variant?.price,
        item?.quantity,
        variant?.sales || []
      )?.price *
        item?.quantity
    )
  }, 0)

  const findCartItem = (cartItem: {
    id: string
    quantity: number
    name: string
  }): ICartItem => {
    const variant = allVariants?.find((variant) => variant.id === cartItem.id)

    return {
      id: variant?.id,
      name: cartItem.name,
      quantity: cartItem.quantity,
      price: variant?.price,
      image_url: variant?.image_url,
      sale_price: variant?.sale_price,
      sales: variant?.sales,
    }
  }

  return (
    <>
      <ReactDrawer
        open={drawerIsOpen}
        onClose={() => {
          setDrawerIsOpen(false)
          setIsFinishingOrder(false)
        }}
        direction="right"
        className="react-modern-drawer"
      >
        <div
          className={clsx(
            "w-full h-full flex flex-col transition-all duration-300 ease-in-out"
          )}
        >
          <div className="w-full h-[12%] flex justify-between items-center p-2">
            <div className="flex items-center gap-2">
              <button
                onClick={() => {
                  setDrawerIsOpen(false)
                  setIsFinishingOrder(false)
                }}
              >
                <CaretLeft
                  size={26}
                  weight="bold"
                  className="text-primary-500"
                />
              </button>
              <p className="text-xl font-semibold text-primary-500">
                Carrinho de Compras
              </p>
            </div>
            <button
              className="text-secondary-500 p-2 px-3 border border-secondary-400 rounded-md flex gap-2 text-base font-medium disabled:opacity-50 pop"
              onClick={handleClearCart}
              disabled={storagedCart.length === 0}
            >
              Limpar
              <TrashSimple
                size={24}
                className="text-secondary-500"
                weight="fill"
              />
            </button>
          </div>
          <div className="w-full h-[1px] bg-gray-300" />
          <div
            className={clsx(
              "p-4 flex flex-col gap-4 overflow-y-auto transition-all duration-300 ease-in-out",
              isFinishingOrder ? "h-[38%] lg:h-[47%]" : "h-[70%] lg:h-[80%]"
            )}
          >
            {storagedCart.length === 0 ? (
              <p className="text-center text-lg text-gray-500 mt-2">
                Seu carrinho está vazio.
              </p>
            ) : (
              storagedCart.map((item) => (
                <CartItem
                  key={item.id}
                  item={findCartItem(item)}
                  handleUpdateQuantity={handleUpdateQuantity}
                  handleRemoveCart={handleRemoveCart}
                />
              ))
            )}
          </div>
          <div className="w-full h-[1px] bg-gray-300" />
          <div className={clsx("p-4 h-[20%] lg:h-fit")}>
            <div className="flex flex-col items-center text-lg">
              <div className="w-full flex justify-between mb-6">
                <span className="font-semibold text-xl">Total</span>
                <span>{formatNumberToMoney(total)}</span>
              </div>
              <SimpleButton
                label="Finalizar pedido"
                disabled={storagedCart.length === 0}
                onClick={() => setIsFinishingOrder(true)}
                className="w-full"
              />
            </div>
          </div>
        </div>
      </ReactDrawer>
      <ReactDrawer
        open={isFinishingOrder}
        onClose={() => setIsFinishingOrder(false)}
        direction="bottom"
        className="react-modern-drawer-bottom"
        enableOverlay={false}
      >
        <FinishOrder
          total={total}
          register={register}
          control={control}
          errors={errors}
          isValid={isValid}
          isPending={isPending}
          setIsFinishingOrder={setIsFinishingOrder}
          isFinishingOrder={isFinishingOrder}
          onFinishingOrder={handleSubmit}
          ecommerceSettings={ecommerceSettings}
        />
      </ReactDrawer>
    </>
  )
}

export default CartDrawer
