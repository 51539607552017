import React, { useState, useEffect } from "react"
import { SimpleButton } from "../Buttons/SimpleButton"
import { plansIds, plansKeys } from "@constants/Plans"
import Price from "./Price"
import Benefits from "./Benefits"

interface IPremiumPlan {
  selectedPlan: string
  isOpen: boolean
  onlyView?: boolean
  isFirst7Days: boolean
  onSelectedPlan: (plan: any) => Promise<void>
  onCancel: (setIsLoading: any) => void
}

const PremiumPlan: React.FC<IPremiumPlan> = ({
  selectedPlan,
  isOpen,
  onlyView,
  isFirst7Days,
  onSelectedPlan,
  onCancel,
}) => {
  const [confirmChange, setConfirmChange] = useState(false)
  const [confirmCancel, setConfirmCancel] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const isUpgrading = selectedPlan === plansKeys.basic

  useEffect(() => {
    if (!isOpen) {
      setTimeout(() => {
        setConfirmChange(false)
        setConfirmCancel(false)
      }, 300)
    }
  }, [isOpen])

  const handleSelectedPlan = async () => {
    setIsLoading(true)
    await onSelectedPlan({
      id: plansIds.premium,
    })
    selectedPlan !== plansKeys.free && setIsLoading(false)
  }

  const handleCancel = () => {
    if (confirmCancel) {
      setIsLoading(true)
      onCancel(setIsLoading)
    } else {
      setConfirmCancel(true)
    }
  }

  const renderConfirmationMessage = () => {
    if (confirmCancel) {
      return (
        <p className="text-base font-medium text-secondary-500">
          Deseja realmente cancelar o plano Premium?{" "}
          {isFirst7Days
            ? "Você receberá um reembolso integral e perderá todos os benefícios."
            : "Você poderá continuar utilizando os benefícios até o final do ciclo de faturamento."}
        </p>
      )
    }

    if (confirmChange) {
      return (
        <p className="text-base font-medium text-secondary-500">
          Deseja realmente fazer o upgrade para o plano Premium?{" "}
          {isUpgrading
            ? "Você será cobrado apenas no proximo ciclo de faturamento."
            : ""}
        </p>
      )
    }

    return null
  }

  const canNotAssign =
    selectedPlan === plansKeys.premium ||
    (selectedPlan === plansKeys.basic && isFirst7Days)

  return (
    <div className="flex flex-col p-6 mx-auto xs:min-w-96 md:max-w-96 lg:w-full text-center text-gray-900 bg-white rounded-lg border border-gray-300 shadow-lg relative">
      <div className="flex flex-col gap-2 justify-center items-center">
        {selectedPlan === plansKeys.premium && (
          <div className="w-fit px-4 py-1 bg-primary-500 text-white rounded-full text-sm absolute -top-4">
            Plano atual
          </div>
        )}
        <h3 className="text-2xl font-semibold">Premium</h3>
        <p className="font-light text-gray-500 sm:text-lg">
          Pra quem quer mais
        </p>
      </div>
      <Price price={99.9} />
      <Benefits
        benefits={[
          "Tudo do plano Básico",
          "Suporte prioritário",
          "Personalização de layout",
          "Integração com WhatsApp",
          "Acesso antecipado a novos recursos",
        ]}
      />
      {!onlyView && (
        <div className="mt-auto w-full flex justify-center flex-col gap-4">
          {renderConfirmationMessage()}
          {!canNotAssign && (
            <div className="flex flex-col gap-2">
              <SimpleButton
                label={
                  isLoading
                    ? "Aguarde..."
                    : confirmChange
                      ? "Confirmar assinatura"
                      : "Assinar Premium"
                }
                size="full"
                onClick={
                  confirmChange
                    ? handleSelectedPlan
                    : selectedPlan === plansKeys.free
                      ? handleSelectedPlan
                      : () => setConfirmChange(true)
                }
                isLoading={isLoading}
              />
              {confirmChange && (
                <SimpleButton
                  label="Desistir da mudança"
                  size="full"
                  className="!bg-red-400 hover:!bg-red-500"
                  onClick={() => setConfirmChange(false)}
                  isLoading={isLoading}
                />
              )}
            </div>
          )}
          {plansKeys.premium === selectedPlan && (
            <>
              <SimpleButton
                label="Cancelar plano"
                size="full"
                onClick={handleCancel}
                disabled={confirmChange}
                isLoading={isLoading}
                className="!bg-gray-500 !hover:bg-gray-600"
              />
              {confirmCancel && (
                <SimpleButton
                  label="Desistir do cancelamento"
                  size="full"
                  className="!bg-red-500 !containerhover:bg-red-600"
                  onClick={() => setConfirmCancel(false)}
                  isLoading={isLoading}
                />
              )}
            </>
          )}
        </div>
      )}
    </div>
  )
}

export default PremiumPlan
