import Card from "@components/Card"
import OrderDetailCard from "@components/OrderDetailCard"
import React from "react"
import Skeleton from "react-loading-skeleton"
import Table from "@components/Table/Table"
import UnderlineTitle from "@components/UnderlineTitle"
import { formatOrderDetailsData } from "@utils/formatters"
import { Masks } from "@constants/Masks"
import { orderDetailsHeadersList } from "@interfaces/Constants/Pages"
import { ordersService } from "@services/Orders"
import { SimpleButton } from "@components/Buttons/SimpleButton"
import { useMutation, useQuery } from "@tanstack/react-query"
import { useParams } from "react-router-dom"
import { toast } from "sonner"
import useStore from "@store"

const OrderDetail: React.FC = ({}) => {
  const { userDetail } = useStore()
  const { orderId } = useParams()

  const userPaymentMethods = userDetail?.settings?.payment_methods

  const { data: orderData } = useQuery({
    queryKey: [ordersService.getOrder.key, orderId],
    queryFn: () => ordersService.getOrder.fn(orderId),
  })

  function downloadFile(data: any, fileName: string, type: string) {
    const blob = new Blob([data], { type })
    const url = window.URL.createObjectURL(blob)
    const a = document.createElement("a")
    a.href = url
    a.download = fileName
    a.click()
    window.URL.revokeObjectURL(url)
  }

  const orderDate = orderData?.created_at
    ? new Date(orderData?.created_at).toLocaleString("pt-BR")
    : undefined
  const orderPrice = orderData?.total
    ? new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      }).format(orderData?.total)
    : undefined

  const { mutate: downloadOrder, isPending: downloadOrderIsPending } =
    useMutation({
      mutationFn: ordersService.downloadOrder.fn,
      onSuccess: (data) => {
        downloadFile(
          data,
          `pedido-${orderData.cnpj}-${orderDate?.split(", ").join("_")}.pdf`,
          "application/pdf"
        )
      },
      onError: (error) => {
        toast.error("Erro ao baixar pedido: " + error.message)
      },
    })

  const orderPaymentMethod = userPaymentMethods?.find(
    (method) => method.name === orderData?.payment_method
  )

  return (
    <div className="w-full flex flex-col">
      <div className="flex justify-between items-center w-full">
        <UnderlineTitle breadcrumb={[{ title: "Detalhes do Pedido" }]} />
      </div>
      <div className="w-full grid grid-cols-1 gap-4 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-6 mb-4">
        <OrderDetailCard icon="User" title="Cliente" info={orderData?.name} />
        <OrderDetailCard
          icon="IdentificationCard"
          title="CNPJ"
          info={Masks.cnpj(orderData?.cnpj)}
        />
        <OrderDetailCard icon="At" title="Email" info={orderData?.email} />
        <OrderDetailCard
          icon="Calendar"
          title="Data do pedido"
          info={orderDate}
        />
        <OrderDetailCard
          icon="Cardholder"
          title="Forma de pagamento"
          info={orderPaymentMethod?.title}
        />
        <OrderDetailCard icon="Money" title="Valor total" info={orderPrice} />
      </div>
      {orderData?.products?.length > 0 ? (
        <Card className="!min-h-fit">
          <div className="flex justify-end w-full h-fit mb-8 gap-4">
            <SimpleButton
              label="Baixar pedido"
              onClick={() => downloadOrder(orderId)}
              isLoading={downloadOrderIsPending}
            />
          </div>
          <Table
            headers={orderDetailsHeadersList}
            data={formatOrderDetailsData(orderData?.products)}
          />
        </Card>
      ) : (
        <Skeleton width="100%" height={400} />
      )}
    </div>
  )
}

export default OrderDetail
