import Card from "@components/Card"
import clsx from "clsx"
import InputWithLabel from "@components/Inputs/InputWithLabel"
import React from "react"
import { IInformations } from "@interfaces/Pages/Products"
import { useFormContext } from "react-hook-form"
import Select from "@components/Inputs/Select"

const Informations: React.FC<IInformations> = ({
  fields,
  hasActionInProgress,
}) => {
  const {
    register,
    formState: { errors },
    control,
  } = useFormContext()

  return (
    <Card className="w-full">
      <div className="mb-6 w-full">
        <div className="w-full flex justify-start items-center gap-4">
          <p className="text-lg font-semibold text-primary-500">Informações</p>
          {fields.some((field) => field.is_required) && (
            <p className="text-xs text-red-500 mt-1">* Campos obrigatórios</p>
          )}
        </div>
        <p className="text-base text-neutral-500">
          Adicione as informações do seu produto
        </p>
      </div>

      <div className="w-full grid grid-cols-1 lg:grid-cols-2 lg:gap-x-4">
        {fields.map((field, index) => {
          if (field.input_type === "select") {
            return (
              <Select
                key={field.key}
                label={field.label}
                placeholder={field.label}
                options={field.options as any}
                onSelectOption={() => {}}
                isDisabled={hasActionInProgress}
                info={field.info}
                className={clsx("w-full mb-2 lg:mb-0", {
                  "col-span-1 lg:col-span-2":
                    index === fields.length - 1 && fields.length % 2 !== 0,
                })}
                register={register(field.key)}
                isRequired={field.is_required}
                control={control}
              />
            )
          }

          return (
            <InputWithLabel
              key={field.key}
              label={field.label}
              placeholder={field.label}
              size="full"
              info={field.info}
              isRequired={field.is_required}
              register={register(field.key)}
              error={errors[field.key]?.message as string}
              masks={field.masks as any}
              className={clsx({
                "col-span-1 lg:col-span-2":
                  index === fields.length - 1 && fields.length % 2 !== 0,
              })}
              isDisabled={hasActionInProgress}
              maxLength={field.max_length}
            />
          )
        })}
      </div>
    </Card>
  )
}

export default Informations
