import { IProduct } from "@interfaces/Services"
import { Card } from "@components/Ecommerce/Card"
import { ICartItem } from "@interfaces/Components/Ecommerce"

interface ProductGridProps {
  products: IProduct[]
  storagedCart: ICartItem[]
  handleOpenDrawer: () => void
  handleAddCart: (item: ICartItem, quantity?: number) => void
  handleRemoveCart: (id: string) => void
}

const ProductGrid: React.FC<ProductGridProps> = ({
  products,
  storagedCart,
  handleOpenDrawer,
  handleAddCart,
  handleRemoveCart,
}) => {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 gap-5">
      {products.map((item) => (
        <Card
          key={item.id}
          product={item}
          isOnCart={storagedCart.some((cartItem) => cartItem.id === item.id)}
          onAddCart={handleAddCart}
        />
      ))}
    </div>
  )
}

export default ProductGrid
