import * as yup from "yup"
import Card from "@components/Card"
import GenericModal from "@components/GenericModal"
import InputWithLabel from "@components/Inputs/InputWithLabel"
import PlanPrices from "@components/PlanPrices"
import React, { useState } from "react"
import useStore from "@store"
import { Masks } from "@constants/Masks"
import { plansTranslation } from "@utils/plans"
import { plansKeys } from "@constants/Plans"
import { SimpleButton } from "@components/Buttons/SimpleButton"
import { subscriptionsService } from "@services/Subscriptions"
import { toast } from "sonner"
import { updateSession } from "@utils/updateSession"
import { useForm } from "react-hook-form"
import { useMutation } from "@tanstack/react-query"
import { userServices } from "@services/User"
import { yupResolver } from "@hookform/resolvers/yup"
import { useFlag } from "@utils/featureFlags"
import { flagsKey } from "@constants/Flags"
import { Plan } from "@interfaces/Components"

const ProfileInfo: React.FC = ({}) => {
  const { userDetail, setUserDetail, paiedFeatures } = useStore()

  const [myPlanIsOpen, setMyPlanIsOpen] = useState(false)

  const isReleasedForThisUser = paiedFeatures?.change_slug as boolean
  const enableFlagPlan = useFlag(flagsKey.subscriptions).isActive

  const schema = yup.object().shape({
    name: yup.string().required("Nome é obrigatório"),
    slug: yup.string().required("Slug é obrigatório"),
    phone: yup.string().test("phone", "Telefone inválido", (value) => {
      if (!value) return true
      return Masks.phone(value || "").length === 15
    }),
  })

  const methods = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    defaultValues: {
      name: userDetail?.full_name || "",
      slug: userDetail?.slug || "",
      phone: Masks.phone(userDetail?.phone_number || ""),
    },
  })

  const {
    handleSubmit,
    register,
    formState: { errors, isValid },
    setValue,
    clearErrors,
    watch,
  } = methods

  const disableSubmit = !isValid

  const { mutate: updateUser, isPending } = useMutation({
    mutationFn: userServices.updateMySelf.fn,
    onSuccess() {
      updateSession({
        onSaveUserDetail: (data) => {
          setUserDetail(data)
          setValue("name", data?.full_name || "")
          setValue("phone", Masks.phone(data?.phone_number || ""))
          setValue("slug", data?.slug || "")
          clearErrors("slug")
        },
      })
      toast.success("Usuário atualizado com sucesso")
    },
    onError(error) {
      toast.error(error.message)
    },
  })

  const hasSomeDiff =
    userDetail?.full_name !== watch("name") ||
    userDetail?.phone_number !== watch("phone") ||
    userDetail?.slug !== watch("slug")

  const userPlan = userDetail?.plan

  const handleSelectPlan = async (plan: Plan) => {
    const alreadyHasSomePlan = userDetail?.subscription_id

    if (alreadyHasSomePlan) {
      const requestChangePlan = subscriptionsService.updateSubscription.fn({
        subscriptionId: userDetail?.subscription_id as string,
        newPlanId: plan.id,
      })

      toast.promise(requestChangePlan, {
        loading: "Carregando...",
        finally() {
          setMyPlanIsOpen(false)
        },
        success: () => {
          setUserDetail({
            ...userDetail,
            plan: plan.key,
          })
          return "Plano alterado com sucesso"
        },
        error: (error) => {
          return error.message
        },
      })
    } else {
      const requestCheckoutUrl = subscriptionsService.createCheckoutSession.fn({
        planId: plan.id,
        getwayId: userDetail?.gateway_customer_id as string,
      })

      toast.promise(requestCheckoutUrl, {
        loading: "Carregando...",
        finally() {
          setMyPlanIsOpen(false)
        },
        success: (data) => {
          window.location.href = data.checkout_url
          return `Redirecionando para o checkout...`
        },
        error: (error) => {
          return error.message
        },
      })
    }
  }

  const handleCancelPlan = async (setIsLoading: any) => {
    const requestCancelPlan = subscriptionsService.cancelSubscription.fn(
      userDetail?.subscription_id as string
    )

    toast.promise(requestCancelPlan, {
      loading: "Carregando...",
      finally() {
        setIsLoading(false)
        setMyPlanIsOpen(false)
      },
      success: () => {
        setUserDetail({
          ...userDetail,
          subscription_id: null,
          plan: plansKeys.free,
        })
        return "Plano cancelado com sucesso"
      },
      error: (error) => {
        return error.message
      },
    })
  }

  return (
    <Card className="w-full lg:max-w-xl">
      <GenericModal
        isOpen={myPlanIsOpen}
        onClose={() => setMyPlanIsOpen(false)}
        className="w-11/12 md:10/12 lg:w-fit min-w-[70%]"
        title="Meu Plano"
      >
        <PlanPrices
          selectedPlan={userPlan}
          onSelectedPlan={handleSelectPlan}
          onCancel={handleCancelPlan}
          isOpen={myPlanIsOpen}
        />
      </GenericModal>
      <div className="flex justify-between items-center mb-4">
        <p className="text-lg font-semibold text-primary-500">Informações</p>

        {userPlan && enableFlagPlan && (
          <SimpleButton
            label="Meu Plano"
            onClick={() => {
              setMyPlanIsOpen(true)
            }}
          />
        )}
      </div>
      <InputWithLabel
        label="Nome"
        size="full"
        placeholder="Nome"
        error={errors?.name?.message}
        masks="onlyLetters"
        register={register("name", {
          onChange: (e) => {
            setValue("name", e.target.value)
            if (isReleasedForThisUser) {
              setValue("slug", e.target.value.toLowerCase().replace(/ /g, "-"))
            }
            clearErrors("slug")
          },
        })}
      />
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-1 gap-x-4">
        <InputWithLabel
          label="Slug"
          size="full"
          placeholder="Slug"
          error={errors?.slug?.message}
          masks="slug"
          register={register("slug")}
          isDisabled={!isReleasedForThisUser}
          info={`Slug é a nome que será utilizada para acessar o seu perfil. ${
            !isReleasedForThisUser
              ? "Para alterar o slug, você precisa estar em um plano superior."
              : ""
          }`}
        />
        <InputWithLabel
          label="Telefone"
          size="full"
          placeholder="Telefone"
          error={errors?.phone?.message}
          masks="phone"
          register={register("phone")}
        />
      </div>
      <div className="flex justify-end">
        <SimpleButton
          label="Salvar"
          className="mt-4"
          disabled={disableSubmit || !hasSomeDiff}
          isLoading={isPending}
          onClick={handleSubmit((data) => {
            updateUser({
              full_name: data.name.trim(),
              slug: data.slug.replace(/-$/, ""),
              phone_number: data.phone,
              segment_id: userDetail?.segment_id,
            })
          })}
        />
      </div>
    </Card>
  )
}

export default ProfileInfo
