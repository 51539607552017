export const requiredFieldMessage = "Campo obrigatório"

export const OrderStatus: {
  [key: string]: {
    key: string
    value: string
    type: string
  }
} = {
  approved: {
    key: "approved",
    value: "Aprovado",
    type: "success",
  },
  canceled: {
    key: "canceled",
    value: "Cancelado",
    type: "danger",
  },
  pending: {
    key: "pending",
    value: "Pendente",
    type: "warning",
  },
}
