import UnderlineTitle from "@components/UnderlineTitle"
import useStore from "@store"
import React, { useEffect } from "react"
import { useSearchParams } from "react-router-dom"
import { toast } from "sonner"

const Dashboard: React.FC = ({}) => {
  const { userDetail } = useStore()

  // Plan query params
  const [params] = useSearchParams()
  const newPlan = params.get("plan")

  useEffect(() => {
    if (newPlan) {
      toast.success(
        `Parabéns! Você assinou o plano ${newPlan}, aproveite todos os benefícios!`
      )

      params.delete("plan")
      window.history.replaceState(
        {},
        "",
        `${window.location.pathname}?${params.toString()}`
      )
    }
  }, [newPlan])

  return (
    <>
      <div className="flex justify-between items-center w-full">
        <UnderlineTitle />
      </div>
      <div className="flex flex-col">
        <h1 className="text-xl font-bold text-gray-800">Seja bem vindo,</h1>
        <h2>{userDetail?.full_name}</h2>
      </div>
    </>
  )
}

export default Dashboard
