import clsx from "clsx"
import Popup from "reactjs-popup"
import React, { useState } from "react"
import Tooltip from "@components/Tooltip"
import ReactDOM from "react-dom"
import { formatNumberToMoney } from "@utils/money"
import { IProduct, IVariant } from "@interfaces/Services"
import { RocketLaunch, ShoppingCart, X, XCircle } from "phosphor-react"
import Carousel from "@components/Caroussel"
import { SimpleButton } from "@components/Buttons/SimpleButton"
import GenericModal from "@components/GenericModal"
import ProductDetailsModal from "./ProductDetailsModal"
import { ICartItem } from "@interfaces/Components/Ecommerce"

interface ICard {
  isOnCart?: boolean
  product: IProduct
  onAddCart?: (item: ICartItem, quantity?: number) => void
}

export const Card: React.FC<ICard> = ({ isOnCart, product, onAddCart }) => {
  const {
    is_release: product_is_release,
    name: product_name,
    variants: product_variants,
  } = product

  const [openItemDetails, setOpenItemDetails] = useState(false)

  const variantsOrderByPrice = product_variants?.sort((a, b) => {
    return a.price - b.price
  })

  const variantWithMinorPrice = variantsOrderByPrice?.reduce((acc, variant) => {
    if (
      (variant.sale_price < acc.sale_price && variant.sale_price > 0) ||
      variant.price < acc.price
    ) {
      return variant
    }

    return acc
  })

  const percentageDiscount = variantWithMinorPrice.sale_price
    ? Math.floor(
        ((variantWithMinorPrice?.price - variantWithMinorPrice?.sale_price) /
          variantWithMinorPrice?.price) *
          100
      )
    : 0

  const [selectedVariant, setSelectedVariant] = useState<IVariant>(
    variantWithMinorPrice
  )

  return (
    <div className="relative group">
      <ProductDetailsModal
        isOpen={openItemDetails}
        onClose={() => setOpenItemDetails(false)}
        product={product}
        defaultSelectedVariant={selectedVariant}
        onAddCart={onAddCart}
      />

      {product_is_release && (
        <div
          className={clsx(
            "bg-secondary-400 text-white rounded text-xs flex justify-center items-center px-2 py-1 absolute top-2 left-2 z-10 group-hover:scale-105 transition-all duration-150"
          )}
        >
          Lançamento
        </div>
      )}
      {percentageDiscount > 0 && (
        <div
          className={clsx(
            "bg-green-500 text-white rounded text-xs flex justify-center items-center px-2 py-1 absolute top-2 right-2 z-10 group-hover:scale-105 transition-all duration-150"
          )}
        >
          {percentageDiscount}% OFF
        </div>
      )}
      <div
        className={clsx(
          "overflow-hidden relative bg-white border shadow-lg flex flex-col items-center p-2 rounded hover:shadow-xl lg:hover:scale-105 transition-all ease-linear group duration-300",
          isOnCart ? "border border-secondary-100" : "border border-gray-200"
        )}
      >
        <div className="w-full h-52 mt-4  relative">
          <Carousel images={[variantWithMinorPrice?.image_url]} />
        </div>

        <div className="w-full h-32 xl:h-20 xl:group-hover:h-32 transition-all duration-300 flex flex-col border-t border-slate-200 rounded-bl-md rounded-br-md p-2 gap-2 ">
          <strong className="w-full font-light text-gray-700 text-lg">
            {product_name.length > 20 ? (
              <span className="block truncate">{product_name}</span>
            ) : (
              <>{product_name}</>
            )}
          </strong>

          <div className="flex items-center text-xl font-semibold w-full gap-2">
            <p
              className={clsx(
                "",
                variantWithMinorPrice?.sale_price
                  ? "line-through text-gray-400"
                  : "text-primary-500s"
              )}
            >
              {formatNumberToMoney(variantWithMinorPrice?.price)}
            </p>

            {variantWithMinorPrice?.sale_price !== 0 && (
              <p className="text-secondary-500">
                {formatNumberToMoney(variantWithMinorPrice?.sale_price)}
              </p>
            )}
          </div>
          <SimpleButton
            label="Selecionar produto"
            onClick={(e) => setOpenItemDetails(true)}
            className="hidden xl:flex xl:absolute -left-0 -bottom-[66px] transition-all duration-300 group-hover:-bottom-3 group-hover:left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10"
            icon="ShoppingBagOpen"
          />
          <SimpleButton
            label="Selecionar produto"
            onClick={(e) => setOpenItemDetails(true)}
            className="w-full mt-1 xl:hidden"
            icon="ShoppingBagOpen"
          />
        </div>
      </div>
    </div>
  )
}
