import Card from "@components/Card"
import ConfirmActionModal from "@components/Modals/ConfirmAction"
import InputWithLabel from "@components/Inputs/InputWithLabel"
import Pagination from "@components/Pagination"
import React, { useEffect, useState } from "react"
import Select from "@components/Inputs/Select"
import Table from "@components/Table/Table"
import TableFilters from "@components/Table/TableFilters"
import UnderlineTitle from "@components/UnderlineTitle"
import useStore from "@store"
import { formatProductsData } from "@utils/formatters"
import { privateRoutes } from "@constants/Routes"
import { productsService } from "@services/Products"
import { segmentsService } from "@services/Segments"
import { SimpleButton } from "@components/Buttons/SimpleButton"
import { toast } from "sonner"
import { useMutation, useQuery } from "@tanstack/react-query"
import { useNavigate } from "react-router-dom"
import {
  headersBySegment,
  // tableVisibleColumnsBySegment,
} from "@constants/Products"

const Products: React.FC = ({}) => {
  const navigate = useNavigate()
  const { userDetail } = useStore()

  const [pageFilters, setPageFilters] = useState({
    actualPage: 1,
    itemsPerPage: 15,
  })

  const userSegment = userDetail?.segment_key || ""

  const [filters, setFilters] = useState<{
    [key: string]: string
  }>({
    name: "",
    isAvailable: "",
    isRelease: "",
  })

  const [customFields, setCustomFields] = useState<any>({})

  const [lastQuantityOfTotalItems, setLastQuantityOfTotalItems] = useState(0)
  const [deleteProductId, setDeleteProductId] = useState("")

  // const [visibleColumns, setVisibleColumns] = useState(
  //   tableVisibleColumnsBySegment.drugs
  // )

  const { data: filtersData } = useQuery({
    queryKey: [segmentsService.getFilters.key, userDetail?.segment_id],
    queryFn: () => segmentsService.getFilters.fn(userDetail?.segment_id),
  })

  const {
    data: listProductsData,
    isFetching: isListProductsPending,
    refetch: refetchListProducts,
  } = useQuery({
    queryKey: [
      productsService.listProducts.key,
      filters,
      pageFilters,
      customFields,
    ],
    queryFn: () =>
      productsService.listProducts.fn({
        page: pageFilters.actualPage,
        ...(filters as any),
        customFields,
      }),
  })

  const { mutate: deleteProduct, isPending: isDeletePending } = useMutation({
    mutationFn: productsService.deleteProduct.fn,
    onSuccess: () => {
      toast.success("Produto deletado com sucesso!")
      setPageFilters({ ...pageFilters, actualPage: 1 })
      setDeleteProductId("")
      refetchListProducts()
    },
    onError: (error) => {
      toast.error("Erro ao deletar produto: " + error.message)
    },
  })

  // const { mutate: updateProduct, isPending: isUpdatePending } = useMutation({
  //   mutationFn: productsService.updateProduct.fn,
  //   onSuccess: () => {
  //     toast.success("Produto atualizado com sucesso!")
  //     refetchListProducts()
  //   },
  //   onError: (error) => {
  //     toast.error("Erro ao atualizar produto: " + error.message)
  //   },
  // })

  useEffect(() => {
    if (
      listProductsData?.total &&
      listProductsData?.total !== lastQuantityOfTotalItems
    ) {
      setLastQuantityOfTotalItems(listProductsData?.total)
    }
  }, [listProductsData])

  const handleChangePage = (page: number) => {
    setPageFilters({ ...pageFilters, actualPage: page })
  }

  const handleChangeFilter = (filtersToChange: any) => {
    setFilters({ ...filters, ...filtersToChange })
  }

  const handleChangeName = (text: string) => {
    handleChangeFilter({
      name: text,
    })
  }

  const allFilters = {
    ...filters,
    ...customFields,
  }

  const howSomeFilterActive = Object.values(allFilters)?.filter(
    (filter) => filter !== ""
  )

  const dontHasProducts =
    !howSomeFilterActive.some((filter) => filter !== "") &&
    (!listProductsData?.data || listProductsData?.data.length === 0)

  const hasProdutToDelete = deleteProductId !== ""

  const customEmptyMessage = dontHasProducts ? (
    <div className="text-center flex flex-col gap-2">
      <p>Nenhum produto encontrado.</p>
      <p>Você pode adicionar um novo produto clicando no botão abaixo.</p>
    </div>
  ) : undefined

  const handleSelectAvailable = (selected: {
    value: string
    label: string
  }) => {
    handleChangeFilter({
      isAvailable: selected.value,
    })
  }

  const handleSelectRelease = (selected: { value: string; label: string }) => {
    handleChangeFilter({
      isRelease: selected.value,
    })
  }

  // const handleSelectType = (selected: { value: string; label: string }) => {
  //   handleChangeFilter({
  //     type: selected.value,
  //   })
  // }

  // const tableListFilteredBySegment = headersBySegment?.[userSegment]?.filter?.(
  //   (header) => visibleColumns?.includes(header.key)
  // )

  const handleEditProduct = (productId: string) => {
    navigate(`${productId}`)
  }

  const availableOptions = [
    { value: "true", label: "Sim" },
    { value: "false", label: "Não" },
  ]

  const releaseOptions = availableOptions

  return (
    <>
      {hasProdutToDelete && (
        <ConfirmActionModal
          isOpen={hasProdutToDelete}
          title="Deletar produto"
          message="Tem certeza que deseja deletar esse produto?"
          onConfirm={() => {
            deleteProduct(deleteProductId)
          }}
          onCancel={() => {
            setDeleteProductId("")
          }}
          icon="Package"
          isPending={isDeletePending}
        />
      )}
      <div className="flex justify-between items-center w-full">
        <UnderlineTitle />
      </div>
      <Card>
        <div className="flex w-full overflow-x-auto lg:overflow-hidden md:justify-end min-h-10 mb-6 gap-4 pb-2 lg:pb-0">
          {!dontHasProducts && (
            <>
              <TableFilters
                filtersQuantity={howSomeFilterActive?.length || null}
                clearFilters={() => {
                  setFilters({
                    name: "",
                    type: "",
                    isAvailable: "",
                    isRelease: "",
                  })
                  setCustomFields({})
                }}
              >
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-8 lg:mb-0">
                  <InputWithLabel
                    label="Nome"
                    rightIcon="MagnifyingGlass"
                    placeholder="Pesquisar por nome"
                    createEspaceToLabel={false}
                    onType={handleChangeName}
                    isDisabled={dontHasProducts}
                    value={filters.name}
                    size="full"
                  />
                  <Select
                    label="Disponível"
                    placeholder="Disponível"
                    options={availableOptions}
                    selectedValue={filters.isAvailable}
                    onSelectOption={handleSelectAvailable}
                    isDisabled={dontHasProducts}
                    className="w-full"
                  />
                  <Select
                    label="Lançamento"
                    placeholder="Lançamento"
                    options={releaseOptions}
                    selectedValue={filters.isRelease}
                    onSelectOption={handleSelectRelease}
                    isDisabled={dontHasProducts}
                    className="w-full"
                  />
                  {filtersData?.map((filter) => {
                    if (filter.type === "select") {
                      return (
                        <Select
                          key={filter.key}
                          label={filter.label}
                          placeholder={filter.placeholder}
                          options={filter.options}
                          selectedValue={customFields[filter.key]}
                          onSelectOption={(selected) =>
                            setCustomFields({
                              ...customFields,
                              [filter.key]: selected.value,
                            })
                          }
                          isDisabled={dontHasProducts}
                          className="w-full"
                        />
                      )
                    }
                  })}
                </div>
              </TableFilters>
              <SimpleButton
                label="Adicionar produto"
                icon="Plus"
                onClick={() => {
                  navigate(privateRoutes.newProduct)
                }}
              />
            </>
          )}
        </div>
        <Table
          headers={headersBySegment?.[userSegment]}
          data={formatProductsData(
            listProductsData?.data,
            setDeleteProductId,
            handleEditProduct
          )}
          customEmptyMessage={customEmptyMessage}
          isPending={isListProductsPending}
          emptyIcon={dontHasProducts ? "Package" : "SmileySad"}
          iconLoading="Package"
        />
        {!dontHasProducts &&
          (listProductsData?.total || lastQuantityOfTotalItems) && (
            <div className="w-full flex justify-end items-center mt-auto">
              <Pagination
                currentPage={pageFilters.actualPage}
                itemsPerPage={pageFilters.itemsPerPage}
                totalItems={listProductsData?.total || lastQuantityOfTotalItems}
                onPageChange={handleChangePage}
              />
            </div>
          )}
      </Card>
    </>
  )
}

export default Products
