import { formatNumberToMoney } from "@utils/money"
import React from "react"

interface IPrice {
  price: number
  salePrice?: number
}

const Price: React.FC<IPrice> = ({ price, salePrice }) => {
  return (
    <div className="flex relative justify-center items-baseline my-8">
      {salePrice ? (
        <div className="flex items-center">
          <span className="mr-2 text-xl font-bold line-through text-gray-500 absolute bottom-9 left-[50%] transform translate-x-[-50%]">
            {formatNumberToMoney(price).replace(",00", "")}
          </span>
          <span className="mr-2 text-4xl font-extrabold text-secondary-500">
            {formatNumberToMoney(salePrice).replace(",00", "")}
          </span>
        </div>
      ) : (
        <span className="mr-2 text-4xl font-extrabold text-primary-500">
          {formatNumberToMoney(price).replace(",00", "")}
        </span>
      )}
      <span className="text-gray-500">/mês</span>
    </div>
  )
}

export default Price
