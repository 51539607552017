import React from "react"
import { ITooltip } from "@interfaces/Components"
import { Tooltip as ReactTooltip } from "react-tooltip"
import "react-tooltip/dist/react-tooltip.css"

const Tooltip: React.FC<ITooltip> = ({
  id,
  text,
  children,
  place,
  disableTooltip,
}) => {
  return (
    <div>
      <ReactTooltip
        id={id}
        content={text}
        style={{
          fontSize: 14,
          zIndex: 999999999,
        }}
        place={place}
        delayShow={disableTooltip ? 999999999 : 100}
      />
      <div data-tooltip-id={id} className="flex">
        {children}
      </div>
    </div>
  )
}

export default Tooltip
