/* eslint-disable @typescript-eslint/no-explicit-any */
import { toast } from "sonner"
import { clientApi } from "../clientApi"
import { generateErrorMessage } from "@utils/generateErrorMessage"

interface IPostData {
  password: string
  token_hash: string
}

const resetPassword = {
  fn: async ({ token_hash, password }: IPostData): Promise<void> => {
    try {
      await clientApi.post("/auth/reset_password", {
        password,
        token_hash,
      })

      toast.success("Senha redefinida com sucesso.")
    } catch (error: any) {
      generateErrorMessage("Erro ao redefinir senha: ", error)
    }
  },
  key: "resetPassword",
}

export { resetPassword }
