import React from "react"
import Tooltip from "@components/Tooltip"
import { Info } from "phosphor-react"
import { IToggleInput } from "@interfaces/Components/Input"
import clsx from "clsx"

const ToggleInput: React.FC<IToggleInput> = ({
  info,
  name,
  title,
  register,
  description,
  disabled,
  checked,
  onChange,
  negativeText = "Não",
  positiveText = "Sim",
}) => {
  const inputProps = register?.(name)

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (disabled) return

    if (inputProps?.onChange) {
      inputProps?.onChange?.(e)
    } else {
      onChange?.(e)
    }
  }

  return (
    <div className="w-full flex flex-col">
      <div className="w-full flex justify-between items-end">
        <div className="w-full flex flex-col">
          <div className="w-full flex items-center">
            <p className="text-base font-semibold text-neutral-700 mr-2">
              {title}
            </p>
            {info && (
              <Tooltip id={title} text={info}>
                <Info size={16} />
              </Tooltip>
            )}
          </div>
          {description && (
            <p className="w-11/12 md:text-sm text-neutral-500">{description}</p>
          )}
        </div>
        <div className="flex justify-center gap-2 items-center">
          <p className="text-sm md:text-base text-primary-500 font-medium">
            {" "}
            {negativeText}
          </p>
          <label
            className={clsx(
              "flex select-none items-center",
              disabled && "opacity-50",
              !disabled && "cursor-pointer"
            )}
          >
            <div className="relative">
              <input
                type="checkbox"
                className="sr-only peer"
                onChange={handleCheckboxChange}
                disabled={disabled}
                {...inputProps}
                checked={checked ?? inputProps?.checked}
                name={name ?? inputProps?.name}
              />
              <div className="box block h-6 w-10 md:h-8 md:w-14 rounded-full peer-checked:bg-primary-500 bg-gray-400" />
              <div className="absolute left-1 top-1 flex h-4 w-4 md:h-6 md:w-6 items-center justify-center rounded-full bg-white transition transform peer-checked:translate-x-full" />
            </div>
          </label>
          <p className="text-sm md:text-base text-primary-500 font-medium">
            {positiveText}
          </p>
        </div>
      </div>
    </div>
  )
}

export default ToggleInput
