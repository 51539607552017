import * as yup from "yup"
import CartDrawer from "@components/Ecommerce/CartDrawer"
import FilterBar from "@components/Ecommerce/Filterbar"
import FullLogo from "@assets/FullLogo"
import Loading from "@components/Loading"
import Pagination from "@components/Pagination"
import ProductGrid from "@components/Ecommerce/ProductGrid"
import React, { useEffect, useState } from "react"
import SearchBar from "@components/Ecommerce/SearchBar"
import useLocalStorage from "@hooks/useLocalStorage"
import useStore from "@store"
import { ecommerceService } from "@services/Ecommerce"
import { ICartItem } from "@interfaces/Components/Ecommerce"
import { ShoppingBag } from "phosphor-react"
import { toast } from "sonner"
import { useForm } from "react-hook-form"
import { useLocation } from "react-router-dom"
import { useMutation, useQuery } from "@tanstack/react-query"
import { yupResolver } from "@hookform/resolvers/yup"
import { IListProducts, IVariant } from "@interfaces/Services"
import NotFoundClientPage from "@components/NotFoundClientPage"
import EcommerceClientVerify from "@components/EcommerceClientVerify"

const formSchema = yup.object({
  name: yup.string().required("Nome do cliente é obrigatório"),
  email: yup.string().email("Email inválido").required("Email é obrigatório"),
  cnpj: yup.string().required("CNPJ é obrigatório").min(14, "CNPJ inválido"),
  payment_method: yup.string().required("Método de pagamento é obrigatório"),
})

const Ecommerce: React.FC = ({}) => {
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const slug = searchParams.get("slug")

  const { data: ecommerceSettings } = useQuery({
    queryKey: ["paymentMethods", slug],
    queryFn: () =>
      ecommerceService.findUserEcommerceSettings.fn(slug as string),
  })

  const [currentPage, setCurrentPage] = useState(1)

  const [search, setSearch] = useState("")
  const [selectedFilters, setSelectedFilters] = useState<string[]>([])

  const [grantedPermission, setGrantedPermission] = useState(false)

  const {
    data: products,
    isLoading,
    isError,
  } = useQuery<IListProducts>({
    queryKey: ["products", slug, search, selectedFilters, currentPage],
    queryFn: () =>
      ecommerceService.listProducts.fn({
        slug,
        currentPage,
        name: search,
        isRelease: selectedFilters.includes("is_release"),
        isSale: selectedFilters.includes("is_sale"),
      }),
  })

  const filterCart = (
    cart: {
      id: string
      quantity: number
      name: string
    }[]
  ) =>
    cart.filter((item) =>
      products?.data.some((product) => product.id === item.id)
    )

  const [storagedCart, setStoragedCart] = useLocalStorage<
    {
      id: string
      quantity: number
      name: string
    }[]
  >(`cart-${slug}`, [], filterCart)

  const [isFinishingOrder, setIsFinishingOrder] = useState(false)

  const [drawerIsOpen, setDrawerIsOpen] = useState(false)

  const {
    register,
    control,
    formState: { errors, isValid },
    handleSubmit,
    reset,
  } = useForm({
    resolver: yupResolver(formSchema),
    mode: "onChange",
  })

  const handleAddCart = (item: ICartItem, quantity: number = 1) => {
    const existingProduct = storagedCart.find(
      (cartItem) => cartItem.id === item.id
    )

    if (!existingProduct) {
      setStoragedCart([
        ...storagedCart,
        {
          id: item.id,
          quantity: quantity,
          name: item.name,
        },
      ])
    } else {
      setStoragedCart(
        storagedCart.map((cartItem) =>
          cartItem.id === item.id
            ? { ...cartItem, quantity: cartItem.quantity + quantity }
            : cartItem
        )
      )
    }

    toast.success("Produto adicionado ao carrinho", {
      duration: 700,
    })
  }

  const handleRemoveCart = (id: string) => {
    setStoragedCart(storagedCart.filter((cartItem) => cartItem.id !== id))
    toast.warning("Produto removido do carrinho", {
      duration: 700,
    })
  }

  const handleUpdateQuantity = (id: string, quantity: number) => {
    if (quantity <= 0) {
      handleRemoveCart(id)
    } else {
      setStoragedCart(
        storagedCart.map((item) =>
          item.id === id ? { ...item, quantity } : item
        )
      )
    }
  }

  const handleFilter = (filter: string) => {
    if (selectedFilters.includes(filter)) {
      setSelectedFilters(
        selectedFilters.filter((selected) => selected !== filter)
      )
    } else {
      setSelectedFilters([...selectedFilters, filter])
    }

    setCurrentPage(1)
  }

  const { mutate: createOrder, isPending: isCreatingOrderPending } =
    useMutation({
      mutationFn: ecommerceService.createOrder.fn,
      mutationKey: [ecommerceService.createOrder.key],
      onSuccess: () => {
        setIsFinishingOrder(false)
        setStoragedCart([])
        setDrawerIsOpen(false)
        reset()
      },
      onError: () => {
        setIsFinishingOrder(false)
        toast.error("Ocorreu um erro ao realizar o pedido")
      },
    })

  const handleOrder = (data: {
    name: string
    email: string
    cnpj: string
    payment_method: string
  }) => {
    setIsFinishingOrder(true)
    createOrder({
      slug: slug as string,
      name: data.name,
      email: data.email,
      cnpj: data.cnpj,
      payment_method: data.payment_method,
      product_variants: storagedCart.map((item) => ({
        id: item.id,
        quantity: item.quantity,
        name: item.name,
      })),
    })
  }

  if (isError) {
    return <NotFoundClientPage />
  }

  const allVariants: IVariant[] = products?.data.reduce(
    (acc, product) => [...acc, ...product.variants],
    []
  )

  const { mutate: verifyClient, isPending: isVerifyingClient } = useMutation({
    mutationFn: ({ slug, cnpj }: { slug: string; cnpj: string }) =>
      ecommerceService.verifyClient.fn(slug, cnpj),
    mutationKey: [ecommerceService.verifyClient.key],
    onSuccess: (data) => {
      if (data.can_access) {
        setGrantedPermission(true)
      } else {
        toast.error("CNPJ não autorizado")
      }
    },
    onError: () => {
      toast.error("Erro ao verificar CNPJ")
    },
  })

  if (ecommerceSettings === undefined) {
    return (
      <div className="h-dvh flex items-center justify-center">
        <FullLogo size="2xlarge" className="animate-pulse" />
      </div>
    )
  }

  if (!grantedPermission && ecommerceSettings?.only_clients_can_buy) {
    return (
      <EcommerceClientVerify
        ecommerceSettings={ecommerceSettings}
        handleVerifyClient={(cnpj: string) =>
          verifyClient({ slug: slug as string, cnpj })
        }
        isLoading={isVerifyingClient}
      />
    )
  }

  return (
    <>
      <div className="w-screen h-dvh min-h-screen mx-auto p-4  overflow-y-auto bg-white">
        <div className="max-w-[1440px] h-full mx-auto flex flex-col">
          <div className="flex justify-between mb-5">
            <div className="h-10 flex items-center">
              <FullLogo disableNavigation />
            </div>
            <label
              className="relative flex items-center justify-center rounded-full text-primary-500 w-12 h-12 cursor-pointer"
              onClick={() => setDrawerIsOpen(true)}
            >
              <ShoppingBag size={35} weight="fill" />
              <span className="flex items-center justify-center absolute bottom-6 left-6 border-2 border-primary-500 bg-white w-6 h-6 rounded-full font-bold">
                {storagedCart.length}
              </span>
            </label>
          </div>

          {/* Barra de pesquisa */}
          <SearchBar onSearch={setSearch} />

          {/* Filtros */}
          <div className="my-5">
            <FilterBar
              selectedFilters={selectedFilters}
              handleFilter={handleFilter}
            />
          </div>

          {isLoading ? (
            <div className="h-[calc(100vh-250px)] flex items-center justify-center">
              <Loading iconLoading="ShoppingBag" size={128} />
            </div>
          ) : products?.data.length === 0 ? (
            <div className="h-[calc(100vh-250px)] flex items-center justify-center">
              <span className="text-xl text-gray-500">
                Desculpe, nenhum produto encontrado. 😢
              </span>
            </div>
          ) : (
            <div className="h-full flex flex-col gap-5">
              <ProductGrid
                products={products?.data || []}
                storagedCart={[]}
                handleAddCart={handleAddCart}
                handleRemoveCart={handleRemoveCart}
                handleOpenDrawer={() => setDrawerIsOpen(true)}
              />
              <div className="flex items-center mt-auto self-end">
                <span className="mr-5">
                  Total de produtos: <strong>{products?.total}</strong>
                </span>
                <Pagination
                  totalItems={products?.total || 0}
                  currentPage={products?.current_page || 1}
                  itemsPerPage={15}
                  maxVisiblePages={products?.pages}
                  onPageChange={(page) => setCurrentPage(page)}
                />
              </div>
            </div>
          )}
        </div>
      </div>

      <CartDrawer
        errors={errors}
        isValid={isValid}
        register={register}
        control={control}
        isPending={isCreatingOrderPending}
        storagedCart={storagedCart}
        allVariants={allVariants}
        drawerIsOpen={drawerIsOpen}
        setDrawerIsOpen={setDrawerIsOpen}
        handleRemoveCart={handleRemoveCart}
        isFinishingOrder={isFinishingOrder}
        handleClearCart={() => {
          setStoragedCart([])
          setDrawerIsOpen(false)
        }}
        handleSubmit={handleSubmit(handleOrder)}
        setIsFinishingOrder={setIsFinishingOrder}
        handleUpdateQuantity={handleUpdateQuantity}
        ecommerceSettings={ecommerceSettings}
      />
    </>
  )
}

export default Ecommerce
