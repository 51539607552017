import { MinusCircle, PlusCircle, TrashSimple } from "phosphor-react"
import { formatNumberToMoney } from "@utils/money"
import { ICartItem } from "@interfaces/Components/Ecommerce"
import { calculatePriceWithSale } from "./CartDrawer"

interface CartItemProps {
  item: ICartItem
  handleUpdateQuantity: (id: string, quantity: number) => void
  handleRemoveCart: (id: string) => void
}

const CartItem: React.FC<CartItemProps> = ({
  item,
  handleUpdateQuantity,
  handleRemoveCart,
}) => {
  if (!item.id) return null

  const priceFromSales = calculatePriceWithSale(
    item.sale_price || item.price,
    item.quantity,
    item.sales || []
  )

  const priceIsSale = priceFromSales.lastPrice > 0

  return (
    <div className="flex items-center w-full">
      <img
        src={item?.image_url}
        alt={`Produto - ${item.name}`}
        className="w-24 h-24 object-scale-down"
      />
      <div className="flex flex-col items-start justify-center gap-3 h-full ml-2 text-base">
        <span className="font-normal text-base text-gray-700">{item.name}</span>
        <div className="flex items-center">
          <MinusCircle
            size={24}
            className="text-primary-400 cursor-pointer"
            weight="fill"
            onClick={() => handleUpdateQuantity(item.id, item.quantity - 1)}
          />
          <input
            type="text"
            className="max-w-[60px] text-center mx-2 border border-gray-400 rounded-md"
            value={item.quantity}
            onChange={(e) => {
              const value = parseInt(e.target.value, 10)
              if (value > 0) handleUpdateQuantity(item.id, value)
            }}
          />
          <PlusCircle
            size={24}
            className="text-primary-400 cursor-pointer"
            weight="fill"
            onClick={() => handleUpdateQuantity(item.id, item.quantity + 1)}
          />
          <div className="divider divider-horizontal before:bg-gray-500 after:bg-gray-500 mx-3"></div>
          <TrashSimple
            size={24}
            className="text-primary-400 cursor-pointer"
            weight="fill"
            onClick={() => handleRemoveCart(item.id)}
          />
        </div>
      </div>
      <div className="flex flex-col items-center justify-center text-lg font-medium ml-auto">
        {priceIsSale ? (
          <span className="text-gray-400 line-through text-base">
            {formatNumberToMoney(
              calculatePriceWithSale(item.price, item.quantity, item.sales)
                .lastPrice
            )}
          </span>
        ) : null}
        <span
          className={
            priceIsSale
              ? "text-secondary-500 font-semibold"
              : "text-primary-500 font-semibold"
          }
        >
          {formatNumberToMoney(
            calculatePriceWithSale(
              item.sale_price || item.price,
              item.quantity,
              item.sales || []
            ).price
          )}
        </span>
      </div>
    </div>
  )
}

export default CartItem
