import { SimpleButton } from "@components/Buttons/SimpleButton"
import { publicRoutes } from "@constants/Routes"
import { Flag } from "phosphor-react"
import React from "react"

interface INotFoundClientPage {}

const NotFoundClientPage: React.FC<INotFoundClientPage> = ({}) => {
  return (
    <div className="h-screen mx-auto flex flex-col justify-center items-center text-center px-8 gap-10">
      <Flag weight="fill" size={120} className="text-secondary-500" />
      {/* <Typography
          variant="h1"
          color="blue-gray"
          className="mt-10 !text-3xl !leading-snug md:!text-4xl"
        >
          Error 404 <br /> It looks like something went wrong.
        </Typography>
        <Typography className="mt-8 mb-14 text-[18px] font-normal text-gray-500 mx-auto md:max-w-sm">
          Don&apos;t worry, our team is already on it.Please try refreshing the
          page or come back later.
        </Typography>
        <Button color="gray" className="w-full px-4 md:w-[8rem]">
          back home
        </Button> */}
      <p className="text-primary-500 font-semibold text-4xl w-full max-w-3xl mx-auto">
        Não encontramos a página do cliente.
      </p>
      <p className="text-gray-500 text-lg w-full max-w-xl mx-auto">
        Não se preocupe, pode ser que o link esteja errado, entre em contato com
        o cliente para verificar essa informação.
      </p>

      <SimpleButton
        onClick={() => (window.location.href = publicRoutes.landing)}
        label="Ir para a página inicial"
      />
    </div>
  )
}

export default NotFoundClientPage
