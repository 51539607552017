import React from "react"
import useStore from "@store"
import dayjs from "dayjs"
import BasicPlan from "./BasicPlan"
import PremiumPlan from "./PremiumPlan"
import FreePlan from "./FreePlan"
import { Plan } from "@interfaces/Components"

interface IPlanPrices {
  selectedPlan?: string
  onlyView?: boolean
  isOpen?: boolean
  onSelectedPlan: (plan: Plan) => Promise<void>
  onCancel: (setIsLoading: any) => void
}

export const generateAlertMessage = (
  message: string,
  size = "text-lg",
  margin = "mb-10"
) => {
  return (
    <div
      className={`w-full p-2 text-center bg-yellow-100 border-l-4 border-yellow-500 dark:bg-yellow-300 dark:border-yellow-600 ${margin}`}
    >
      <p
        className={`text-left font-medium text-yellow-800 dark:text-yellow-900 ${size}`}
      >
        {message}
      </p>
    </div>
  )
}

const PlanPrices: React.FC<IPlanPrices> = ({
  selectedPlan,
  onlyView = false,
  isOpen,
  onSelectedPlan,
  onCancel,
}) => {
  const { userDetail } = useStore()

  const isFirst7Days =
    dayjs().diff(dayjs(userDetail?.subscription_date), "day") < 7

  return (
    <section className="w-full bg-white dark:bg-gray-900">
      <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-2 lg:px-6">
        {onlyView && (
          <div className="mx-auto max-w-screen-md text-center mb-8 lg:mb-12">
            <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">
              Feito para equipes de negócios como a sua
            </h2>
            <p className="mb-5 font-light text-gray-500 sm:text-xl dark:text-gray-400">
              Aqui na{" "}
              <span className="text-primary-400 font-bold">CliquePeça</span>,
              focamos em tecnologia para seu negócio, com planos que se encaixam
              perfeitamente na sua necessidade.
            </p>
          </div>
        )}
        {isFirst7Days &&
          !onlyView &&
          generateAlertMessage(
            "Você está no período de 7 dias iniciais de uso de seu plano. Caso realize o cancelamento, o valor pago será reembolsado e você perderá os benefícios do plano."
          )}
        {userDetail?.cancel_at_period_end &&
          generateAlertMessage(
            "Seu plano foi cancelado e será encerrado no final do ciclo de faturamento."
          )}
        <div className="w-full space-y-8 lg:grid lg:grid-cols-3 sm:gap-6 xl:gap-10 lg:space-y-0">
          <FreePlan selectedPlan={selectedPlan ?? ""} />
          <BasicPlan
            selectedPlan={selectedPlan ?? ""}
            isOpen={isOpen ?? false}
            onSelectedPlan={onSelectedPlan}
            onCancel={onCancel}
            isFirst7Days={isFirst7Days}
            onlyView={onlyView}
          />
          <PremiumPlan
            selectedPlan={selectedPlan ?? ""}
            isOpen={isOpen ?? false}
            onSelectedPlan={onSelectedPlan}
            onCancel={onCancel}
            isFirst7Days={isFirst7Days}
            onlyView={onlyView}
          />
        </div>
      </div>
    </section>
  )
}

export default PlanPrices
