import { sidebarMenu } from "@constants/Routes"
import clsx from "clsx"
import { List } from "phosphor-react"
import React, { useEffect, useState } from "react"
import SideBarOption from "./SideBarOption"
import useStore from "@store"

interface ISideBarMobile {}

const SideBarMobile: React.FC<ISideBarMobile> = ({}) => {
  const { userDetail } = useStore()
  const [isCollapsed, setIsCollapsed] = useState(true)

  const refDiv = React.useRef<HTMLDivElement>(null)

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (refDiv.current && !refDiv.current.contains(event.target)) {
        setIsCollapsed(true)
      }
    }

    document.addEventListener("mousedown", handleClickOutside)

    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [refDiv])

  return (
    <>
      <div
        className={clsx(
          "lg:hidden h-full absolute w-64 bg-white shadow-xl transition-all duration-700 rounded-b-3xl z-30 p-2",
          isCollapsed ? "right-[-256px]" : "right-0"
        )}
        onClick={() => {
          setIsCollapsed(!isCollapsed)
        }}
        ref={refDiv}
      >
        <div
          className={clsx(
            "h-full lg:h-[calc(100vh-230px)] flex flex-col justify-start items-start gap-2 overflow-hidden"
          )}
        >
          {sidebarMenu(userDetail).map((option, index) => (
            <SideBarOption key={index} isCollapsed={isCollapsed} {...option} />
          ))}
        </div>
      </div>
      <List
        weight="fill"
        className={clsx(
          "absolute lg:hidden top-5 text-primary-500 cursor-pointer transition-all duration-700 right-5"
        )}
        size={32}
        onClick={() => {
          setIsCollapsed(!isCollapsed)
        }}
      />
    </>
  )
}

export default SideBarMobile
