import React from "react"
import Tooltip from "./Tooltip"
import { Info } from "phosphor-react"
import { ITitle } from "@interfaces/Components"

const Title: React.FC<ITitle> = ({ title, info }) => {
  return (
    <div className="flex justify-start items-center mb-4 gap-2">
      <p className="text-lg font-semibold text-primary-500">{title}</p>
      {info && (
        <Tooltip id={title} text={info}>
          <Info size={16} />
        </Tooltip>
      )}
    </div>
  )
}

export default Title
