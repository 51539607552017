import { KJUR } from "jsrsasign"
import { localValidationToken } from "./localValidationToken"
import { ITokenData } from "@services/SignIn/loginUser"

export interface ITokenDataDecoded {
  payloadObj: {
    email: string
    sub: string
    user_metadata: { role: string }
  }
}

export const decryptToken = (
  tokenData: ITokenData
): ITokenDataDecoded | undefined => {
  const jsonParser = KJUR.jws.JWS

  const tokenIsVerificated = localValidationToken(tokenData)

  if (!tokenIsVerificated) {
    return undefined
  }

  const decrypToken = jsonParser.parse(tokenData?.access_token) as {
    payloadObj: {
      email: string
      sub: string
      user_metadata: { role: string }
    }
  }

  return decrypToken
}
