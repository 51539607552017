import React from "react"
import { Navigate, Route, Routes } from "react-router-dom"

import SignUp from "@pages/Public/SignUp"
import Landing from "@pages/Public/Landing"
import SignIn from "@pages/Public/SignIn"
import ForgotPassword from "@pages/Public/ForgotPassword"
import ResetPassword from "@pages/Public/ResetPassword"

import SplashScreen from "@components/SplashScreen"
import { publicRoutes } from "@constants/Routes"
import Ecommerce from "@pages/Public/Ecommerce"

const Public: React.FC = ({}) => {
  return (
    <Routes>
      <Route path={publicRoutes.landing} element={<Landing />} />
      <Route path={publicRoutes.splashScreen} element={<SplashScreen />} />
      <Route path={publicRoutes.signUp} element={<SignUp />} />
      <Route path={publicRoutes.signIn} element={<SignIn />} />
      <Route path={publicRoutes.forgotPassword} element={<ForgotPassword />} />
      <Route path={publicRoutes.resetPassword} element={<ResetPassword />} />
      <Route path={publicRoutes.products} element={<Ecommerce />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  )
}

export default Public
