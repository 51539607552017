import { SimpleButton } from "@components/Buttons/SimpleButton"
import { publicRoutes } from "@constants/Routes"
import { Lock } from "phosphor-react"
import React, { useState } from "react"
import InputWithLabel from "./Inputs/InputWithLabel"
import { IEcommerceSettings } from "@interfaces/Pages/Ecommerce"
import { useMutation } from "@tanstack/react-query"

interface IEcommerceClientVerify {
  ecommerceSettings: IEcommerceSettings
  handleVerifyClient: (cnpj: string) => void
  isLoading: boolean
}

const EcommerceClientVerify: React.FC<IEcommerceClientVerify> = ({
  ecommerceSettings,
  handleVerifyClient,
  isLoading,
}) => {
  const [cnpj, setCnpj] = useState<string>("")

  return (
    <div className="h-screen mx-auto flex flex-col justify-center items-center text-center px-8 gap-6">
      <Lock weight="fill" size={120} className="text-secondary-500" />

      <p className="text-primary-500 font-semibold text-4xl w-full max-w-3xl mx-auto">
        Seja bem-vindo a loja de {ecommerceSettings?.userName}
      </p>
      <p className="text-gray-500 text-lg w-full max-w-xl mx-auto">
        Para realizar compras, é necessário fazer login com sua conta de
        cliente. por favor, insira seu CNPJ e clique em "Entrar".
      </p>

      <div className="w-full max-w-sm mx-auto">
        <InputWithLabel
          label="CNPJ"
          placeholder="CNPJ"
          size="full"
          masks="cnpj"
          value={cnpj}
          onType={(value) => setCnpj(value)}
          onEnter={() => handleVerifyClient(cnpj)}
        />
        <SimpleButton
          label="Entrar"
          onClick={() => {
            handleVerifyClient(cnpj)
          }}
          className="w-full mt-4"
          disabled={!cnpj || cnpj.length < 18}
          isLoading={isLoading}
        />
      </div>
    </div>
  )
}

export default EcommerceClientVerify
