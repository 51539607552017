import Admin from "@pages/Private/Admin"
import Clients from "@pages/Private/Clients"
import Dashboard from "@pages/Private/Dashboard"
import NewProduct from "@pages/Private/NewProduct"
import OrderDetail from "@pages/Private/OrderDetail"
import Orders from "@pages/Private/Orders"
import Products from "@pages/Private/Products"
import React from "react"
import { autoRefreshSession } from "@utils/autoRefreshSession"
import { clientApi } from "@services/clientApi"
import { Navigate, Route, Routes } from "react-router-dom"
import { privateRoutes } from "@constants/Routes"
import { useFlag } from "@utils/featureFlags"
import Profile from "@pages/Private/Profile"

const Private: React.FC = ({}) => {
  clientApi.interceptors.response.use(
    (response) => {
      return response
    },
    (error) => {
      if (error.response?.status === 403) {
        autoRefreshSession({})
      }
      return Promise.reject(error)
    }
  )

  const enableAdminPage = useFlag("admin").isActive

  return (
    <Routes>
      <Route path={privateRoutes.dashboard} element={<Dashboard />} />
      <Route path={privateRoutes.products} element={<Products />} />
      <Route path={privateRoutes.newProduct} element={<NewProduct />} />
      <Route path={privateRoutes.editProduct} element={<NewProduct />} />
      <Route path={privateRoutes.orderDetails} element={<OrderDetail />} />
      <Route
        path={`${privateRoutes.settings}${privateRoutes.profile}`}
        element={<Profile />}
      />
      <Route path={privateRoutes.orders} element={<Orders />} />
      <Route path={privateRoutes.clients} element={<Clients />} />
      {enableAdminPage && (
        <Route path={privateRoutes.admin} element={<Admin />} />
      )}
      <Route path="*" element={<Navigate to={privateRoutes.dashboard} />} />
    </Routes>
  )
}

export default Private
