/* eslint-disable @typescript-eslint/no-explicit-any */

import { ITokenData } from "@services/SignIn/loginUser"
import { clientApi } from "@services/clientApi"

interface IRefreshTokenProps {
  refresh_token?: string
}

export const refreshToken = async ({
  refresh_token,
}: IRefreshTokenProps): Promise<ITokenData | void> => {
  if (!refresh_token) {
    return
  }

  try {
    const response = await clientApi.post("/auth/refresh_token", {
      refresh_token: refresh_token,
    })

    return response.data
  } catch (error: any) {
    throw new Error(error)
  }
}
