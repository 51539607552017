import { ITokenData } from "@services/SignIn/loginUser"
import { KJUR } from "jsrsasign"

const jwtSecret = import.meta.env.VITE_SUPABASE_JWT_SECRET

const tokenIsExpired = (tokenData: ITokenData) => {
  const currentTime = Date.now()
  const tokenDataExp = Number(tokenData.expires_at) * 1000

  return currentTime > tokenDataExp
}

export const localValidationToken = (tokenData: ITokenData) => {
  const tokenExpired = tokenIsExpired(tokenData)

  if (tokenExpired) {
    return false
  }

  try {
    const utf8EncodedKey = new TextEncoder().encode(jwtSecret)

    const hexSecretKey = Array.from(utf8EncodedKey)
      .map((b) => b.toString(16).padStart(2, "0"))
      .join("")

    const isValid = KJUR.jws.JWS.verifyJWT(
      tokenData.access_token,
      hexSecretKey,
      {
        alg: ["HS256"],
      }
    )

    return isValid
  } catch (error) {
    return false
  }
}
