import { IListProducts, IProduct } from "@interfaces/Services"
import { clientApi } from "./clientApi"
import { toast } from "sonner"
import { generateErrorMessage } from "@utils/generateErrorMessage"
import { IEcommerceSettings } from "@interfaces/Pages/Ecommerce"

const ecommerceService = {
  listProducts: {
    fn: async ({
      slug,
      name,
      currentPage,
      isRelease,
      isSale,
    }: any): Promise<IListProducts> => {
      try {
        const { data } = await clientApi.get<IListProducts>(
          `/ecommerce/products/${slug}`,
          {
            params: {
              page: currentPage,
              name,
              is_release: isRelease ? isRelease : undefined,
              is_on_sale: isSale ? isSale : undefined,
            },
          }
        )

        const productOnlyVariantsAvailable = data.data.map(
          (product: IProduct) => {
            return {
              ...product,
              variants: product.variants.filter(
                (variant) => variant.is_available
              ),
            }
          }
        )

        const productOnlyVariantsAvailableAndHasStock =
          productOnlyVariantsAvailable.filter(
            (product) => product.variants.length > 0
          )

        return {
          ...data,
          data: productOnlyVariantsAvailableAndHasStock,
        }
      } catch (error: any) {
        return generateErrorMessage("Erro ao buscar: ", error)
      }
    },
    key: "listProducts",
  },
  createOrder: {
    fn: async (data: {
      slug: string
      name: string
      email: string
      cnpj: string
      payment_method: string
      product_variants: any[]
    }) => {
      const response = clientApi.post("/orders", data)

      toast.promise(response, {
        loading: "Enviando pedido...",
        success: "Pedido realizado com sucesso!",
        error: "Erro ao realizar o pedido",
      })

      await response
    },
    key: "createOrder",
  },
  findUserEcommerceSettings: {
    fn: async (slug: string) => {
      try {
        const { data } = await clientApi.get<IEcommerceSettings>(
          `/ecommerce/settings`,
          {
            params: {
              slug,
            },
          }
        )

        return data
      } catch (error: any) {
        return generateErrorMessage("Erro ao buscar: ", error)
      }
    },
    key: "findUserEcommerceSettings",
  },
  verifyClient: {
    fn: async (slug: string, cnpj: string) => {
      const { data } = await clientApi.post("/ecommerce/verify-client", {
        cnpj,
        slug,
      })

      return data
    },
    key: "verifyClient",
  },
}
export { ecommerceService }
