import clsx from "clsx"
import StatusTag from "@components/StatusTag"
import TableActionItem from "@components/Table/TableActionItem"
import TableActions from "@components/Table/TableActions"
import { formatNumberToMoney } from "./money"
import { Masks } from "@constants/Masks"
import { OrderStatus } from "@constants/index"
import {
  IListClients,
  IListOrders,
  IListProducts,
  OrderDetail,
} from "@interfaces/Services"
import { Tooltip } from "react-tooltip"

export const formatOrderDetailsData = (data: OrderDetail["products"]) => {
  if (!data) return []

  return data.map((order) => {
    const productName = `${order?.variant?.product?.name} - ${order?.variant?.type_value} ${order?.variant?.type_name}`

    return {
      ...order,
      name: {
        value: productName,
        component: <div className="flex items-center gap-2">{productName}</div>,
      },
      quantity: order?.quantity,
      price: formatNumberToMoney(order?.price),
      total: formatNumberToMoney(order?.price * order?.quantity),
    }
  })
}

export const formatProductsData = (
  data?: IListProducts["data"],
  setDeleteProductId?: (value: string) => void,
  setEditProductModalIsOpen?: (value: string) => void
) => {
  if (!data) return []

  return data.map((product) => {
    const hasSomeVariantAvailable = product.variants.some(
      (variant) => variant.is_available
    )

    return {
      ...product,
      name: {
        value: product.name,
        component: (
          <div className="flex items-center gap-2">
            {product.name.length > 40 && (
              <Tooltip id={product.name} content={product.name} />
            )}

            <span data-tooltip-id={product.name} className="truncate">
              {product.name}
            </span>
          </div>
        ),
      },
      link: {
        value: "",
        component: product?.custom_fields?.find?.(
          (field) => field?.key === "product_url"
        )?.value ? (
          <a
            href={
              product?.custom_fields?.find(
                (field) => field.key === "product_url"
              )?.value
            }
            target="_blank"
            rel="noreferrer"
            className={clsx(
              "",
              !product?.custom_fields?.find(
                (field) => field.key === "product_url"
              )
                ? "text-gray-400 cursor-not-allowed"
                : "text-primary-500 hover:underline"
            )}
          >
            Ver produto
          </a>
        ) : (
          "Sem link"
        ),
      },
      variants: {
        value: product.variants.length,
        component: (
          <div className="flex items-center gap-2">
            <div className="bg-primary-500 text-white px-4 py-1 rounded-full font-medium ">
              {product.variants.length} variantes
            </div>
          </div>
        ),
      },
      is_available: {
        value: hasSomeVariantAvailable ? "Sim" : "Não",
        component: (
          <StatusTag
            text={hasSomeVariantAvailable ? "Sim" : "Não"}
            type={hasSomeVariantAvailable ? "success" : "danger"}
          />
        ),
      },
      actions: {
        value: "",
        component: (
          <TableActions
            id={product.id}
            onEdit={setEditProductModalIsOpen}
            onDelete={setDeleteProductId}
          />
        ),
      },
    }
  })
}

export const formatOrdersData = (
  data: IListOrders["data"],
  onDelete: (id: string) => void,
  handleViewDetails: (id: string) => void,
  handleApproveOrder: (id: string) => void,
  handleDisapproveOrder: (id: string) => void
) => {
  if (!data) return []

  return data.map((order: any) => ({
    ...order,
    cnpj: Masks.cnpj(order.cnpj),
    total: formatNumberToMoney(order.total),
    createdAt: new Date(order.created_at).toLocaleString(),
    status: {
      value: order.status,
      component: (
        <StatusTag
          text={OrderStatus?.[order?.status]?.value}
          type={OrderStatus?.[order?.status]?.type as any}
        />
      ),
    },
    actions: {
      value: "",
      component: (
        <TableActions id={order.id} onDelete={onDelete}>
          <TableActionItem
            handleClick={() => handleViewDetails(order.id)}
            text="Detalhes"
            icon="Eye"
          />
          {order.status !== OrderStatus.approved.key && (
            <TableActionItem
              handleClick={() => handleApproveOrder(order.id)}
              text="Aprovar"
              icon="Check"
            />
          )}
          {order.status !== OrderStatus.canceled.key && (
            <TableActionItem
              handleClick={() => handleDisapproveOrder(order.id)}
              text="Cancelar"
              icon="X"
            />
          )}
        </TableActions>
      ),
    },
  })) as any
}

export const formatClientsData = (
  data: IListClients["data"],
  onDelete?: (id: string) => void,
  onEdit?: (id: string) => void
) => {
  if (!data) return []

  return data.map((client: any) => ({
    ...client,
    status: {
      value: client.is_active ? "Ativo" : "",
      component: (
        <StatusTag
          text={client.is_active ? "Sim" : "Não"}
          type={client.is_active ? "success" : "danger"}
        />
      ),
    },
    actions: {
      value: "",
      component: (
        <TableActions id={client.id} onEdit={onEdit} onDelete={onDelete} />
      ),
    },
  })) as any
}
