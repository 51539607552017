/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  IGetSegmentsData,
  ICustomFields,
  IProductsFilters,
  ICustonFieldsObject,
  IVariantTypes,
} from "@interfaces/Services"
import { clientApi } from "./clientApi"
import { generateErrorMessage } from "@utils/generateErrorMessage"
import {
  IPaymentMethods,
  IPaymentMethodsResponse,
} from "@interfaces/Components"

const segmentsService = {
  getSegments: {
    fn: async () => {
      try {
        const { data } = await clientApi.get<IGetSegmentsData>("/segments")

        return data
      } catch (error: any) {
        return generateErrorMessage("Erro ao buscar segmentos: ", error)
      }
    },
    key: "getSegments",
  },
  getSegment: {
    fn: async (segmentId: string) => {
      try {
        const { data } = await clientApi.get<{
          key: string
        }>(`/segments/${segmentId}`)

        return data
      } catch (error: any) {
        return generateErrorMessage("Erro ao buscar segmento: ", error)
      }
    },
    key: "getSegment",
  },
  getSegmentsVariantTypes: {
    fn: async (segmentId: string) => {
      try {
        const { data } = await clientApi.get<IVariantTypes[]>(
          `/segments/variant_types/${segmentId}`
        )

        return data
      } catch (error: any) {
        return generateErrorMessage(
          "Erro ao buscar tipos de variantes: ",
          error
        )
      }
    },
    key: "getSegmentsVariantTypes",
  },
  getSegmentsCustomFields: {
    fn: async (segmentId: string) => {
      try {
        const { data } = await clientApi.get<ICustonFieldsObject>(
          `/segments/custom_fields/${segmentId}`
        )

        return data
      } catch (error: any) {
        return generateErrorMessage(
          "Erro ao buscar campos customizados: ",
          error
        )
      }
    },
    key: "getSegmentsCustomFields",
  },
  getSegmentsPaymentMethods: {
    fn: async (segmentId: string) => {
      try {
        const { data } = await clientApi.get<IPaymentMethodsResponse[]>(
          `/segments/payment_methods/${segmentId}`
        )

        return data
      } catch (error: any) {
        return generateErrorMessage(
          "Erro ao buscar campos customizados: ",
          error
        )
      }
    },
    key: "getSegmentsPaymentMethods",
  },
  getFilters: {
    fn: async (segmentId?: string) => {
      try {
        const { data } = await clientApi.get<IProductsFilters[]>(
          `/segments/product_filters/${segmentId}`
        )
        return data
      } catch (error: any) {
        return generateErrorMessage("Erro ao buscar filtros: ", error)
      }
    },
    key: "getFilters",
  },
}

export { segmentsService }
